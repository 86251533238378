import React from "react";
import logo from "../../assets/images/logo.svg";
// import google from "../../assets/images/google1.svg";
import { Form, Input, Button, Checkbox, Layout } from "antd";
import styles from "./Login.module.scss";
import { Link } from "react-router-dom";
import { apiQuery, apiQueryError } from "../../Utils";
// import imglo from "../../assets/images/imglo.svg";
import imglo from "../../assets/images/authBg.png";
import userStore from "./userStore";
import goggl from "../../assets/images/googleogo.svg";
import fclogo from "../../assets/images/fclogo.svg";
import appl from "../../assets/images/appl.svg";

const { Content } = Layout;

const Login = () => {
  const onFinish = (values) => {
    apiQuery("/login", {
      method: "POST",
      body: values,
    })
      .then((userData) => {
        userStore.setUserData(userData.userData);
        userStore.setAuth(true);
        if (!userData.userData?.profileData?.category)
          return (window.location.href = "/cabinet");
        if (!userData.cases)
          return (window.location.href = "/cabinet/cases/add");
        window.location.href = "/";
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };

  return (
    <div className={styles.wrp_log + " " + styles.heigh_log}>
      {/*<div className={styles.block_login}>
        <Content
          className="form_log"
          style={{
            width: 420,
            maxWidth: "100%",
            padding: "0 16px",
            margin: "0px auto",
            boxSizing: "border-box",
          }}
        >
          <div>
            <a className="logo_auth" href={"/"}>
              <img src={logo} style={{ marginBottom: "20px" }} alt="logo" />
            </a>
          </div>
        </Content>
      </div>*/}
      <Content
        className={styles.login_form}
        style={{
          width: 420,
          maxWidth: "100%",
          padding: "0 16px",
          margin: "0px auto",
          boxSizing: "border-box",
          flex: "none",
        }}
      >
        <div>
          <a className="logo_auth" href={"/"}>
            <img src={logo} style={{ marginBottom: "20px" }} alt="logo" />
          </a>
          <div className={styles.fonn}>
            <div
              className={styles.logo_neww}
              style={{ backgroundImage: `url(${imglo})` }}
            ></div>
          </div>
        </div>

        <p className={styles.title_bl + " mb-24"}>Авторизация</p>

        <Form
          name="normal_login"
          className={styles.login_form + "mt-32"}
          initialValues={{ rememberMe: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Введите email!" }]}
          >
            <Input type={"email"} placeholder="Электронный адрес" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Введите пароль!" }]}
          >
            <Input type="password" placeholder="Пароль" />
          </Form.Item>
          <Form.Item className={styles.two_text}>
            <Form.Item name="rememberMe" valuePropName="checked" noStyle>
              <Checkbox>Запомнить меня</Checkbox>
            </Form.Item>
            <Link className="login_form_forgot" to="/restore">
              Забыли пароль?
            </Link>
          </Form.Item>

          <Form.Item>
            <div className={styles.flex_bntt}>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.login_form_button}
                block
              >
                Войти
              </Button>
            </div>
          </Form.Item>
        </Form>
        {/*<div className={styles.title}>*/}
        {/*  <span>или</span>*/}
        {/*</div>*/}
        {/*<div style={{ textAlign: "center" }} className="mt-16">*/}
        {/*  <a*/}
        {/*    className={styles.but_soc_net}*/}
        {/*    href={"/"}*/}
        {/*  >*/}
        {/*    <span>*/}
        {/*      <img src={google} alt="google" />*/}
        {/*    </span>*/}
        {/*    Продолжить с Google*/}
        {/*  </a>*/}
        {/*</div>*/}

        {/*<div className={styles.add_registr}>
          <p className={styles.text_lit}>Авторизоваться с помощью</p>
          <div style={{ textAlign: "center" }}>
            <Link to={'https://vpoiske.kz/api/auth/google'}>
              <img src={goggl} />
            </Link>
          </div>
        </div>*/}

        <div style={{ textAlign: "center" }} className="mt-16">
          <p className={styles.text_lit + " mb-0"}>Создать аккаунт</p>

          <Link className={styles.text_link} to="/create">
            Регистрация
          </Link>
        </div>
      </Content>
    </div>
  );
};

export default Login;
