import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.svg";
import {
  Form,
  Input,
  Button,
  Layout,
  message,
  Tabs,
  Modal,
  Checkbox,
} from "antd";
import styles from "./Login.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { apiQuery, apiQueryError } from "../../Utils";
import ModalSelect from "../ModalSelect";
import imglo from "../../assets/images/imggg4.svg";
import goggl from "../../assets/images/googleogo.svg";
import fclogo from "../../assets/images/fclogo.svg";
import appl from "../../assets/images/appl.svg";
import SearchStore from "../Search/SearchStore";
import SearchModalSelect from "../Search/SearchModalSelect";

//import {faFire} from "@fortawesome/free-solid-svg-icons";

const { Content } = Layout;
const { TabPane } = Tabs;
const onChange = (key) => {
  console.log(key);
};
const Create = () => {
  let navigate = useNavigate();
  const [categoryOptions, setCategoryOptions] = useState([]);

  const onFinish = (values) => {
    apiQuery("/create_user", {
      method: "POST",
      body: values,
    })
      .then((userData) => {
        message.success("Вы успешно зарегистрированны");
        navigate("/cabinet", { replace: true });
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };

  useEffect(() => {
    apiQuery("/public/categoryOptionsGet")
      .then((data) => {
        setCategoryOptions(data);
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  }, []);

  return (
    <div className={styles.wrp_log}>
      {/*<div className={styles.block_login}>
        <Content
          className="form_log"
          style={{
            width: 420,
            maxWidth: "100%",
            padding: "0 16px",
            margin: "0px auto",
            boxSizing: "border-box",
          }}
        >
          <div>
            <a className="logo_auth" href={"/"}>
              <img src={logo} style={{ marginBottom: "20px" }} alt="logo" />
            </a>
          </div> 
        </Content>
      </div>*/}
      <Content
        className={styles.login_form}
        style={{
          width: 420,
          maxWidth: "100%",
          padding: "0 16px",
          margin: "0px auto",
          boxSizing: "border-box",
          flex: "none",
        }}
      >
        <div>
          <a className={styles.logo_block} href={"/"}>
            <img src={logo} style={{ marginBottom: "20px" }} alt="logo" />
          </a>
          <div className={styles.fonn}>
            <img src={imglo} alt="fon" />
          </div>
        </div>

        <div className={styles.tabs_registr}>
          <Tabs
            className="scroll"
            defaultActiveKey="1"
            onChange={onChange}
            centered="true"
          >
            <TabPane tab="Эксперт" key="1">
              <p className={styles.title_bl + " mb-24"}>Регистрация</p>
              <Form
                name="normal_login"
                className={styles.login_form + "mt-32"}
                onFinish={onFinish}
                layout={"vertical"}
              >
                <Form.Item
                  name="role"
                  hidden={true}
                >
                  <Input value={'expert'} />
                </Form.Item>
                <Form.Item
                  name="displayName"
                  rules={[{ required: true, message: "Введите ФИО!" }]}
                >
                  <Input placeholder="ФИО" />
                </Form.Item>

                <Form.Item
                  name="userName"
                  rules={[
                    { required: true, message: "Введите Username!" },
                    {
                      pattern: new RegExp(
                        /^[a-zA-Z0-9]+([_\s-]?[a-zA-Z0-9])*$/i
                      ),
                      message: "Только латинские символы и тире",
                    },
                  ]}
                >
                  <Input placeholder="Username" />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: "Введите email!" }]}
                >
                  <Input type={"email"} placeholder="Email" />
                </Form.Item>
                <Form.Item
                  name="category"
                  rules={[
                    { required: true, message: "Выберите вашу специальность" },
                  ]}
                >
                  {/*<ModalSelect*/}
                  {/*  modalTitle={"Ваша специальность"}*/}
                  {/*  mode="multiple"*/}
                  {/*  size={"middle"}*/}
                  {/*  options={categoryOptions}*/}
                  {/*  placeholder="Ваша специальность"*/}
                  {/*/>*/}

                  <SearchModalSelect
                    mode="multiple"
                    modalTitle={"Категории"}
                    modalAllTitle={"Все категории"}
                    // modalPopularTitle={"Популярные категории"}
                    // modalPopularOptions={categoryOptions.filter((item) => [
                    //   '63da66486e62e680c299af0e','63da67236e62e680c299af10','63da672d6e62e680c299af11'
                    // ].includes(item.value))}
                    placeholder="Категория"
                    options={categoryOptions}
                    // onSelectAdd={(v) => SearchStore.addFilterCategoryItem(v)}
                    // onSelectDel={(v) => SearchStore.delFilterCategoryItem(v)}
                  />

                  {/*<Select mode="multiple"*/}
                  {/*        style={{*/}
                  {/*          width: '100%',*/}
                  {/*        }}*/}
                  {/*        size={"middle"}*/}
                  {/*        placeholder="Ваша специальность"*/}
                  {/*        options={categoryOptions} />*/}
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[{ required: true, message: "Введите пароль!" }]}
                >
                  <Input type="password" placeholder="Пароль" />
                </Form.Item>
                <p className={styles.link_pr}>
                  Регистрируясь, вы соглашаетесь с нашими{" "}
                  <Link to="">Условиями использования</Link> и
                  <Link to=""> Политикой конфиденциальности</Link>
                </p>

                <Form.Item>
                  <div className={styles.flex_bntt}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className={styles.login_form_button}
                      block
                    >
                      Создать аккаунт
                    </Button>
                    {/*  <p className="little_text ">
                <Link to="/login">Войти</Link>
              </p>*/}
                  </div>
                  {/*<div className={styles.add_registr}>
              <p className={styles.text_lit}>Зарегистрироваться с помощью</p>
              <div className="flex">
                <Link to={"https://vpoiske.kz/api/auth/google"}>
                  <img src={goggl} />
                </Link>
                <Link>
                  <img src={fclogo} />
                </Link>
                <Link>
                  <img src={appl} />
                </Link>
              </div>
            </div>*/}
                </Form.Item>
              </Form>
            </TabPane>
            <TabPane tab="Компания" key="2">
              <p className={styles.title_bl + " mb-24"}>Регистрация</p>
              <Form
                name="normal_login"
                className={styles.login_form + "mt-32"}
                onFinish={onFinish}
                layout={"vertical"}
              >
                <Form.Item
                  name="role"
                  hidden={true}
                  initialValue={'company'}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="displayName"
                  rules={[{ required: true, message: "Введите ФИО!" }]}
                >
                  <Input placeholder="Название компании" />
                </Form.Item>

                <Form.Item
                  name="userName"
                  rules={[
                    { required: true, message: "Введите Username!" },
                    {
                      pattern: new RegExp(
                        /^[a-zA-Z0-9]+([_\s-]?[a-zA-Z0-9])*$/i
                      ),
                      message: "Только латинские символы и тире",
                    },
                  ]}
                >
                  <Input placeholder="Username" />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: "Введите email!" }]}
                >
                  <Input type={"email"} placeholder="Email" />
                </Form.Item>
                <Form.Item
                  name="category"
                  rules={[
                    { required: true, message: "Выберите вашу специальность" },
                  ]}
                >
                  <SearchModalSelect
                    mode="multiple"
                    modalTitle={"Категории"}
                    modalAllTitle={"Все категории"}
                    // modalPopularTitle={"Популярные категории"}
                    // modalPopularOptions={categoryOptions.filter((item) => [
                    //   '63da66486e62e680c299af0e','63da67236e62e680c299af10','63da672d6e62e680c299af11'
                    // ].includes(item.value))}
                    placeholder="Категория"
                    options={categoryOptions}
                    // onSelectAdd={(v) => SearchStore.addFilterCategoryItem(v)}
                    // onSelectDel={(v) => SearchStore.delFilterCategoryItem(v)}
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[{ required: true, message: "Введите пароль!" }]}
                >
                  <Input type="password" placeholder="Пароль" />
                </Form.Item>
                <p className={styles.link_pr}>
                  Регистрируясь, вы соглашаетесь с нашими{" "}
                  <Link to="">Условиями использования</Link> и
                  <Link to=""> Политикой конфиденциальности</Link>
                </p>

                <Form.Item>
                  <div className={styles.flex_bntt}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className={styles.login_form_button}
                      block
                    >
                      Создать аккаунт
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </TabPane>
          </Tabs>
        </div>

        <div style={{ textAlign: "center" }}>
          <p className={styles.text_lit + " mb-0"}>Авторизоваться</p>
          <Link className={styles.text_link} to="/login">
            Войти
          </Link>
        </div>
      </Content>
    </div>
  );
};

export default Create;
