import { Button, Divider, Form, Input, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./CaseCard.module.scss";
import stylesFormBlock from "../FormBlock/FormBlock.module.scss";
import OrderStore from "./OrderStore";
import { observer } from "mobx-react-lite";
import InputMask from "react-input-mask";

const OrderModal = ({
  caseId,
  expertId,
  caseData,
  buttonStyle,
  buttonText = "Заказать",
  styleButtonSend = null,
  imgbl,
}) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    values.caseId = caseId;
    values.expertId = expertId;
    OrderStore.add(values, (e) => {
      if (e) {
        message.success("Заказ отправлен");
        setIsModalOpen(false);
      }
    });
  };

  useEffect(() => {
    form.resetFields();
  }, [isModalOpen]);

  return (
    <>
      <span
        className={styles.orderButton + " " + styles.but_viz}
        style={buttonStyle}
        onClick={showModal}
      >
        {imgbl}
        {buttonText}
      </span>
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        okText={"Отправить"}
        onCancel={handleCancel}
        cancelText={"Закрыть"}
        confirmLoading={OrderStore.addLoad}
        footer={null}
        closeIcon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.8214 8.336C17.2119 7.94548 17.2119 7.31231 16.8214 6.92179C16.4308 6.53126 15.7977 6.53126 15.4071 6.92179L12.0002 10.3288L8.59306 6.92168C8.20253 6.53116 7.56937 6.53116 7.17884 6.92168C6.78832 7.31221 6.78832 7.94537 7.17884 8.33589L10.5859 11.743L7.17899 15.1499C6.78846 15.5405 6.78846 16.1736 7.17899 16.5642C7.56951 16.9547 8.20268 16.9547 8.5932 16.5642L12.0002 13.1572L15.407 16.564C15.7975 16.9546 16.4307 16.9546 16.8212 16.564C17.2117 16.1735 17.2117 15.5404 16.8212 15.1498L13.4144 11.743L16.8214 8.336Z"
              fill="#6D8EF7"
            />
          </svg>
        }
      >
        {!caseData && (
          <>
            <h2>Свяжитесь со мной!</h2>
            <p style={{ fontSize: 18 }}>
              Заполните форму, и я свяжусь с вами в течении одно рабочего дня
            </p>
          </>
        )}

        {!!caseData && (
          <>
            <h2>{caseData?.caseData?.name}</h2>
            <p style={{ fontSize: 16 }}>
              <strong>Цена:</strong> от {caseData?.caseData?.amount}{" "}
            </p>
            <p style={{ color: "#5F6B74", fontSize: 16 }}>
              {caseData?.caseData?.headline}
            </p>
          </>
        )}

        <Divider />
        <div className={stylesFormBlock.form_bbb} id="formm">
          <Form
            form={form}
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="userName"
              rules={[
                {
                  required: true,
                  message: "Обязательное поле!",
                },
              ]}
            >
              <Input
                placeholder="Имя Фамилия"
                className={styles.inputPlaceholder}
              />
            </Form.Item>

            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Обязательное поле!",
                },
              ]}
            >
              <InputMask
                placeholder="Номер телефона"
                mask="+7 999-999-99-99"
                className={styles.inputPlaceholder}
              />
            </Form.Item>

            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: "Обязательное поле!",
                },
              ]}
            >
              <Input.TextArea
                placeholder="Комментарий"
                className={styles.inputPlaceholder}
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" style={styleButtonSend}>
                Отправить
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default observer(OrderModal);
