import { Link } from "react-router-dom";
import Utils from "../../Utils";
import styles from "../Search/Search.module.scss";
import { Col, Progress, Row } from "antd";
import { AutoSizer } from "react-virtualized";
import React from "react";
import note from "../../assets/images/note.svg";
import clock from "../../assets/images/clock.svg";
import ExpertsStore from "../Experts/ExpertsStore";
import skill from "../../assets/images/skill.svg";
import premium from "../../assets/images/premium.svg";

const ExpertsBlockItem2 = ({ item }) => {
  return (
    <Link to={"/card/" + item?._id} className={styles.expertItem}>
      <div
        style={{
          backgroundImage: `url(${Utils.urlAvatar(item?.profileData?.avatar)})`,
        }}
        className={styles.expert_avatar}
      >
        {item?.premium && (
          <img
            src={premium}
            alt={""}
            style={{
              width: 20,
              position: "absolute",
              top: 5,
              right: 5,
            }}
          />
        )}
      </div>

      <div className={styles.panel_text}>
        <Row>
          <Col flex={"auto"}>
            <AutoSizer disableHeight={true} style={{ display: "inline-block" }}>
              {({ width }) => (
                <p style={{ width: width }}>{item?.profileData?.fio}</p>
              )}
            </AutoSizer>

            <div>
              {!!item.profileData.category &&
                item.profileData.category.length > 0 &&
                item.profileData.category.slice(0, 1).map((item, index) => (
                  <span key={index} className={styles.categoryName}>
                    {item?.name}
                  </span>
                ))}
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles.block_fll}>
        <div className={styles.info_bll}>
          <div className={styles.info_bll_item}>
            <img src={note} alt={""} />
            <span>{item.profileData?.reviewCount || 0}</span>
          </div>
          <div className={styles.info_bll_item}>
            <img src={clock} alt={""} />
            <span>{item.profileData.experience}</span>
          </div>
          <div className={styles.info_bll_item}>
            <img src={skill} alt={""} />
            <span>{item.profileData?.skills?.length || 0}</span>
          </div>
        </div>
        <div className={styles.review}>
          <div className={styles.bg} />
          <Progress
            type="circle"
            size={30}
            strokeColor={"#0029FF"}
            percent={(item?.profileData?.review * 100) / 5}
            format={() => <span>{item?.profileData?.review || 0}</span>}
          />
        </div>
      </div>
    </Link>
  );
};

export default ExpertsBlockItem2;
