import {configure, makeAutoObservable} from "mobx";

configure({enforceActions: "never"})

class AppStore {

  width = 0;
  height = 0;
  logovisible = true;

  constructor() {
    makeAutoObservable(this)
  }

  resize(width, height){
    this.width = width;
    this.height = height;
  }
  setLogovisible(v){
    this.logovisible = v;
  }
  
}

const clsAppStore = new AppStore()
export default clsAppStore