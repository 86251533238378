import styles from "./Search.module.scss";
import React, { useEffect } from "react";
import { Button, Empty, Form, Input, Slider, Spin, Radio } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import SearchModalSelect from "./SearchModalSelect";
import { observer } from "mobx-react-lite";
import SearchStore from "./SearchStore";
import InfiniteScroll from "react-infinite-scroller";
import ExpertsBlockItem2 from "../ExpertsBlockItem/ExpertsBlockItem2";
import starRating from "../../assets/images/starRating.svg";

const Search = () => {
  const expertListItems = SearchStore.expertListItems;

  useEffect(() => {
    SearchStore.categoryList();
    SearchStore.skillsList();
    SearchStore.countryList();
    SearchStore.citiesList();
    SearchStore.list({ clear: true });
    return () => {
      SearchStore.clearFilter();
    };
  }, []);

  return (
    <div style={{ backgroundColor: "#F4FBFF" }}>
      <div className="container">
        <div className={styles.flex_tit + " mb-16"}>
          <h3 className={styles.title_main + " mt-0"}>
            Найдите нужного эксперта
          </h3>
        </div>

        <div className={styles.searchInputBlock}>
          <Input
            size="large"
            placeholder="Введите имя или профессию"
            className={styles.searchInput}
            prefix={<SearchOutlined />}
            value={SearchStore.filter.searchValue}
            onChange={(e) => {
              SearchStore.setSearchValue(e.target.value);
            }}
            onPressEnter={() => {
              SearchStore.list({ clear: true });
            }}
          />
        </div>

        <div className={styles.flex_sidebar}>
          <div className={styles.filterBlock}>
            <div className={styles.filterBlockContent}>
              <p className={styles.filterTitle}>Фильтр</p>

              <Form
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <div className={"mt-8 mb-8"}>
                  <p>
                    По рейтингу{" "}
                    <img src={starRating} alt={""} style={{ width: 14 }} />
                  </p>
                  <Slider
                    marks={{ 1: 0, 5: 5 }}
                    value={SearchStore.filter.rating}
                    min={1}
                    max={5}
                    range
                    onChange={(v) => {
                      SearchStore.setFilterRating(v);
                    }}
                  />
                </div>

                <Form.Item>
                  <Radio.Group
                    value={SearchStore.filter.role}
                    onChange={(e) => {
                      SearchStore.setFilterRole(e.target.value);
                      SearchStore.list({ clear: true });
                    }}
                  >
                    <Radio.Button value={"all"}>Все</Radio.Button>
                    <Radio.Button value={"expert"}>Эксперты</Radio.Button>
                    <Radio.Button value={"company"}>Компании</Radio.Button>
                  </Radio.Group>
                </Form.Item>

                <Form.Item label="Категория">
                  <SearchModalSelect
                    mode="multiple"
                    modalTitle={"Категории"}
                    modalAllTitle={"Все категории"}
                    modalPopularTitle={"Популярные категории"}
                    modalPopularOptions={SearchStore.optionsCategory.filter(
                      (item) =>
                        [
                          "63da66486e62e680c299af0e",
                          "63da67236e62e680c299af10",
                          "63da672d6e62e680c299af11",
                        ].includes(item.value)
                    )}
                    placeholder="Категория"
                    onChange={(value) => {
                      SearchStore.setFilterCategory(value);
                    }}
                    value={SearchStore.filter.category}
                    options={SearchStore.optionsCategory}
                    onSelectAdd={(v) => SearchStore.addFilterCategoryItem(v)}
                    onSelectDel={(v) => SearchStore.delFilterCategoryItem(v)}
                  />
                </Form.Item>
                <Form.Item label="Навыки">
                  <SearchModalSelect
                    mode="multiple"
                    modalTitle={"Навыки"}
                    modalAllTitle={"Все навыки"}
                    modalPopularTitle={"Популярные навыки"}
                    modalPopularOptions={SearchStore.optionsSkills.filter(
                      (item) =>
                        [
                          "63da64c56e62e680c299af0a",
                          "63da64b36e62e680c299af09",
                          "63dc9b1a6e62e680c299af22",
                        ].includes(item.value)
                    )}
                    placeholder="Навыки"
                    onChange={(value) => {
                      SearchStore.setFilterSkills(value);
                    }}
                    value={SearchStore.filter.skills}
                    options={SearchStore.optionsSkills}
                    onSelectAdd={(v) => SearchStore.addFilterSkillsItem(v)}
                    onSelectDel={(v) => SearchStore.delFilterSkillsItem(v)}
                  />
                </Form.Item>
                <Form.Item label="Страна">
                  <SearchModalSelect
                    modalTitle={"Страна"}
                    modalAllTitle={"Все страны"}
                    placeholder="Страна"
                    mode="multiple"
                    onChange={(value) => {
                      SearchStore.setFilterCountry(value);
                    }}
                    value={SearchStore.filter.country}
                    options={SearchStore.optionsCountry}
                    showArrow={true}
                    optionFilterProp="label"
                    onSelectAdd={() => {}}
                    onSelectDel={(v) => SearchStore.delFilterCountryItem(v)}
                  />{" "}
                </Form.Item>
                <Form.Item label="Город">
                  <SearchModalSelect
                    modalTitle={"Город"}
                    modalAllTitle={"Все города"}
                    modalPopularTitle={"Популярные города"}
                    modalPopularOptions={SearchStore.optionsCities.filter(
                      (item) =>
                        [
                          "642fecc3cb9b4d831fd86a17",
                          "642fecc3cb9b4d831fd86a21",
                        ].includes(item.value)
                    )}
                    placeholder="Город"
                    mode="multiple"
                    onChange={(value) => {
                      SearchStore.setFilterCities(value);
                    }}
                    value={SearchStore.filter.city}
                    options={SearchStore.optionsCities}
                    showArrow={true}
                    optionFilterProp="label"
                    onSelectAdd={(v) => SearchStore.addFilterCitiesItem(v)}
                    onSelectDel={(v) => SearchStore.delFilterCitiesItem(v)}
                  />{" "}
                </Form.Item>

                <Button
                  onClick={() => {
                    SearchStore.list({ clear: true });
                  }}
                  type={"primary"}
                  block
                >
                  Применить фильтр
                </Button>

                <span
                  className={styles.filterClear}
                  onClick={() => {
                    SearchStore.clearFilter();
                  }}
                >
                  Сбросить фильтр
                </span>
              </Form>
            </div>
          </div>
          <div className={styles.searchList}>
            {!expertListItems.length && SearchStore.fetchCheck && (
              <div style={{ width: "100%", textAlign: "center" }}>
                <Spin />
              </div>
            )}
            {!expertListItems.length && !SearchStore.fetchCheck && (
              <div style={{ width: "100%", textAlign: "center" }}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={"По вашим критериям ничего не найдено"}
                />
              </div>
            )}
            {!!expertListItems.length && (
              <InfiniteScroll
                style={{ width: "100%", minHeight: 50, padding: "20px 0" }}
                pageStart={0}
                loadMore={() => SearchStore.list({})}
                hasMore={SearchStore.expertListLoadCheck}
                loader={
                  <div className="loader" key={0}>
                    <Spin />
                  </div>
                }
              >
                {expertListItems.map((item, index) => (
                  <div
                    key={index}
                    className={styles.itemsearch}
                    style={{ width: "33.3%", display: "inline-block" }}
                  >
                    <div style={{ padding: "0 5px 10px" }}>
                      <ExpertsBlockItem2 item={item} />
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Search);
