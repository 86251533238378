import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../Favorites/Favorites.module.scss";
import { observer } from "mobx-react-lite";
import {Empty, Spin} from "antd";
import userStore from "../Auth/userStore";
import { LeftOutlined } from "@ant-design/icons";
import FavoritesStore from "./FavoritesStore";
import CasesItemView from "../CasesBlockItem/CasesItemView";
import ReviewsStore from "../Reviews/ReviewsStore";

const Favorites = () => {
  const userId = userStore.userData._id;

  useEffect(() => {
    FavoritesStore.list();
  }, [userId]);

  if (FavoritesStore.itemsLoad) return <Spin />;

  return (
    <div className={styles.page_form} style={{maxWidth: 700, margin: '0 auto'}}>
      <div className="container">
        <p className={styles.title_page}>Избранное</p>
        <div className={styles.favorit_flex} >
          {!FavoritesStore.items.length && (
            <div style={{ width: "100%", textAlign: "center" }}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={"В избранное ничего не добавлено"}
              />
            </div>
          )}
          {FavoritesStore.items.map((item, index) => (
            <span className={styles.favorit_one} key={index}>
              <CasesItemView item={item} />
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
export default observer(Favorites);
