import styles from "../../pages/Home.module.scss";
import { Spin, Empty } from "antd";
import React, { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { observer } from "mobx-react-lite";
import ExpertsItemGorizont2 from "../ExpertsItemGorizont2";
import ExpertsTopStore from "./ExpertsTopStore";

const ExpertsTopList = ({ role = "expert" }) => {
  useEffect(() => {
    ExpertsTopStore.list({ clear: true, role });
  }, []);

  return (
    <div className={styles.top_experts} id="formm">
      <div className={styles.flex_bl}>
        {!ExpertsTopStore.expertListItems.length &&
          ExpertsTopStore.fetchCheck && (
            <div style={{ width: "100%", textAlign: "center" }}>
              <Spin />
            </div>
          )}
        {!ExpertsTopStore.expertListItems.length &&
          !ExpertsTopStore.fetchCheck && (
            <div style={{ width: "100%", textAlign: "center" }}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={"По вашим критериям ничего не найдено"}
              />
            </div>
          )}
        {!!ExpertsTopStore.expertListItems.length && (
          <InfiniteScroll
            style={{ width: "100%", minHeight: 50 }}
            pageStart={0}
            loadMore={() => ExpertsTopStore.list({ role })}
            hasMore={ExpertsTopStore.expertListLoadCheck}
            loader={
              <div className="loader" key={0}>
                <Spin />
              </div>
            }
          >
            {ExpertsTopStore.expertListItems.map((item, index) => (
              <div key={index}>
                <ExpertsItemGorizont2 item={item} index={index} />
              </div>
            ))}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

export default observer(ExpertsTopList);
