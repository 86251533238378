import React from "react";
import styles from "../Rating/Rating.module.scss"
import {Space} from 'antd';
import star from "../../assets/images/Star2.svg";

const Rating = ({review = 0}) => {
  return (
    <Space wrap className={styles.raiting_bl}>
      {/*<Progress strokeColor="#00C814" type="circle" strokeWidth="10" percent={review*10} format={() => {
        if (review > 10) return '10+';
        return review
      }}/>*/}
      <p>{review} <img src={star} alt={''}/></p>
    </Space>
  );
};

export default Rating;
