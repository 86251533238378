import {configure, makeAutoObservable} from "mobx";
import {apiQuery, apiQueryError} from "../../Utils";

configure({enforceActions: "never"})

class ExpertsStore {

  filter = {};
  searchValue = '';
  expertTopItems = [];
  expertListItems = [];
  expertListLoadCheck = true;
  expertsAllListItems = [];
  expertAllListLoadCheck = true;
  expertListTimeOut = null;
  expertCardLoad = true;
  expertCardData = null;
  skillsCategoryItems = [];
  filterRatingFrom = null;
  filterRatingTo = null;
  filterCities = [];

  filterCategoryOptions = [];
  filterCategoryOptionsSelected = null;
  filterSkillsOptions = [];
  filterSkillsOptionsSelected = [];
  filterSliderRating = [1,5];
  skills = {};
  fetchCheck = false;
  citiesOptions = [];
  countriesOptions = [];

  constructor() {
    makeAutoObservable(this)
  }

  setExpertListItems(v) {
    this.expertListItems = v
  }

  expertsTopList() {
    apiQuery("/public/experts/top", {
      method: 'POST',
      body: {
        sort: 'rating'
      }
    })
      .then((data) => {
        this.expertTopItems = data
      })
      .catch((error) => {
        apiQueryError({error})
      })
  }

  expertsList({clear = false}) {

    console.log('expertsList')

    if (clear) {
      this.fetchCheck = false;
      this.expertListLoadCheck = true;
      this.expertListItems = [];
    }

    if (this.fetchCheck) return;
    this.fetchCheck = true;

    if (!this.expertListLoadCheck) {
      this.fetchCheck = false;
      return;
    }

    apiQuery("/public/experts/list", {
      method: 'POST',
      body: {
        // filter: this.filter,
        // filterRatingFrom: this.filterRatingFrom,
        // filterRatingTo: this.filterRatingTo,
        // filterCities: this.filterCities,
        expertItemsCount: clear ? 0 : this.expertListItems.length,
        // search: this.searchValue
      }
    })
      .then((data) => {
        if (clear) {
          this.expertListItems = data;
        } else {
          this.expertListItems = [...this.expertListItems, ...data];
        }
        this.expertListLoadCheck = data !== false && data.length > 0;
        this.fetchCheck = false;
      })
      .catch((error) => {
        apiQueryError({error})
        this.fetchCheck = false;
      })

  }

  expertsAllList({clear = false, sort = 'rating'}) {

    if (clear) {
      this.fetchCheck = false;
      this.expertAllListLoadCheck = true;
      this.expertsAllListItems = [];
    }

    if (this.fetchCheck) return;
    this.fetchCheck = true;

    if (!this.expertAllListLoadCheck) {
      this.fetchCheck = false;
      return;
    }

    apiQuery("/public/experts/list", {
      method: 'POST',
      body: {
        expertItemsCount: clear ? 0 : this.expertsAllListItems.length,
        sort
      }
    })
      .then((data) => {
        if (clear) {
          this.expertsAllListItems = data;
        } else {
          this.expertsAllListItems = [...this.expertsAllListItems, ...data];
        }
        this.expertAllListLoadCheck = data !== false && data.length > 0;
        this.fetchCheck = false;
      })
      .catch((error) => {
        apiQueryError({error})
        this.fetchCheck = false;
      })

  }

  expertCard(userId) {
    this.expertCardLoad = true;
    console.log({
      expertCardData: this.expertCardData,
      userId
    })
    if (this.expertCardData?.profile._id !== userId) this.expertCardData = null;
    apiQuery("/public/expert/" + userId)
      .then((data) => {
        this.expertCardData = data;
        this.expertCardLoad = false;
      })
      .catch((error) => {
        apiQueryError({error})
        this.expertCardLoad = false;
      })
  }

  skillsCategoryList() {
    apiQuery("/public/skillsCategory/list")
      .then((data) => {
        this.skillsCategoryItems = data.skillsCategory;

        this.filterCategoryOptions = data.skillsCategory.map((item) => {
          return {
            value: item._id,
            label: item.name,
          }
        });

        let skl = {};
        for (let skill of data.skills) {
          skl[skill._id] = skill.name;
        }
        this.skills = skl;

      })
      .catch((error) => {
        apiQueryError({error})
      })
  }

  setFilterSliderRating(v) {
    this.filterSliderRating = v;
  }

  setSearchValue(v) {
    this.searchValue = v;
    clearTimeout(this.expertListTimeOut);
    this.expertListTimeOut = setTimeout(() => {
      this.expertsList({clear: true})
    }, 1500)
  }

  setFilterCategoryOptionsSelected(v) {
    this.filterCategoryOptionsSelected = v;
    this.filterSkillsOptions = [];
    this.filterSkillsOptionsSelected = this.filter[v] ? this.filter[v] : [];
    let arr = [];
    for (let item of this.skillsCategoryItems) {
      if (item._id === v) {
        for (let skill of item.skillsDataIds) {
          arr.push({
            value: skill._id,
            label: skill.name,
          })
        }
        this.filterSkillsOptions = arr;
      }
    }
  }

  setFilterSkillsOptionsSelected(v) {
    this.filterSkillsOptionsSelected = v;
    this.filter[this.filterCategoryOptionsSelected] = v;
    clearTimeout(this.expertListTimeOut);
    this.expertListTimeOut = setTimeout(() => {
      this.expertsList({clear: true})
    }, 1000)
  }

  setFilterRatingFrom(v) {
    this.filterRatingFrom = v;
  }

  setFilterRatingTo(v) {
    this.filterRatingTo = v;
  }

  setFilterCities(v) {
    this.filterCities = v;
  }

  setFilter(c, v) {
    if (!this.filter[c]) this.filter[c] = [];
    this.filter[c].push(v);
  }

  delFilter(c, v) {
    const array = [...this.filter[c]];
    const index = array.indexOf(v);
    if (index > -1) { // only splice array when item is found
      array.splice(index, 1); // 2nd parameter means remove one item only
    }
    this.filter[c] = array;
    if (this.filterCategoryOptionsSelected === c) {
      this.filterSkillsOptionsSelected = array;
    }
    clearTimeout(this.expertListTimeOut);
    this.expertListTimeOut = setTimeout(() => {
      this.expertsList({clear: true})
    }, 1500)
  }

  clearFilter() {
    this.searchValue = '';
    this.filterSliderRating = [1,5];
    this.filterRatingFrom = '';
    this.filterRatingTo = '';
    this.filterCities = [];
    this.filter = {};
    this.filterCategoryOptionsSelected = null;
    this.filterSkillsOptionsSelected = [];
    clearTimeout(this.expertListTimeOut);
    this.expertListTimeOut = setTimeout(() => {
      this.expertsList({clear: true})
    }, 1500)
  }

  citiesList() {
    apiQuery("/public/cities")
      .then((data) => {
        if (data.length === 0) return;
        this.citiesOptions = data.map((item) => {
          return {
            value: item._id,
            label: item.name,
          }
        });
      })
      .catch((error) => {
        apiQueryError({error})
      })
  }

  countriesList() {
    apiQuery("/public/countries")
      .then((data) => {
        if (data.length === 0) return;
        this.countriesOptions = data.map((item) => {
          return {
            value: item._id,
            label: item.name,
          }
        });
      })
      .catch((error) => {
        apiQueryError({error})
      })
  }
}

const clsExpertsStore = new ExpertsStore()
export default clsExpertsStore