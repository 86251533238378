import ExpertsBlockItem2 from "../ExpertsBlockItem/ExpertsBlockItem2";
import React, { useEffect } from "react";
import ExpertsStore from "./ExpertsStore";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import Slider from "react-slick";
import styles from "../../pages/Home.module.scss";
import arrow12 from "../../assets/images/arrow13.svg";
import Allcat from "../Allcat";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
/*const setting = {
  dots: false,
  speed: 500,
  slidesToShow: 4,
  infinite: false,
  slidesToScroll: 4,
  arrows: true,
  nextArrow: <CaretRightOutlined />,
  prevArrow: <CaretLeftOutlined />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};*/
const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: false,
  speed: 1000,
  autoplaySpeed: 2000,
  //cssEase: "linear",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 2,
        centerMode: true,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.5,
        arrows: false,
      },
    },
  ],
};

const ExpertsTop = () => {
  useEffect(() => {
    ExpertsStore.expertsTopList();
  }, []);

  console.log(ExpertsStore.expertTopItems);

  return (
    <div className={styles.exp_wrp}>
      <div className="container">
        <div className={styles.flex_tit + " mb-16"}>
          <h4 className={styles.title_main}>Топ экспертов </h4>
          <Allcat title={"Все категории"} link="/allexperts" />
        </div>
        <div className="margin_minus">
          <Slider {...settings}>
            {ExpertsStore.expertTopItems.map((item, index) => (
              <div className={styles.blockk} key={index}>
                <ExpertsBlockItem2 item={item} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default observer(ExpertsTop);
