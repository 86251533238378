import React from "react";
import styles from "../CasesBlockItem/CasesBlockItem.module.scss"
import {Link} from "react-router-dom";
import Utils from "../../Utils";
import {observer} from "mobx-react-lite";
import TextTruncate from 'react-text-truncate';
import CaseFavoriteButton from "../Cases/CaseFavoriteButton";

const CasesItemView = ({item}) => {
  return (
    <div style={{position:"relative"}}>
      <div style={{
        backgroundColor: 'rgba(0, 0, 0, 0.11)',
        display: 'inline-block',
        position: 'absolute',
        zIndex: 1,
        width: 30,
        textAlign: 'center',
        right: 5,
        borderRadius: 12,
        padding: '5px 2px 0',
        height: 30,
        top: 5,
      }}>
        <CaseFavoriteButton caseId={item._id}/>
      </div>
      <Link className={styles.cases_bl} to={`/cases/view/${item._id}`}>
        <div className={styles.cases_block}
             style={{backgroundImage: `url(https://${Utils.domain}/api/public/file/${item.image})`}}
        >
        </div>

        <p className={styles.text}>
          <TextTruncate
            line={3}
            element="span"
            truncateText="…"
            text={item.name}
          />
        </p>
        <span className={styles.text_span}>
        <TextTruncate
          line={3}
          element="span"
          truncateText="…"
          text={item.headline}
        />
      </span>
      </Link>
    </div>

  );
};

export default observer(CasesItemView);
