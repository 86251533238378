import React from "react";
import styles from "../Allcat/Allcat.module.scss";
import { Link } from "react-router-dom";
import arrow12 from "../../assets/images/arrow13.svg";

const Allcat = (props) => {
  return (
    <Link className={styles.all_link} to={props.link}>
      {props.title}
      {/*<img src={arrow12} alt="" />*/}
    </Link>
  );
};

export default Allcat;
