import { configure, makeAutoObservable } from "mobx";
import { apiQuery, apiQueryError } from "../../Utils";

configure({ enforceActions: "never" });

class SearchStore {
  drawerOpen = false;
  expertListItems = [];
  expertListLoadCheck = false;
  fetchCheck = false;
  expertFull = 0;
  expertSearch = 0;

  filter = {
    searchValue: "",
    role: 'all',
    rating: [0, 5],
    category: [],
    skills: [],
    country: [],
    city: [],
    premium: "all",
  };
  itemsCategory = [];
  itemsSkills = [];
  optionsCategory = [];
  optionsSkills = [];
  optionsCountry = [];
  optionsCities = [];

  expertListPageItems = [];
  expertListPageItemsCount = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setDrawerOpen(v) {
    this.drawerOpen = v;
    if (!v) this.clearFilter();
  }

  categoryList() {
    apiQuery("/public/category/list")
      .then((data) => {
        this.optionsCategory = data.map((item) => {
          return {
            value: item._id,
            label: item.name,
            item,
          };
        });
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  }

  skillsList() {
    apiQuery("/public/skills/list")
      .then((data) => {
        this.optionsSkills = data.map((item) => {
          return {
            value: item._id,
            label: item.name,
            item,
          };
        });
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  }

  countryList() {
    apiQuery("/public/country/list")
      .then((data) => {
        this.optionsCountry = data.map((item) => {
          return {
            value: item._id,
            label: item.name,
            item,
          };
        });
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  }

  citiesList() {
    apiQuery("/public/cities/list")
      .then((data) => {
        this.optionsCities = data.map((item) => {
          return {
            value: item._id,
            label: item.name,
            item,
          };
        });
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  }

  clearFilter() {
    this.expertFull = 0;
    this.expertSearch = 0;
    this.filter = {
      searchValue: "",
      rating: [0, 5],
      category: [],
      skills: [],
      premium: "all",
    };
    this.list({ clear: true });
    this.adminListPage({page: 1});
  }

  setFilterRole(v) {
    this.filter.role = v;
  }

  setFilterRating(v) {
    this.filter.rating = v;
  }

  setFilterPremium(v) {
    this.filter.premium = v;
  }

  setFilterCategory(v) {
    this.filter.category = v;
  }
  addFilterCategoryItem(v, clear = false) {
    const arr = clear ? [] : [...this.filter.category];
    arr.push(v);
    this.filter.category = [...new Set(arr)];
  }
  delFilterCategoryItem(v) {
    console.log(v);
    const arr = [...this.filter.category];
    console.log(arr);
    this.filter.category = arr.filter(function (obj) {
      console.log({ obj });
      return obj !== v;
    });
  }

  setFilterSkills(v) {
    this.filter.skills = v;
  }
  addFilterSkillsItem(v) {
    const arr = [...this.filter.skills];
    arr.push(v);
    this.filter.skills = [...new Set(arr)];
  }
  delFilterSkillsItem(v) {
    const arr = [...this.filter.skills];
    this.filter.skills = arr.filter(function (obj) {
      return obj !== v;
    });
  }

  setFilterCountry(v) {
    this.filter.country = v;
  }
  addFilterCountryItem(v) {
    const arr = [...this.filter.country];
    arr.push(v);
    this.filter.country = [...new Set(arr)];
  }
  delFilterCountryItem(v) {
    const arr = [...this.filter.country];
    this.filter.country = arr.filter(function (obj) {
      return obj !== v;
    });
  }

  setFilterCities(v) {
    this.filter.city = v;
  }
  addFilterCitiesItem(v) {
    const arr = [...this.filter.city];
    arr.push(v);
    this.filter.city = [...new Set(arr)];
  }
  delFilterCitiesItem(v) {
    const arr = [...this.filter.city];
    this.filter.city = arr.filter(function (obj) {
      return obj !== v;
    });
  }

  setSearchValue(v) {
    this.filter.searchValue = v;
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.list({ clear: true });
    }, 1500);
  }

  list({ clear = false }) {
    if (clear) {
      this.fetchCheck = false;
      this.expertListLoadCheck = true;
      this.expertListItems = [];
    }

    if (this.fetchCheck) return;
    this.fetchCheck = true;

    if (!this.expertListLoadCheck) {
      this.fetchCheck = false;
      return;
    }

    apiQuery("/public/search/experts", {
      method: "POST",
      body: {
        filter: this.filter,
        expertItemsCount: clear ? 0 : this.expertListItems.length,
        sort: "rating",
      },
    })
      .then((data) => {
        if (clear) {
          this.expertListItems = data;
        } else {
          this.expertListItems = [...this.expertListItems, ...data];
        }
        this.expertListLoadCheck = data !== false && data.length > 0;
        this.fetchCheck = false;
      })
      .catch((error) => {
        apiQueryError({ error });
        this.fetchCheck = false;
      });
  }

  adminList({ clear = false }) {
    if (clear) {
      this.fetchCheck = false;
      this.expertListLoadCheck = true;
      this.expertListItems = [];
    }

    if (this.fetchCheck) return;
    this.fetchCheck = true;

    if (!this.expertListLoadCheck) {
      this.fetchCheck = false;
      return;
    }

    apiQuery("/private/admin/experts", {
      method: "POST",
      body: {
        filter: this.filter,
        expertItemsCount: clear ? 0 : this.expertListItems.length,
        sort: "rating",
      },
    })
      .then((data) => {
        this.expertFull = data.usersFull;
        this.expertSearch = data.usersSearch;
        data = data.users;

        if (clear) {
          this.expertListItems = data;
        } else {
          this.expertListItems = [...this.expertListItems, ...data];
        }
        this.expertListLoadCheck = data !== false && data.length > 0;
        this.fetchCheck = false;
      })
      .catch((error) => {
        apiQueryError({ error });
        this.fetchCheck = false;
      });
  }

  adminListPage({ page = 1 }) {
    apiQuery("/private/admin/experts/page", {
      method: "POST",
      body: {
        filter: this.filter,
        sort: "rating",
        page
      },
    })
      .then((data) => {
        this.expertFull = data.usersFull;
        this.expertSearch = data.usersSearch;
        this.expertListPageItems = data.items;
        this.expertListPageItemsCount = data.itemsCount;
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  }
}

const clsSearchStore = new SearchStore();
export default clsSearchStore;
