import {configure, makeAutoObservable} from "mobx";
import {apiQuery, apiQueryError} from "../../Utils";

configure({enforceActions: "never"})

class CompanyStore {

  items = [];
  fetchCheck = false;
  listLoadCheck = true;

  constructor() {
    makeAutoObservable(this)
  }

  list({clear = false}) {

    if (clear) {
      this.fetchCheck = false;
      this.listLoadCheck = true;
      this.items = [];
    }

    if (this.fetchCheck) return;
    this.fetchCheck = true;

    if (!this.listLoadCheck) {
      this.fetchCheck = false;
      return;
    }

    apiQuery("/public/company/list", {
      method: 'POST',
      body: {
        expertItemsCount: clear ? 0 : this.items.length,
      }
    })
      .then((data) => {
        if (clear) {
          this.items = data;
        } else {
          this.items = [...this.items, ...data];
        }
        this.listLoadCheck = data !== false && data.length > 0;
        this.fetchCheck = false;
      })
      .catch((error) => {
        apiQueryError({error})
        this.fetchCheck = false;
      })

  }
}

const clsCompanyStore = new CompanyStore()
export default clsCompanyStore
