import {configure, makeAutoObservable} from "mobx";
import {apiQuery} from "../../Utils";

configure({enforceActions: "never"})

class userStore {

  auth     = null; // null - данных еще нет (прилоадер), false|true - не авторизован | авторизован
  userData = {}
  restoreEmail = '';
  restoreCode = '';
  profileMenuOpen = false;

  constructor() {
    makeAutoObservable(this)
  }

  setProfileMenuOpen(v) {
    this.profileMenuOpen = v
  }

  setAuth(value) {
    this.auth = value
  }

  setUserData(value) {
    this.userData = value
  }

  setRestoreEmail(value) {
    this.restoreEmail = value
  }

  setRestoreCode(value) {
    this.restoreCode = value
  }

  authCheck() {
    return new Promise((resolve, reject) => {
      apiQuery("/private/check")
        .then((userData) => {

          this.userData = userData;
          this.auth     = true;

          resolve(this.userData)

        })
        .catch((error) => {
          console.error(error);
          if (error.res?.status === 401) this.auth = false;
          reject()
        })
    })
  }

  logout() {
    apiQuery("/logout")
      .then(() => {
        this.auth     = false;
        this.userData = {};
      })
      .catch((error) => {
        console.error(error)
        this.auth = false;
      })
  }

}

const clsUserStore = new userStore()
export default clsUserStore