import { configure, makeAutoObservable } from "mobx";
import { apiQuery, apiQueryError } from "../../Utils";

configure({ enforceActions: "never" });

class CasesStore {
  caseCardLoad = true;
  caseCardData = null;
  caseMyItemsLoad = true;
  caseMyItems = [];

  constructor() {
    makeAutoObservable(this);
  }

  caseCard(caseId) {
    this.caseCardLoad = true;
    this.caseCardData = null;
    apiQuery("/public/case/" + caseId)
      .then((data) => {
        this.caseCardData = data;
        this.caseCardLoad = false;
      })
      .catch((error) => {
        apiQueryError({ error });
        this.caseCardLoad = false;
      });
  }

  casesMy() {
    this.caseMyItemsLoad = true;
    this.caseMyItems = [];
    apiQuery("/private/cases/my", {
      method: "POST",
    })
      .then((data) => {
        this.caseMyItems = data;
        this.caseMyItemsLoad = false;
      })
      .catch((error) => {
        apiQueryError({ error });
        this.caseMyItemsLoad = false;
      });
  }
}

const clsCasesStore = new CasesStore();
export default clsCasesStore;
