import React from "react";
import styles from "../Header/Header.module.scss";
import { Col, Row } from "antd";
import logo from "../../assets/images/logo.svg";
import logo2 from "../../assets/images/logo_white_ny.svg";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import LoginWidget from "../Auth/LoginWidget";
import AppStore from "../App/AppStore";
import { observer } from "mobx-react-lite";

const Header = (props) => {
  let history = useNavigate();
  return (
    <>
      {AppStore.width <= 767 ? (
        <>
          {!props.home && window.location.pathname !== "/" && (
            <div className={styles.header}>
              <div className="container">
                <Row justify="space-between" align="middle">
                  <Col span={6}>
                    {window.location.pathname !== "/" && (
                      <button
                        onClick={() => {
                          history(-1);
                        }}
                      >
                        {props.button1}
                      </button>
                    )}
                  </Col>
                  <Col span={12}>
                    <div className={styles.logo}>
                      <Link to="/">
                        <img src={logo} alt="logo" width={80} />
                      </Link>
                    </div>
                  </Col>
                  <Col span={6} className={styles.textright}>
                    <LoginWidget />
                  </Col>
                </Row>
              </div>
            </div>
          )}
          {props.home && (
            <div className={styles.headerDesktop}>
              <div className="container">
                <Row>
                  <Col span={12}>
                    {AppStore.logovisible && (
                      <div className={styles.logo}>
                        <Link to="/">
                          <img src={logo2} alt="logo" width={80} />
                        </Link>
                      </div>
                    )}
                  </Col>
                  <Col span={12} className={styles.textright}>
                    <LoginWidget />
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {console.log(props)}
          {!props.home && window.location.pathname !== "/" && (
            <div style={{ height: 70 }} />
          )}
          {!props.home && window.location.pathname !== "/" && (
            <div className={styles.headerDesktopFixed}>
              <div className="container">
                <Row>
                  <Col span={12}>
                    {AppStore.logovisible && (
                      <div className={styles.logo}>
                        <Link to="/">
                          <img src={logo2} alt="logo" width={80} />
                        </Link>
                      </div>
                    )}
                  </Col>
                  <Col span={12} className={styles.textright}>
                    <LoginWidget />
                  </Col>
                </Row>
              </div>
            </div>
          )}
          {props.home && (
            <div className={styles.headerDesktop}>
              <div className="container">
                <Row>
                  <Col span={12}>
                    {AppStore.logovisible && (
                      <div className={styles.logo}>
                        <Link to="/">
                          <img src={logo2} alt="logo" width={80} />
                        </Link>
                      </div>
                    )}
                  </Col>
                  <Col span={12} className={styles.textright}>
                    <LoginWidget />
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default observer(Header);
