import React from "react";
import styles from "../ExpertsItemGorizont/ExpertsItemGorizont.module.scss";
import Rating from "../Rating/index";
import { Link, useNavigate } from "react-router-dom";
import Utils from "../../Utils";
import AppStore from "../App/AppStore";
import { Col, Progress, Row } from "antd";
import SearchStore from "../Search/SearchStore";
import ImagePreview from "../ImagePreview";
import { AutoSizer } from "react-virtualized";
import premium from "../../assets/images/premium.svg";

const ExpertsItemGorizont2 = ({ item, index }) => {
  let navigate = useNavigate();
  if (AppStore.width <= 767)
    return (
      <a
        className={styles.expert_item_wrp_gorizont}
        onClick={() => {
          SearchStore.setDrawerOpen(false);
          navigate("/card/" + item?._id);
        }}
      >
        <Row>
          {/*!!index && (*/}
          <Col flex={"32px"} className={styles.number}>
            {index + 1}
          </Col>
          {/* )*/}
          <Col flex={"auto"}>
            <Row>
              <Col flex={"90px"}>
                <div style={{ width: 70, height: 70, position: "relative" }}>
                  <ImagePreview
                    src={`${Utils.urlAvatar(item?.profileData?.avatar)}`}
                    style={{
                      borderRadius: 16,
                      border: 0,
                      width: 70,
                      height: 70,
                      backgroundColor: "#fff",
                    }}
                  />
                  {item.premium && (
                    <img
                      src={premium}
                      alt={""}
                      style={{
                        width: 20,
                        position: "absolute",
                        bottom: -5,
                        right: -5,
                      }}
                    />
                  )}
                </div>
              </Col>
              <Col flex={"auto"} className={styles.reviewBlock}>
                <AutoSizer
                  disableHeight={true}
                  style={{ display: "inline-block" }}
                >
                  {({ width }) => (
                    <>
                      <p className={styles.fio} style={{ width: width - 10 }}>
                        {item?.profileData?.fio}
                      </p>
                      <div
                        className={styles.categoryName}
                        style={{ width: width }}
                      >
                        {!!item.profileData.category &&
                          item.profileData.category.length > 0 &&
                          item.profileData.category
                            .slice(0, 2)
                            .map((item, index) => (
                              <span key={index} className={styles.categoryItem}>
                                {item.name}
                              </span>
                            ))}
                      </div>
                    </>
                  )}
                </AutoSizer>
              </Col>
            </Row>
          </Col>
          <Col flex={"36px"} className={styles.reviewBlock}>
            <div className={styles.review}>
              <div className={styles.bg} />
              <Progress
                type="circle"
                size={35}
                strokeColor={"#0029FF"}
                percent={(item?.profileData?.review * 100) / 5}
                format={() => (
                  <span style={{ color: "#1242CE" }}>
                    {item?.profileData?.review || 0}
                  </span>
                )}
              />
            </div>
          </Col>
        </Row>
      </a>
    );

  return (
    <Link
      className={styles.expert_item_wrp_gorizont2}
      to={"/card/" + item?._id}
    >
      <Row>
        <Col flex={"40px"} className={styles.number}>
          {index + 1}
        </Col>

        <Col flex={"auto"}>
          <Row>
            <Col flex={"90px"}>
              <div style={{ width: 70, height: 70, position: "relative" }}>
                <ImagePreview
                  src={`${Utils.urlAvatar(item?.profileData?.avatar)}`}
                  style={{
                    borderRadius: 16,
                    border: 0,
                    width: 70,
                    height: 70,
                    backgroundColor: "#fff",
                  }}
                />
                {item.premium && (
                  <img
                    src={premium}
                    alt={""}
                    style={{
                      width: 20,
                      position: "absolute",
                      bottom: -5,
                      right: -5,
                    }}
                  />
                )}
              </div>
            </Col>
            <Col flex={"auto"} className={styles.reviewBlock}>
              <AutoSizer
                disableHeight={true}
                style={{ display: "inline-block" }}
              >
                {({ width }) => (
                  <>
                    <p className={styles.fio} style={{ width: width }}>
                      {item?.profileData?.fio}
                    </p>
                    <div
                      className={styles.categoryName}
                      style={{ width: width }}
                    >
                      {!!item.profileData.category &&
                        item.profileData.category.length > 0 &&
                        item.profileData.category
                          .slice(0, 2)
                          .map((item, index) => (
                            <span key={index} className={styles.categoryItem}>
                              {item.name}
                            </span>
                          ))}
                    </div>
                  </>
                )}
              </AutoSizer>
            </Col>
          </Row>
        </Col>
        <Col flex={"36px"} className={styles.reviewBlock}>
          <div className={styles.review}>
            <div className={styles.bg} />
            <Progress
              type="circle"
              size={35}
              strokeColor={"#0029FF"}
              percent={(item?.profileData?.review * 100) / 5}
              format={() => (
                <span style={{ color: "#1242CE" }}>
                  {item?.profileData?.review || 0}
                </span>
              )}
            />
          </div>
        </Col>
      </Row>
    </Link>
  );
};

export default ExpertsItemGorizont2;
