import React, { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { Result } from "antd";
import Home from "../../pages/Home";
import CabinetLayout from "../../layouts/CabinetLayout";
import AuthLayout from "../../layouts/AuthLayout";
import HomeLayout from "../../layouts/HomeLayout";
import PersonalUser from "../PersonalUser/PersonalUser";
import CaseAddEdit from "../Cases/CaseAddEdit";
import ExpertCard from "../ExpertCard/index";
import Login from "../Auth/Login";
import Create from "../Auth/Create";
import AppStore from "./AppStore";
import userStore from "../Auth/userStore";
import CaseCard from "../Cases/CaseCard";
import Cases from "../../pages/Сases";
import ReviewsPage from "../ReviewsPage";
import Favorites from "../Favorites";
import ExpertCardByUserName from "../ExpertCard/ExpertCardByUserName";
import ExpertsTopList from "../Experts/ExpertsTopList";
import AllExperts from "../../pages/AllExperts";
import AllCompany from "../../pages/AllCompany";
import Restore from "../Auth/Restore";
import VerifyEmail from "../Auth/VerifyEmail";
import Search from "../Search/Search";
import Ads from "../Ads/Ads";
import AdminLayout from "../../layouts/AdminLayout";
import Admin from "../Admin/Admin";
import CompanyCard from "../Company/CompanyCard";

// import Restore from "../Auth/Restore";
// import VerifyEmail from "../Auth/VerifyEmail";

function NoMatch() {
  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle="Извините, страница, которую вы посетили, не существует. "
        extra={
          <a href={"/"} type="primary">
            На главную
          </a>
        }
      />
    </div>
  );
}

function App() {
  useEffect(() => {
    userStore.authCheck().catch(console.error);
  }, []);

  let element = useRoutes([
    {
      path: "/",
      element: <AuthLayout />,
      children: [
        { path: "/login", element: <Login /> },
        { path: "/create", element: <Create /> },
        { path: "/restore", element: <Restore /> },
        { path: "/verify_email", element: <VerifyEmail /> },
      ],
    },
    {
      path: "/",
      element: <HomeLayout />,
      children: [
        { index: true, element: <Home /> },
        { path: "/card", element: <ExpertCard /> },
        { path: "/card/:userId", element: <ExpertCard /> },
        { path: "/expert/:userName", element: <ExpertCardByUserName /> },
        { path: "/cases/view/:caseId", element: <CaseCard /> },
        { path: "/allexperts", element: <AllExperts /> },
        { path: "/allcompany", element: <AllCompany /> },
        { path: "/search", element: <Search /> },
        { path: "/ads", element: <Ads /> },
        { path: "/company/:userId", element: <CompanyCard /> },
      ],
    },
    {
      path: "/cabinet",
      element: <CabinetLayout />,
      children: [
        { index: true, element: <PersonalUser /> },
        { path: "/cabinet/cases/add", element: <CaseAddEdit /> },
        { path: "/cabinet/cases/edit/:caseId", element: <CaseAddEdit /> },
        { path: "/cabinet/mycases", element: <Cases /> },
        { path: "/cabinet/casecard", element: <CaseCard /> },
        { path: "/cabinet/reviews", element: <ReviewsPage /> },
        { path: "/cabinet/favorites", element: <Favorites /> },
      ],
    },
    {
      path: "/admin",
      element: <AdminLayout />,
      children: [{ index: true, element: <Admin /> }],
    },
    { path: "*", element: <NoMatch /> },
  ]);

  return <>{element}</>;
}

export default App;

AppStore.resize(window.innerWidth, window.innerHeight);

(function () {
  window.addEventListener("resize", resizeThrottler, false);

  let resizeTimeout;

  function resizeThrottler() {
    // ignore resize events as long as an actualResizeHandler execution is in the queue
    if (!resizeTimeout) {
      resizeTimeout = setTimeout(() => {
        resizeTimeout = null;
        actualResizeHandler(this);

        // The actualResizeHandler will execute at a rate of 15fps
      }, 500);
    }
  }

  function actualResizeHandler(e) {
    AppStore.resize(e.innerWidth, e.innerHeight);
  }
})();
