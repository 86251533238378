import React, {useEffect} from 'react';
import {Button, Checkbox, Empty, Form, Input, Slider, Radio, Spin, Table, Tag, Progress} from 'antd';
import SearchStore from "../Search/SearchStore";
import styles from "../Search/Search.module.scss";
import {SearchOutlined} from "@ant-design/icons";
import starRating from "../../assets/images/starRating.svg";
import SearchModalSelect from "../Search/SearchModalSelect";
import InfiniteScroll from "react-infinite-scroller";
import ExpertsBlockItem2 from "../ExpertsBlockItem/ExpertsBlockItem2";
import {observer} from "mobx-react-lite";
import {Link, useNavigate} from "react-router-dom";
import ImagePreview from "../ImagePreview";
import Utils from "../../Utils";
import note from "../../assets/images/note.svg";
import clock from "../../assets/images/clock.svg";
import skill from "../../assets/images/skill.svg";

const columns = [
  {
    title: '',
    dataIndex: 'name',
    key: 'name',
    render: (text, record, index) => {
      return <ImagePreview src={Utils.urlAvatar(record?.profileData?.avatar)} height={40} width={40}/>
    },
  },
  {
    title: '',
    dataIndex: 'fio',
    key: 'fio',
    render: (text, record, index) => {
      return <>
        {record?.profileData?.fio}
        <div>
          {!!record.profileData.category &&
          record.profileData.category.length > 0 &&
          record.profileData.category.slice(0, 1).map((item, index) => (
            <span key={index} className={styles.categoryName}>
                    {item?.name}
                  </span>
          ))}
        </div>
        </>
    },
  },
  {
    title: '',
    dataIndex: 'data',
    key: 'data',
    render: (text, record, index) => {
      return <>
        <div className={styles.info_bll}>
          <div className={styles.info_bll_item}>
            <img src={note} alt={""} />
            <span>{record.profileData?.reviewCount || 0}</span>
          </div>
          <div className={styles.info_bll_item}>
            <img src={clock} alt={""} />
            <span>{record.profileData.experience}</span>
          </div>
          <div className={styles.info_bll_item}>
            <img src={skill} alt={""} />
            <span>{record.profileData?.skills?.length || 0}</span>
          </div>
        </div>
        </>
    },
  },
  {
    title: '',
    dataIndex: 'r',
    key: 'r',
    render: (text, record, index) => {
      return <>
        <div className={styles.review}>
          <div className={styles.bg} />
          <Progress
            type="circle"
            size={30}
            strokeColor={"#0029FF"}
            percent={(record?.profileData?.review * 100) / 5}
            format={() => <span>{record?.profileData?.review || 0}</span>}
          />
        </div>
      </>
    },
  },
];

const Admin = () => {

  let navigate = useNavigate();
  const expertListPageItems = SearchStore.expertListPageItems;

  useEffect(() => {
    SearchStore.categoryList();
    SearchStore.skillsList();
    SearchStore.countryList();
    SearchStore.citiesList();
    SearchStore.adminList({clear: true})
    SearchStore.adminListPage({page: 1})
    return () => {
      SearchStore.clearFilter()
    }
  }, [])

  return <div style={{backgroundColor:'#F4FBFF'}}>
    <div className="container">

      <div className={styles.flex_tit + " mb-16"}>
        <h3 className={styles.title_main + " mt-0"} style={{fontSize: 20}}>
          Эксперты и компании[Всего: {SearchStore.expertFull} | Найдено: {SearchStore.expertSearch}]
        </h3>
      </div>

      <div className={styles.searchInputBlock}>
        <Input size="large"
               placeholder="Введите имя"
               className={styles.searchInput}
               prefix={<SearchOutlined/>}
               value={SearchStore.filter.searchValue}
               onChange={(e) => {
                 SearchStore.setSearchValue(e.target.value);
               }}
               onPressEnter={() => {
                 SearchStore.adminList({clear: true})
               }}
        />
      </div>

      <div className={styles.flex_sidebar}>

        <div className={styles.filterBlock}>
          <div className={styles.filterBlockContent}>
            <p className={styles.filterTitle}>Фильтр</p>

            <Form name="basic" labelCol={{span: 24}} wrapperCol={{span: 24}}>

              <div className={'mt-8 mb-8'}>
                <p>По рейтингу <img src={starRating} alt={''} style={{width: 14}}/></p>
                <Slider
                  marks={{1: 0, 5: 5}}
                  value={SearchStore.filter.rating}
                  min={1}
                  max={5}
                  range
                  onChange={(v) => {SearchStore.setFilterRating(v)}}
                />
              </div>

              <Form.Item label="Тип">
                <Radio.Group onChange={(e)=>{
                  SearchStore.setFilterRole(e.target.value)
                }} value={SearchStore.filter.role}>
                  <Radio value={'all'} checked={SearchStore.filter.role==='all'}>Все</Radio>
                  <Radio value={'expert'} checked={SearchStore.filter.role==='expert'}>Эксперт</Radio>
                  <Radio value={'company'} checked={SearchStore.filter.role==='company'}>Компания</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item label="Статус">
                <Radio.Group onChange={(e)=>{
                  SearchStore.setFilterPremium(e.target.value)
                }} value={SearchStore.filter.premium}>
                  <Radio value={'all'} checked={SearchStore.filter.premium==='all'}>Все</Radio>
                  <Radio value={'premium'} checked={SearchStore.filter.premium==='premium'}>VIP</Radio>
                  <Radio value={'default'} checked={SearchStore.filter.premium==='default'}>Обычные</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item label="Категория">
                <SearchModalSelect
                  mode="multiple"
                  modalTitle={"Категории"}
                  modalAllTitle={"Все категории"}
                  // modalPopularTitle={"Популярные категории"}
                  // modalPopularOptions={SearchStore.optionsCategory.filter((item) => [
                  //   '63da66486e62e680c299af0e','63da67236e62e680c299af10','63da672d6e62e680c299af11'
                  // ].includes(item.value))}
                  placeholder="Категория"
                  onChange={(value) => {
                    SearchStore.setFilterCategory(value);
                  }}
                  value={SearchStore.filter.category}
                  options={SearchStore.optionsCategory}
                  onSelectAdd={(v) => SearchStore.addFilterCategoryItem(v)}
                  onSelectDel={(v) => SearchStore.delFilterCategoryItem(v)}
                />
              </Form.Item>
              <Form.Item label="Навыки">
                <SearchModalSelect
                  mode="multiple"
                  modalTitle={"Навыки"}
                  modalAllTitle={"Все навыки"}
                  // modalPopularTitle={"Популярные навыки"}
                  // modalPopularOptions={SearchStore.optionsSkills.filter((item) => [
                  //   '63da64c56e62e680c299af0a','63da64b36e62e680c299af09','63dc9b1a6e62e680c299af22'
                  // ].includes(item.value))}
                  placeholder="Навыки"
                  onChange={(value) => {
                    SearchStore.setFilterSkills(value);
                  }}
                  value={SearchStore.filter.skills}
                  options={SearchStore.optionsSkills}
                  onSelectAdd={(v) => SearchStore.addFilterSkillsItem(v)}
                  onSelectDel={(v) => SearchStore.delFilterSkillsItem(v)}
                />
              </Form.Item>
              <Form.Item label="Страна">
                <SearchModalSelect
                  modalTitle={"Страна"}
                  modalAllTitle={"Все страны"}
                  placeholder="Страна"
                  mode="multiple"
                  onChange={(value) => {
                    SearchStore.setFilterCountry(value);
                  }}
                  value={SearchStore.filter.country}
                  options={SearchStore.optionsCountry}
                  showArrow={true}
                  optionFilterProp="label"
                  onSelectAdd={() => {}}
                  onSelectDel={(v) => SearchStore.delFilterCountryItem(v)}
                />{" "}
              </Form.Item>
              <Form.Item label="Город">
                <SearchModalSelect
                  modalTitle={"Город"}
                  modalAllTitle={"Все города"}
                  // modalPopularTitle={"Популярные города"}
                  // modalPopularOptions={SearchStore.optionsCities.filter((item) => [
                  //   '642fecc3cb9b4d831fd86a17','642fecc3cb9b4d831fd86a21'
                  // ].includes(item.value))}
                  placeholder="Город"
                  mode="multiple"
                  onChange={(value) => {
                    SearchStore.setFilterCities(value);
                  }}
                  value={SearchStore.filter.city}
                  options={SearchStore.optionsCities}
                  showArrow={true}
                  optionFilterProp="label"
                  onSelectAdd={(v) => SearchStore.addFilterCitiesItem(v)}
                  onSelectDel={(v) => SearchStore.delFilterCitiesItem(v)}
                />{" "}
              </Form.Item>

              <Button
                onClick={() => {
                  SearchStore.adminListPage({page: 1});
                }}
                type={"primary"}
                block
              >
                Применить фильтр
              </Button>

              <span
                className={styles.filterClear}
                onClick={() => {
                  SearchStore.clearFilter();
                }}
              >Сбросить фильтр</span>
            </Form>

          </div>


        </div>
        <div className={styles.searchList}>
          {!expertListPageItems.length &&
          SearchStore.fetchCheck && (
            <div style={{ width: "100%", textAlign: "center" }}>
              <Spin />
            </div>
          )}
          {!expertListPageItems.length &&
          !SearchStore.fetchCheck && (
            <div style={{ width: "100%", textAlign: "center" }}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={"По вашим критериям ничего не найдено"}
              />
            </div>
          )}
          {!!expertListPageItems.length && (
            <Table dataSource={expertListPageItems}
                   columns={columns}
                   style={{marginTop: 20, width:'100%'}}
                   showHeader={false}
                   rowClassName={styles.tableExpertItem}
                   pagination={{
                     pageSize: 10,
                     showSizeChanger: false,
                     total: SearchStore.expertListPageItemsCount,
                     onChange: (page, pageSize) => {
                       SearchStore.adminListPage({page});
                     },
                   }}
                   onRow={(record, rowIndex) => {
                     return {
                       onClick: (event) => {
                         navigate("/card/" + record?._id)
                       }, // click row
                     };
                   }}
            />
            // <InfiniteScroll
            //   style={{ width: "100%", minHeight: 50, padding: '20px 0' }}
            //   pageStart={0}
            //   loadMore={() => SearchStore.adminList({})}
            //   hasMore={SearchStore.expertListLoadCheck}
            //   loader={
            //     <div className="loader" key={0}>
            //       <Spin />
            //     </div>
            //   }
            // >
            //   {expertListItems.map((item, index) => (
            //     <div key={index} style={{width: '33.3%', display: 'inline-block'}} >
            //       <div style={{padding: '0 5px 10px'}}>
            //         <ExpertsBlockItem2 item={item} />
            //       </div>
            //     </div>
            //   ))}
            // </InfiniteScroll>
          )}
        </div>
      </div>

    </div>
  </div>
}

export default observer(Admin);
