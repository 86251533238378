import {configure, makeAutoObservable} from "mobx";

configure({enforceActions: "never"})

class PremiumStore {

  modalOpen = false;

  constructor() {
    makeAutoObservable(this)
  }

  setModalOpen(v){
    this.modalOpen = v;
  }
}

const clsPremiumStore = new PremiumStore()
export default clsPremiumStore