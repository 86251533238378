import React from "react";
import styles from "../PredFooter/PredFooter.module.scss";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Row } from "antd";
import foton from "../../assets/images/foton.png";
import "animate.css/animate.compat.css";

const PredFooter = () => {
  return (
    <div className={styles.pred_footer}>
      <div className="container">
        <div className={styles.felx_ffoot}>
          <div>
            <ScrollAnimation animateIn="fadeIn">
              <p className={styles.bloc_abs2}>100 тыс. счастливых клиентов</p>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
              <p className={styles.bloc_abs1}>более 100 успешный проектов</p>
            </ScrollAnimation>
            <img src={foton} />
          </div>

          <div className={styles.pred_footer__right}>
            <ScrollAnimation
              style={{ with: "100%" }}
              animateIn="bounceInRight"
              animateOut="bounceOutLeft"
            >
              <p>Поиск специалистов стал доступным!</p>
              <span>
                Здесь вы найдёте сотни предложений от самых востребованных
                профессионалов.
              </span>
              <ul>
                <li>Крупнейшая база экспертов и работодателей</li>
                <li>Прозрачная система рейтингов</li>
                <li>Понятный и удобный интерфейс</li>
                <li>
                  Ежедневная публикация выгодных предложений от лидеров рынка.
                </li>
              </ul>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PredFooter;
