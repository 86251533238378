import React from "react";
import styles from "../BestCategories/BestCategories.module.scss";
import { Row, Col } from "antd";
import bs1 from "../../assets/images/bs1.svg";
import bs2 from "../../assets/images/bs2.svg";
import bs3 from "../../assets/images/bs3.svg";
import bs4 from "../../assets/images/bs4.svg";
import bs5 from "../../assets/images/bs5.svg";
import bs6 from "../../assets/images/bs6.svg";
import bs7 from "../../assets/images/bs7.svg";
import bs8 from "../../assets/images/bs8.svg";
import Allcat from "../Allcat/index";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AppStore from "../App/AppStore";
import SearchStore from "../Search/SearchStore";

const BestCategories = () => {
  let navigate = useNavigate();
  return (
    <>
      {AppStore.width >= 600 && (
        <div className={styles.best_wrp}>
          <div className="container">
            <div className={styles.best_wrp__flex}>
              <p className={styles.best_wrp__title}>
                Лучшие специалисты по категориям{" "}
                <span>Получите вдохновение от 1000+ объявлении</span>
              </p>
              <Allcat title={"Все категории"} link="/search" />
            </div>

            <Row>
              <Col className="mb-32" xs={24} sm={12} md={12} lg={6} xl={6}>
                <div className={styles.best_item}>
                  <div
                    onClick={() => {
                      SearchStore.addFilterCategoryItem(
                        "6589a04b3b658096128e25bc",
                        true
                      );
                      if (AppStore.width <= 767)
                        SearchStore.setDrawerOpen(true);
                      else navigate("/search", { replace: true });
                    }}
                  >
                    <div>
                      <img src={bs1} />
                      <p>
                        <b>IT профессии</b>
                        <span>8 объявлений</span>
                      </p>
                    </div>
                    <p>
                      Менеджер проектов, Тестировщик, UX-UI-дизайнер и многие
                      другие.
                    </p>
                  </div>
                </div>
              </Col>
              <Col className="mb-32" xs={24} sm={12} md={12} lg={6} xl={6}>
                <div className={styles.best_item}>
                  <div
                    onClick={() => {
                      SearchStore.addFilterCategoryItem(
                        "6589a04d3b658096128e2630",
                        true
                      );
                      if (AppStore.width <= 767)
                        SearchStore.setDrawerOpen(true);
                      else navigate("/search", { replace: true });
                    }}
                  >
                    <div>
                      <img alt="" src={bs2} />
                      <p>
                        <b>Веб-дизайн</b>
                        <span>16 объявлений</span>
                      </p>
                    </div>
                    <p>
                      Веб-дизайнер, Бренд-менеджер, Графический дизайнер и
                      многие другие.
                    </p>
                  </div>
                </div>
              </Col>
              <Col className="mb-32" xs={24} sm={12} md={12} lg={6} xl={6}>
                <div className={styles.best_item}>
                  <div
                    onClick={() => {
                      SearchStore.addFilterCategoryItem(
                        "6589a04d3b658096128e25fd",
                        true
                      );
                      if (AppStore.width <= 767)
                        SearchStore.setDrawerOpen(true);
                      else navigate("/search", { replace: true });
                    }}
                  >
                    <div>
                      <img alt="" src={bs3} />
                      <p>
                        <b>Маркетинг, реклама и PR</b>
                        <span>8 объявлений</span>
                      </p>
                    </div>
                    <p>
                      СММ-специалист, Маркетолог, PR-менеджер и многие другие.
                    </p>
                  </div>
                </div>
              </Col>
              <Col className="mb-32" xs={24} sm={12} md={12} lg={6} xl={6}>
                <div className={styles.best_item}>
                  <div
                    onClick={() => {
                      SearchStore.addFilterCategoryItem(
                        "6589a04d3b658096128e2615",
                        true
                      );
                      if (AppStore.width <= 767)
                        SearchStore.setDrawerOpen(true);
                      else navigate("/search", { replace: true });
                    }}
                  >
                    <div>
                      <img alt="" src={bs4} />
                      <p>
                        <b>Копирайтинг</b>
                        <span>8 объявлений</span>
                      </p>
                    </div>
                    <p>Копирайтер, Редактор, Переводчик и многие другие.</p>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                <div className={styles.best_item}>
                  <div
                    onClick={() => {
                      SearchStore.addFilterCategoryItem(
                        "64bfc63f3642f8471a11404f",
                        true
                      );
                      if (AppStore.width <= 767)
                        SearchStore.setDrawerOpen(true);
                      else navigate("/search", { replace: true });
                    }}
                  >
                    <div>
                      <img alt="" src={bs5} />
                      <p>
                        <b>Музыка и аудио</b>
                        <span>8 объявлений</span>
                      </p>
                    </div>
                    <p>
                      Звукорежиссёр, Саунд-дизайнер, Музыкальный редактор и
                      многие другие.
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                {" "}
                <div className={styles.best_item}>
                  <div
                    onClick={() => {
                      SearchStore.addFilterCategoryItem(
                        "6589a04d3b658096128e2603",
                        true
                      );
                      if (AppStore.width <= 767)
                        SearchStore.setDrawerOpen(true);
                      else navigate("/search", { replace: true });
                    }}
                  >
                    <div>
                      <img alt="" src={bs6} />
                      <p>
                        <b>Видеомонтаж</b> <span>8 объявлений</span>
                      </p>
                    </div>
                    <p>Оператор, Монтажёр, Мультипликатор и многие другие.</p>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                {" "}
                <div className={styles.best_item}>
                  <div
                    onClick={() => {
                      SearchStore.addFilterCategoryItem(
                        "6589a04d3b658096128e261e",
                        true
                      );
                      if (AppStore.width <= 767)
                        SearchStore.setDrawerOpen(true);
                      else navigate("/search", { replace: true });
                    }}
                  >
                    <div>
                      <img alt="" src={bs7} />
                      <p>
                        <b>Программирование</b>
                        <span>8 объявлений</span>
                      </p>
                    </div>
                    <p>
                      Системный администратор, 1С-программист, Java-разработчик
                      и многие другие.
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                {" "}
                <div className={styles.best_item}>
                  <div
                    onClick={() => {
                      SearchStore.addFilterCategoryItem(
                        "650ea3081f1e023554641e3a",
                        true
                      );
                      if (AppStore.width <= 767)
                        SearchStore.setDrawerOpen(true);
                      else navigate("/search", { replace: true });
                    }}
                  >
                    <div>
                      <img alt="" src={bs8} />
                      <p>
                        <b>Финансы и бухгалтерский учёт</b>{" "}
                        <span>8 объявлений</span>
                      </p>
                    </div>
                    <p>Аудитор, Бухгалтер, HR-менеджер и многие другие.</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default BestCategories;
