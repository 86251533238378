import {Input, Modal, Form, Rate, Button} from "antd";
import ReviewsStore from "./ReviewsStore";
import {observer} from "mobx-react-lite";
import {useEffect} from "react";

const {TextArea} = Input;

const AnswerAdd = () => {

  const [form] = Form.useForm();
  const isModalOpen = ReviewsStore.isAnswerModalOpen;

  const onFinish = (values) => {
    if (!isModalOpen.expertId || !isModalOpen.commentId) return;
    values.expertId = isModalOpen.expertId;
    values.commentId = isModalOpen.commentId;
    ReviewsStore.commentSave(values)
  }

  useEffect(() => {
    form.resetFields()
  }, [form, isModalOpen])

  return <>
    <Modal title="Добавить ответ к отзыву"
                open={isModalOpen}
                onCancel={() => {
                  ReviewsStore.answerModalClose()
                }}
                onOk={() => {
                  form.submit()
                }}
                cancelText={'Отмена'}
                okText={'Отправить'}
  >
    <Form
      name="basic"
      form={form}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        name="text"
        rules={[
          {
            required: true,
            message: 'Введите текст отзыва!',
          },
        ]}
      >
        <TextArea style={{width: '100%'}}/>
      </Form.Item>

    </Form>
  </Modal></>
}

export default observer(AnswerAdd);