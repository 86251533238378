import React, { useEffect } from "react";
import AdsStore from "./AdsStore";
import styles from "../Ads/Ads.module.scss";
import { Empty, Spin } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import AdsItem from "./AdsItem";
import { Carousel } from "antd";
import { observer } from "mobx-react-lite";
import AppStore from "../App/AppStore";
import Allcat from "../Allcat";

const setting = {
  dots: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows: true,
  nextArrow: <CaretRightOutlined />,
  prevArrow: <CaretLeftOutlined />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        arrows: false,
        slidesToScroll: 1,
      },
    },
  ],
};
const Ads = () => {
  const items = AdsStore.items;

  useEffect(() => {
    AdsStore.list({ clear: true });
  }, []);

  return (
    <div className={styles.ads}>
      <div className="container">
        <div className={styles.ads__flex}>
          <p className={styles.ads__title}>
            Объявления
            <span>Более тысячи предложений от компаний со всего мира!</span>
          </p>
          <Allcat title={"Показать все"} link="/ads" />
        </div>

        <div>
          {!items.length && AdsStore.fetchCheck && (
            <div style={{ width: "100%", textAlign: "center" }}>
              <Spin />
            </div>
          )}
          {!items.length && !AdsStore.fetchCheck && (
            <div style={{ width: "100%", textAlign: "center" }}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={"По вашим критериям ничего не найдено"}
              />
            </div>
          )}

          <div className="margin_minus">
            <Carousel {...setting}>
              {AdsStore.items.map((item, index) => (
                <div key={index}>
                  <AdsItem item={item} />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Ads);
