import React, { useEffect } from "react";
import styles from "../pages/Cases.module.scss";
import { Button, Spin, Empty } from "antd";
import CasesBlockItem from "../components/CasesBlockItem";
import { useNavigate } from "react-router-dom";
import CasesStore from "../components/Cases/CasesStore";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

function Cases() {
  let navigate = useNavigate();

  useEffect(() => {
    CasesStore.casesMy();
  }, []);

  return (
    <>
      <div
        className={styles.wrap_cases}
        style={{ maxWidth: 700, margin: "0 auto" }}
      >
        <div className="container">
          <div className={styles.flex_tit + " mb-16"}>
            <p className={styles.title_page}>Мои объявления</p>
            <Button
              icon={
                <span style={{ marginRight: 5 }}>
                  <FontAwesomeIcon icon={faPlus} />
                </span>
              }
              className={styles.caseAdd}
              onClick={() => {
                navigate("/cabinet/cases/add", { replace: true });
              }}
            >
              Добавить
            </Button>
          </div>
          {/*<div className={styles.fl_bl + " mb-24"}>
            <Form layout="inline">
              <Form.Item name="search" rules={[{message: 'Please input your username!',},]}>
                <Input placeholder="Поиск..."/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  <SearchOutlined/>
                </Button>
              </Form.Item>
            </Form>
          </div>*/}

          <div className={styles.slider_blcase}>
            {CasesStore.caseMyItemsLoad && <Spin />}
            {!CasesStore.caseMyItems.length && (
              <div style={{ width: "100%", textAlign: "center" }}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={"Вы еще не добавили объявлений"}
                />
              </div>
            )}
            {!CasesStore.caseMyItemsLoad &&
              CasesStore.caseMyItems.map((item, index) => (
                <div key={index} className={styles.slider_bl_one}>
                  <CasesBlockItem item={item} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(Cases);
