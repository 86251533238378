import styles from "../../pages/Home.module.scss";
import { Spin, Empty, Row, Col } from "antd";
import ExpertsBlockItemBig from "../ExpertsBlockItemBig";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import ExpertsStore from "./ExpertsStore";
import { observer } from "mobx-react-lite";
import AppStore from "../App/AppStore";
import Allcat from "../Allcat";
import ExpertsBlockItem2 from "../ExpertsBlockItem/ExpertsBlockItem2";

const ExpertsAllList = () => {
  const expertListItems = ExpertsStore.expertListItems;

  useEffect(() => {
    ExpertsStore.skillsCategoryList();
    ExpertsStore.citiesList();
    ExpertsStore.expertsAllList({ clear: true, sort: "_id" });
    ExpertsStore.expertsList({ clear: true });
  }, []);

  return (
    <div className={styles.top_experts} id="formm">
      <div className="container">
        {AppStore.width > 900 ? (
          <>
            <div className={styles.flex_tit + " mb-16"}>
              <h4 className={styles.title_main}>Новые эксперты </h4>
              <Allcat title={"Все категории"} link="/allexperts" />
            </div>

            <div className={styles.flex_bl}>
              {!expertListItems.length && ExpertsStore.fetchCheck && (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <Spin />
                </div>
              )}
              {!expertListItems.length && !ExpertsStore.fetchCheck && (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={"По вашим критериям ничего не найдено"}
                  />
                </div>
              )}

              {!!expertListItems.length && (
                <InfiniteScroll
                  style={{
                    width: "100%",
                    minHeight: 50,
                    padding: "20px 0",
                  }}
                  pageStart={0}
                  loadMore={() => ExpertsStore.expertsList({})}
                  hasMore={ExpertsStore.expertListLoadCheck}
                  loader={
                    <div className="loader" key={0}>
                      <Spin />
                    </div>
                  }
                >
                  <div style={{ margin: "0 0px" }}>
                    {expertListItems.map(
                      (item, index) =>
                        index < 12 && (
                          <div
                            key={index}
                            style={{ width: "25%", display: "inline-block" }}
                          >
                            <div style={{ padding: "0 5px 20px" }}>
                              <ExpertsBlockItem2 item={item} />
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </InfiniteScroll>
              )}
            </div>
          </>
        ) : (
          <>
            <div className={styles.flex_tit + " mb-16"}>
              <h4 className={styles.title_main + " mt-0"} id={"searchExperts"}>
                Новые эксперты
              </h4>
            </div>
            <div className={styles.flex_sidebar}>
              <div className={styles.flex_bl}>
                {!ExpertsStore.expertsAllListItems.length &&
                  ExpertsStore.fetchCheck && (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <Spin />
                    </div>
                  )}
                {!ExpertsStore.expertsAllListItems.length &&
                  !ExpertsStore.fetchCheck && (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={"По вашим критериям ничего не найдено"}
                      />
                    </div>
                  )}

                {!!ExpertsStore.expertsAllListItems.length && (
                  <InfiniteScroll
                    style={{ width: "100%", minHeight: 300 }}
                    pageStart={0}
                    loadMore={() =>
                      ExpertsStore.expertsAllList({ sort: "_id" })
                    }
                    hasMore={ExpertsStore.expertAllListLoadCheck}
                    loader={
                      <div className="loader" key={0}>
                        <Spin />
                      </div>
                    }
                  >
                    {/*{expertItems.map((item, index) =>*/}
                    {/*  <div key={index} className={styles.width_bl}><ExpertsBlockItem item={item}/></div>*/}
                    {/*)}*/}

                    {ExpertsStore.expertsAllListItems
                      .slice(0, 5)
                      .map((item, index) => (
                        <div key={index} className={styles.width_bl}>
                          <ExpertsBlockItemBig item={item} />
                          {/*<ExpertsBlockItem2 item={item} />*/}
                        </div>
                      ))}
                  </InfiniteScroll>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default observer(ExpertsAllList);
