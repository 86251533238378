import React, { useEffect, useState } from "react";
import styles from "../ExpertCard/ExpertCard.module.scss";
import { Spin, message } from "antd";
import star from "../../assets/images/starRating.svg";
import note from "../../assets/images/note.svg";
import clock from "../../assets/images/clock.svg";
import skill from "../../assets/images/skill.svg";
import star2 from "../../assets/images/star22.svg";
import { useNavigate, useParams } from "react-router-dom";
import CaseItem from "../Cases/CaseItem";
import ContactBlock from "../ContactBlock/index";
import userStore from "../Auth/userStore";
import AppStore from "../App/AppStore";
import { observer } from "mobx-react-lite";
import ImagePreview from "../ImagePreview";
import ExpertsStore from "../Experts/ExpertsStore";
import Utils from "../../Utils";
import TextTruncate from "react-text-truncate";
import Reviews from "../Reviews/Reviews";
import OrderModal from "../Cases/OrderModal";
import premium from "../../assets/images/premium.svg";
import PremiumStore from "../Premium/PremiumStore";
import { PhoneOutlined } from "@ant-design/icons";
import {
  LikeOutlined,
  FieldTimeOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";

const ExpertCard = ({ userId }) => {
  const params = useParams();
  if (!userId && params.userId) userId = params.userId;
  let navigate = useNavigate();

  let width = AppStore.width - 32;
  if (width > 700) width = 700;

  const [itemsToShow, setItemsToShow] = useState(3);
  const showmore = () => {
    setItemsToShow(ExpertsStore.expertCardData.skills.length);
  };

  const showless = () => {
    setItemsToShow(3);
  };
  const [textTruncate, setTextTruncate] = useState(true);
  const [skillTruncate, setSkillTruncate] = useState(true);

  useEffect(() => {
    if (!userId)
      return navigate("/cabinet/card/" + userStore.userData._id, {
        replace: true,
      });
    ExpertsStore.expertCard(userId);
    if (width > 900) AppStore.setLogovisible(false);
    ExpertsStore.clearFilter();
    return () => {
      AppStore.setLogovisible(true);
    };
  }, [userId, navigate]);

  if (ExpertsStore.expertCardLoad && !ExpertsStore?.expertCardData?.profile)
    return <Spin />;
  if (!ExpertsStore?.expertCardData || !userId) return null;
  if (ExpertsStore?.expertCardData?.role?.company) {
    navigate("/company/" + userId, {
      replace: true,
    });
    return null;
  }

  return (
    <div className={styles.card_bl}>
      <div className="container">
        {AppStore.width <= 768 ? (
          <div
            className={styles.mobile_bl}
            style={{ maxWidth: 700, margin: "0 auto" }}
          >
            <div className={styles.block_immd}>
              <div class={styles.flex_bl_f}>
                <div
                  style={{
                    backgroundImage: `url(${Utils.urlAvatar(
                      ExpertsStore?.expertCardData?.profile?.avatar
                    )})`,
                  }}
                  className={styles.avatarBlock}
                />

                <div className={styles.avatartext}>
                  <p>{ExpertsStore.expertCardData.profile.fio}</p>
                  <span className={styles.skillsCategory}>
                    {ExpertsStore.expertCardData.skillsCategory &&
                      ExpertsStore.expertCardData.skillsCategory.map((item) =>
                        ExpertsStore.expertCardData.skillsCategory.length >
                        1 ? (
                          <span>{item.name} | </span>
                        ) : (
                          <span>{item.name} </span>
                        )
                      )}
                  </span>
                </div>
              </div>
              <div className={styles.buttons_group + " mt-8"}>
                <a
                  onClick={() => {
                    const textField = document.createElement("textarea");
                    textField.innerText = `https://vpoiske.kz/expert/${userStore.userData.userName}`;
                    document.body.appendChild(textField);
                    textField.select();
                    document.execCommand("copy");
                    textField.remove();
                    message.info("Ссылка скопирована в буфер обмена");
                  }}
                  className={styles.share_but}
                >
                  <img src={note} alt={""} /> Поделиться{" "}
                </a>
                <OrderModal
                  expertId={userId}
                  imgbl={<PhoneOutlined />}
                  buttonText={"Связаться"}
                />
              </div>
              <div className={styles.info_bll}>
                <div className={styles.info_bll_item}>
                  <img src={note} alt={""} />
                  <span>
                    {ExpertsStore.expertCardData?.cases?.length || 0}{" "}
                    {Utils.declOfNum(
                      ExpertsStore.expertCardData?.cases?.length || 0,
                      ["объявление", "объявления", "объявлений"]
                    )}
                  </span>
                </div>
                <div className={styles.info_bll_item}>
                  <img src={clock} alt={""} />
                  <span>
                    {ExpertsStore.expertCardData.profile?.experience}{" "}
                    {Utils.declOfNum(
                      ExpertsStore.expertCardData.profile?.experience || 0,
                      ["год опыта", "года опыта", "лет опыта"]
                    )}
                  </span>
                </div>
                <div className={styles.info_bll_item}>
                  <img src={skill} alt={""} />
                  <span>
                    {ExpertsStore.expertCardData?.skills?.length || 0}{" "}
                    {Utils.declOfNum(
                      ExpertsStore.expertCardData?.skills?.length || 0,
                      ["навык", "навыка", "навыков"]
                    )}
                  </span>
                </div>
              </div>
              <div className={styles.backgr_vv}>
                <div className={styles.flex_bl}>
                  <p className={styles.backgr_tit}>#Обо мне</p>
                  <div className={styles.info_bll_item}>
                    <p className={styles.card_titl_rait}>
                      <span>
                        {ExpertsStore.expertCardData.profile?.review}{" "}
                      </span>
                      <img src={star} alt={""} />
                      <span className={styles.ratingContent}>
                        ({ExpertsStore.expertCardData.profile?.reviewCount || 0}{" "}
                        {Utils.declOfNum(
                          ExpertsStore.expertCardData.profile?.reviewCount || 0,
                          ["отзыв", "отзыва", "отзывов"]
                        )}
                        )
                      </span>
                    </p>
                  </div>
                </div>
                {textTruncate ? (
                  <TextTruncate
                    line={8}
                    element="p"
                    truncateText="…"
                    text={ExpertsStore.expertCardData.profile.description}
                    textTruncateChild={
                      <a
                        href={"#"}
                        style={{ fontSize: 12 }}
                        onClick={() => setTextTruncate(false)}
                      >
                        Показать все
                      </a>
                    }
                  />
                ) : (
                  <p>
                    {ExpertsStore.expertCardData.profile.description}{" "}
                    <a
                      href={"#"}
                      style={{ fontSize: 12 }}
                      onClick={() => setTextTruncate(true)}
                    >
                      Свернуть
                    </a>
                  </p>
                )}
              </div>

              <div className={styles.navik} id={"skills"}>
                {!!ExpertsStore.expertCardData.skills &&
                  ExpertsStore.expertCardData.skills.length > 0 && (
                    <>
                      {ExpertsStore.expertCardData.skills
                        .slice(0, itemsToShow)
                        .map((item, index) => (
                          <p key={index}>{item.name}</p>
                        ))}
                    </>
                  )}
                {itemsToShow === 3 ? (
                  <button className={styles.but_link} onClick={showmore}>
                    Показать больше
                  </button>
                ) : (
                  <button className={styles.but_link} onClick={showless}>
                    Скрыть
                  </button>
                )}
              </div>
            </div>
            <div className={styles.text_name}>
              <TextTruncate
                line={1}
                element="p"
                truncateText="…"
                text={
                  <>
                    {ExpertsStore.expertCardData.premium && (
                      <img
                        src={premium}
                        alt={""}
                        style={{
                          width: 24,
                          verticalAlign: "middle",
                          cursor: "pointer",
                        }}
                        onClick={() => PremiumStore.setModalOpen(true)}
                      />
                    )}
                  </>
                }
              />
            </div>

            {/* <div className={styles.navik} id={"skills"}>
              {!!ExpertsStore.expertCardData.skills &&
                ExpertsStore.expertCardData.skills.length > 0 && (
                  <>
                    {skillTruncate ? (
                      <>
                        {ExpertsStore.expertCardData.skills
                          .slice(0, 5)
                          .map((item, index) => (
                            <p key={index}>{item.name}</p>
                          ))}
                        <a
                          href={"#skills"}
                          style={{ fontSize: 12 }}
                          onClick={() => setSkillTruncate(false)}
                        >
                          Показать все
                        </a>
                      </>
                    ) : (
                      <>
                        {ExpertsStore.expertCardData.skills.map(
                          (item, index) => (
                            <p key={index}>{item.name}</p>
                          )
                        )}
                        <a
                          href={"#skills"}
                          style={{ fontSize: 12 }}
                          onClick={() => setSkillTruncate(true)}
                        >
                          Свернуть
                        </a>
                      </>
                    )}
                  </>
                )}
            </div>*/}

            {ExpertsStore.expertCardData.cases.length > 0 && (
              <>
                <div className={styles.flex_tit + " mb-16"}>
                  <h4 className={styles.title_main}>
                    <img src={star2} alt={""} /> Объявления
                  </h4>
                </div>
                <div className={styles.slider_blcase}>
                  {ExpertsStore.expertCardData.cases.map((item, index) => (
                    <div key={index} className={styles.slider_bl_one}>
                      <CaseItem
                        item={item}
                        arr={ExpertsStore.expertCardData.cases}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}

            <div className={styles.right_keys} style={{ marginBottom: "24px" }}>
              <ContactBlock profile={ExpertsStore.expertCardData?.profile} />
            </div>
            <div style={{ marginBottom: 50, marginTop: 0 }}>
              <Reviews expertId={userId} />
            </div>
            {/*
            <OrderModal
              expertId={userId}
              buttonStyle={{
                background: "rgba(15, 24, 43, 1)",
                color: "#fff",
                fontFamily: "Manrope-Medium",
                cursor: "pointer",
                borderRadius: 10,
                display: "block",
                textAlign: "center",
                fontSize: 16,
                padding: 16,
              }}
              buttonText={"Связаться"}
            />*/}
          </div>
        ) : (
          <>
            <div className={styles.flex_vvv}>
              <div className={styles.right_bl}>
                <div
                  className={styles.avatarBlock + " " + styles.avatarwidth}
                  style={{
                    backgroundImage: `url(${Utils.urlAvatar(
                      ExpertsStore?.expertCardData?.profile?.avatar
                    )})`,
                  }}
                />

                <div className={styles.text_name}>
                  <p>
                    {ExpertsStore.expertCardData.profile.fio}{" "}
                    {ExpertsStore.expertCardData.premium && (
                      <img
                        src={premium}
                        alt={""}
                        style={{
                          width: 24,
                          verticalAlign: "middle",
                          cursor: "pointer",
                        }}
                        onClick={() => PremiumStore.setModalOpen(true)}
                      />
                    )}
                  </p>
                  <span className={styles.skillsCategory}>
                    {ExpertsStore.expertCardData.skillsCategory &&
                      ExpertsStore.expertCardData.skillsCategory.map((item) =>
                        ExpertsStore.expertCardData.skillsCategory.length >
                        1 ? (
                          <span>{item.name} | </span>
                        ) : (
                          <span>{item.name} </span>
                        )
                      )}
                  </span>

                  <div>
                    <span>
                      {ExpertsStore.expertCardData.profile.description}
                    </span>
                  </div>
                </div>
                <div className={styles.fll_block + " mb-16"}>
                  <div className={styles.info_bll_item}>
                    <span>
                      <FieldTimeOutlined />
                      {ExpertsStore.expertCardData.profile?.experience}{" "}
                      {Utils.declOfNum(
                        ExpertsStore.expertCardData.profile?.experience || 0,
                        ["год опыта", "года опыта", "лет опыта"]
                      )}
                    </span>
                  </div>
                  <div className={styles.info_bll_item}>
                    <span>
                      <ThunderboltOutlined />
                      {ExpertsStore.expertCardData?.skills?.length || 0}{" "}
                      {Utils.declOfNum(
                        ExpertsStore.expertCardData?.skills?.length || 0,
                        ["навык", "навыка", "навыков"]
                      )}
                    </span>
                  </div>
                  <div className={styles.info_bll_item}>
                    <p className={styles.card_titl_rait}>
                      {/*<span>
                          {ExpertsStore.expertCardData.profile?.review}{" "}
                        </span>*/}
                      <LikeOutlined />
                      <span className={styles.ratingContent}>
                        {ExpertsStore.expertCardData.profile?.reviewCount || 0}{" "}
                        {Utils.declOfNum(
                          ExpertsStore.expertCardData.profile?.reviewCount || 0,
                          ["отзыв", "отзыва", "отзывов"]
                        )}
                      </span>
                    </p>
                  </div>
                </div>
                <div className={styles.navik + " mb-24"} id={"skills"}>
                  {!!ExpertsStore.expertCardData.skills &&
                    ExpertsStore.expertCardData.skills.length > 0 && (
                      <>
                        {ExpertsStore.expertCardData.skills
                          .slice(0, itemsToShow)
                          .map((item, index) => (
                            <p key={index}>{item.name}</p>
                          ))}
                      </>
                    )}

                  {itemsToShow === 3 ? (
                    <button className={styles.but_link} onClick={showmore}>
                      Показать больше
                    </button>
                  ) : (
                    <button className={styles.but_link} onClick={showless}>
                      Скрыть
                    </button>
                  )}
                </div>

                <ContactBlock profile={ExpertsStore.expertCardData?.profile} />
                <OrderModal
                  expertId={userId}
                  buttonStyle={{
                    background: "rgba(15, 24, 43, 1)",
                    color: "#fff",
                    fontFamily: "Manrope-Medium",
                    cursor: "pointer",
                    borderRadius: 8,
                    display: "block",
                    textAlign: "center",
                    fontSize: 14,
                    padding: "12px 16px",
                    width: "240px",
                    marginTop: "16px",
                    maxWidth: "100%",
                  }}
                  buttonText={"Связаться"}
                />
              </div>

              <div className={styles.left_bl}>
                <div className={styles.flex_tit + " mb-16"}>
                  <h4 className={styles.title_main + " mt-0"}>
                    <img src={star2} alt={""} /> Объявления
                  </h4>
                </div>
                <div className={styles.slider_blcase}>
                  {!ExpertsStore.expertCardData.cases.length && (
                    <p>Пользователь еще не добавил свои объявления</p>
                  )}

                  {!!ExpertsStore.expertCardData.cases.length &&
                    ExpertsStore.expertCardData.cases.map((item, index) => (
                      <div key={index} className={styles.slider_bl_one}>
                        <CaseItem
                          item={item}
                          arr={ExpertsStore.expertCardData.cases}
                        />
                      </div>
                    ))}
                </div>

                <div style={{ marginBottom: 50, marginTop: 40 }}>
                  <Reviews expertId={userId} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default observer(ExpertCard);
