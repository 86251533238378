import React from "react";
import styles from "../ReviewsItem/ReviewsItem.module.scss";
import moment from "moment";
import Utils from "../../Utils";
import basket from "../../assets/images/basket.svg";
import "moment/locale/ru";
import { Button } from "antd";
import noFoto from "../../assets/images/nofoto.png";
import userStore from "../Auth/userStore";
import { observer } from "mobx-react-lite";
import ReviewsStore from "../Reviews/ReviewsStore";
import ExpertsStore from "../Experts/ExpertsStore";
import AnswerAdd from "../Reviews/AnswerAdd";
import { Link } from "react-router-dom";

moment.locale("ru");

const ReviewsItem = ({ item, caseId }) => {
  if (!item) return null;
  return (
    <div className={styles.reviews}>
      <div className={styles.reviews__top}>
        <Link to={`/card/${item?.userIdData?._id}`}>
          {!item?.userIdData?.profileData?.avatar && (
            <div
              style={{
                backgroundImage: `url(${noFoto})`,
              }}
              className={styles.reviews__top_img}
            />
          )}
          {item?.userIdData?.profileData?.avatar && (
            <div
              style={{
                backgroundImage: `url(https://${Utils.domain}/api/public/file/${item?.userIdData?.profileData?.avatar})`,
              }}
              className={styles.reviews__top_img}
            />
          )}
        </Link>
        <div className={styles.reviews__top_text} style={{ marginRight: 20 }}>
          <Link to={`/card/${item?.userIdData?._id}`}>
            <p>{item?.userIdData?.profileData?.fio}</p>
          </Link>
          <span>
            {/*{moment(item.createdAt).format('DD MMM в HH:mm')}*/}
            Оценка: {item?.userIdData?.profileData?.review}
          </span>
        </div>
        {userStore.userData._id === item.userIdData._id && (
          <Button
            type={"text"}
            onClick={() => {
              ReviewsStore.commentDelete(
                { expertId: item.expertId, commentId: item._id, caseId },
                () => {
                  ExpertsStore.expertCard(item.expertId);
                }
              );
            }}
          >
            <img src={basket} alt={""} />
          </Button>
        )}
        {item.expertId === userStore.userData._id && (
          <Button
            size={"small"}
            onClick={() => {
              ReviewsStore.answerModalOpen({
                expertId: item.expertId,
                commentId: item._id,
              });
            }}
          >
            Ответить
          </Button>
        )}
      </div>
      <div className={styles.reviews_content}>
        <p>{item.text}</p>
      </div>
      <div style={{ margin: "10px 0 0 20px", borderLeft: "2px solid #D9D9D9" }}>
        {item.answers &&
          item.answers.map((item2, index) => (
            <div className={styles.reviews} key={index}>
              <div className={styles.reviews__top} style={{ paddingLeft: 10 }}>
                <div
                  style={{
                    backgroundImage: `url(https://${Utils.domain}/api/public/file/${item2?.userIdData?.profileData?.avatar})`,
                  }}
                  className={styles.reviews__top_img}
                />
                <div className={styles.reviews__top_text}>
                  <p>{item2?.userIdData?.profileData?.fio}</p>
                  <span>
                    {moment(item2.createdAt).format("DD MMM в HH:mm")}
                    {/*17 ноября в 12:15*/}
                  </span>
                </div>
                {userStore.userData._id === item2.userIdData._id && (
                  <Button
                    type={"text"}
                    onClick={() => {
                      ReviewsStore.commentDelete(
                        {
                          expertId: item2.expertId,
                          commentId: item2._id,
                          caseId,
                        },
                        () => {
                          ExpertsStore.expertCard(item2.expertId);
                        }
                      );
                    }}
                  >
                    <img src={basket} alt={""} />
                  </Button>
                )}
              </div>
              <div
                className={styles.reviews_content}
                style={{ paddingLeft: 10 }}
              >
                <p>{item2.text}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default observer(ReviewsItem);
