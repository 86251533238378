import {configure, makeAutoObservable} from "mobx";
import {apiQuery, apiQueryError} from "../../Utils";

configure({enforceActions: "never"})

class ReviewsStore {

  modalData = null;
  isModalOpen = false;
  isAnswerModalOpen = false;
  commentItems = [];
  commentItemsLoad = true;

  constructor() {
    makeAutoObservable(this)
  }

  answerModalOpen(v) {
    this.isAnswerModalOpen = v;
  }

  answerModalClose() {
    this.isAnswerModalOpen = false;
  }

  modalOpen(commId = null, form) {
    if (!commId) {
      this.modalData = null;
      this.isModalOpen = true;
      return;
    }
    this.commentGet(commId, form)
  }
  modalClose(){
    this.isModalOpen = false;
  }

  commentGet(commId, form) {
    apiQuery("/private/comment/" + commId, {
      method: 'POST',
      body: {}
    })
      .then((data) => {
        this.modalData = data;
        form.setFieldsValue(data);
      })
      .catch((error) => {
        apiQueryError({error})
      })
  }

  commentList(expertId, caseId){
    this.commentItemsLoad = true;
    // this.commentItems = [];
    apiQuery("/public/comment/list", {
      method: 'POST',
      body: {expertId, caseId}
    })
      .then((data) => {
        this.commentItems = data;
        this.commentItemsLoad = false;
      })
      .catch((error) => {
        apiQueryError({error});
        this.commentItemsLoad = false;
      })
  }

  commentSave(values, cb){
    if (!cb) cb = () => {}
    apiQuery("/private/comment/save", {
      method: 'POST',
      body: values
    })
      .then(() => {
        this.commentList(values.expertId, values.caseId);
        this.isModalOpen = false;
        this.isAnswerModalOpen = false
        cb()
      })
      .catch((error) => {
        apiQueryError({error});
      })
  }

  commentDelete({expertId, commentId, caseId}, cb = () => {}){
    apiQuery("/private/comment/delete", {
      method: 'POST',
      body: {expertId, commentId}
    })
      .then(() => {
        this.commentList(expertId, caseId);
        cb()
      })
      .catch((error) => {
        apiQueryError({error});
      })
  }
}

const clsReviewsStore = new ReviewsStore();

export default clsReviewsStore;