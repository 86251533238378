import React from "react";
import styles from "../Footer/Footer.module.scss";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo_white.svg";
import PremiumStore from "../Premium/PremiumStore";

const Footer = () => {
  const d = useLocation();
  const re = new RegExp("^/cases/view/", "g");

  if (re.test(d.pathname)) return null;

  console.log(d);

  return (
    <div className={styles.footer}>
      <div className="container">
        <div className={styles.flex_footer}>
          <div>
            <Link to="/">
              <img src={logo} alt="logo" width={80} />
            </Link>
          </div>
          <div>
            <p>О проекте</p>
            <span>
              <Link className={styles.left}>О нас</Link>
            </span>
            <span>
              <Link className={styles.left}>Контакты</Link>
            </span>
          </div>
          <div>
            <p>Карта сайта</p>
            <span>
              {" "}
              <Link className={styles.left} to="/ads">
                Объявления
              </Link>{" "}
            </span>
            <span>
              {" "}
              <Link className={styles.left} to="/search">
                Поиск экспертов
              </Link>{" "}
            </span>
            <span>
              <Link className={styles.left} to="/allexperts">
                Топ экспертов
              </Link>
            </span>
          </div>
          {/*<div>
            <p>VIP</p>
            <span>
              <Link
                className={styles.left}
                onClick={() => {
                  PremiumStore.setModalOpen(true);
                }}
              >
                Стать VIP
              </Link>
            </span>
            <span>
              <Link className={styles.left}>Условия оплаты</Link>
            </span>
            <span>
              <Link className={styles.left}>Условия VIP экспертов</Link>
            </span>
          </div>*/}
        </div>
        <p className={styles.text22}>
          Найдите опытных специалистов в один клик. Poisk 2023
        </p>
      </div>
    </div>
  );
};

export default Footer;
