import CompanyBlockItem from "../Company/CompanyBlockItem";
import React, { useEffect } from "react";
import ExpertsStore from "../Experts/ExpertsStore";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { Carousel } from "antd";
import styles from "../../pages/Home.module.scss";
import slar1 from "../../assets/images/slar2.svg";
import slar2 from "../../assets/images/slar1.svg";

import Allcat from "../Allcat";
import CompanyStore from "./CompanyStore";

const setting = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  arrows: true,
  nextArrow: <img src={slar1} />,
  prevArrow: <img src={slar2} />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

const CompanySlider = () => {
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  useEffect(() => {
    CompanyStore.list({ clear: true });
  }, []);

  return (
    <div className={styles.comp_wrp}>
      <div className="container">
        <div className={styles.flex_tit + " mb-16"}>
          <h4 className={styles.title_main}>Компании </h4>
          <Allcat title={"Все компании"} link="/allcompany" />
        </div>
        <div className="margin_minus">
          <Carousel {...setting}>
            {CompanyStore.items?.map((item, index) => (
              <div key={index}>
                <CompanyBlockItem item={item} />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default observer(CompanySlider);
