import React, { useEffect, useRef, useState } from "react";
import styles from "../SliderHistory/SliderHistory.module.scss";
import { Link } from "react-router-dom";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Modal, Carousel } from "antd";
import str3 from "../../assets/images/2-44_.jpg";
import str2 from "../../assets/images/2-43_.jpg";
import str1 from "../../assets/images/2-42_.jpg";
import str4 from "../../assets/images/2-41_.jpg";
import str1slide from "../../assets/images/1-33.jpg";
import str3slide from "../../assets/images/1-34.jpg";
import str2slide from "../../assets/images/1-35.jpg";
import str4slide from "../../assets/images/1-36.jpg";
import { AutoSizer } from "react-virtualized";
import ImagePreview from "../ImagePreview";
import AppStore from "../App/AppStore";

const style = { top: "0", paddingBottom: "0", margin: "0 auto" };
let height = document.documentElement.clientHeight;
let width1 = document.documentElement.clientWidth;
let width = (height * 1242) / 2208;
if (width > width1) {
  width = "100%";
}

const contentStyle = {
  height,
  width,
  background: "transparent",
};

function StoriesCarousel({ show = false, n }) {
  let slider = useRef(null);

  useEffect(() => {
    if (show) slider.current.goTo(n);
  }, [show, n]);

  if (!show) return <></>;

  return (
    <div id="div_id" style={contentStyle}>
      <Carousel
        ref={slider}
        className="main_carousel"
        arrows={false}
        dotPosition="bottom"
        effect="fade"
        nextArrow={<RightOutlined />}
        prevArrow={<LeftOutlined />}
      >
        <div className="carousel_item">
          <img src={str1slide} alt={""} />
          <a href="/" className="button button_site">
            ПОДРОБНЕЕ
          </a>
        </div>

        <div className="carousel_item">
          <img src={str2slide} alt={""} />
          <a href="/" className="button button_site">
            ПОДРОБНЕЕ
          </a>
        </div>

        <div className="carousel_item">
          <img src={str3slide} alt={""} />
          <a href="/#" className="button button_site">
            ПОДРОБНЕЕ
          </a>
        </div>

        <div className="carousel_item">
          <img src={str4slide} alt={""} />
          <a href="/#" className="button button_site">
            ПОДРОБНЕЕ
          </a>
        </div>
        <div className="carousel_item">
          <img src={str1slide} alt={""} />
          <a href="/#" className="button button_site">
            ПОДРОБНЕЕ
          </a>
        </div>
      </Carousel>
    </div>
  );
}

const SliderHistoryItem = ({ src }) => {
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();

  return (
    <ImagePreview
      src={src}
      height="auto"
      width="100%"
      style={{
        backgroundColor: "transparent",
        border: 0,
        borderRadius: 16,
      }}
    />
  );
};

const SliderHistory = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [slideNumber, setSlideNumber] = useState(2);

  const showModal = (n) => {
    setIsModalVisible(true);
    setSlideNumber(n);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  const settings = {
    dots: true,
    speed: 1000,
    slidesToShow: 2,
    swipeToSlide: true,
    centerMode: false,
    infinite: true,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <RightOutlined />,
    prevArrow: <LeftOutlined />,
    autoplay: true,
    autoplaySpeed: 5000,
    waitForAnimate: true,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  return (
    <>
      <div className={styles.containerwpr}>
        <Carousel {...settings} afterChange={onChange}>
          <div className={styles.item_slide}>
            <Link
              onClick={() => {
                if (AppStore.width < 700) showModal(0);
              }}
              style={{ cursor: AppStore.width < 700 ? "pointer" : "default" }}
            >
              <SliderHistoryItem src={str1} />
            </Link>
          </div>
          <div className={styles.item_slide}>
            <Link
              onClick={() => {
                if (AppStore.width < 700) showModal(1);
              }}
              style={{ cursor: AppStore.width < 700 ? "pointer" : "default" }}
            >
              <SliderHistoryItem src={str2} />
            </Link>
          </div>
          <div className={styles.item_slide}>
            <Link
              onClick={() => {
                if (AppStore.width < 700) showModal(2);
              }}
              style={{ cursor: AppStore.width < 700 ? "pointer" : "default" }}
            >
              <SliderHistoryItem src={str3} />
            </Link>
          </div>
          <div className={styles.item_slide}>
            <Link
              onClick={() => {
                if (AppStore.width < 700) showModal(3);
              }}
              style={{ cursor: AppStore.width < 700 ? "pointer" : "default" }}
            >
              <SliderHistoryItem src={str4} />
            </Link>
          </div>
          {/*<div className={styles.item_slide}>*/}
          {/*  <Link*/}
          {/*    onClick={() => {*/}
          {/*      showModal(4);*/}
          {/*    }}*/}
          {/*    // style={{*/}
          {/*    //   backgroundImage: `url(${str1})`,*/}
          {/*    // }}*/}
          {/*  >*/}
          {/*    <SliderHistoryItem src={str1} />*/}
          {/*  </Link>*/}
          {/*</div>*/}
        </Carousel>
      </div>

      <Modal
        width={contentStyle.width}
        bodyStyle={{ padding: 0 }}
        open={isModalVisible}
        onOk={handleOk}
        className="ant_modal1"
        onCancel={handleCancel}
        footer={""}
        style={style}
      >
        <StoriesCarousel show={isModalVisible} n={slideNumber} />
      </Modal>
    </>
  );
};

export default SliderHistory;
