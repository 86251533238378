import {configure, makeAutoObservable} from "mobx";
import {apiQuery, apiQueryError} from "../../Utils";

configure({enforceActions: "never"})

class ViewCounterStore {

  count = null;

  constructor() {
    makeAutoObservable(this)
  }

  get(page, id) {
    this.count = null;
    apiQuery("/public/viewCounter", {
      method: 'POST',
      body: {page, id}
    })
      .then((data) => {
        this.count = data;
      })
      .catch((error) => {
        this.count = 0;
        apiQueryError({error})
      })
  }
}

const clsViewCounterStore = new ViewCounterStore();

export default clsViewCounterStore;