import {Input, Modal, Form, Rate} from "antd";
import ReviewsStore from "./ReviewsStore";
import {observer} from "mobx-react-lite";
import {useEffect} from "react";
import ExpertsStore from "../Experts/ExpertsStore";

const {TextArea} = Input;

const ReviewsAddEdit = ({expertId, caseId = null}) => {

  const [form] = Form.useForm();
  const isModalOpen = ReviewsStore.isModalOpen;

  const onFinish = (values) => {
    if (!expertId) return;
    values.expertId = expertId;
    values.caseId = caseId;
    ReviewsStore.commentSave(values, () => {
      ExpertsStore.expertCard(expertId);
    })
  }

  useEffect(() => {
    form.resetFields()
  }, [form, isModalOpen])

  return <Modal title="Добавить отзыв"
                open={ReviewsStore.isModalOpen}
                onCancel={() => {
                  ReviewsStore.modalClose()
                }}
                onOk={() => {
                  form.submit()
                }}
                cancelText={'Отмена'}
                okText={'Отправить'}
  >
    <Form
      name="basic"
      form={form}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        name="review"
        rules={[
          {
            required: true,
            message: 'Выберите оценку!',
          },
        ]}
        style={{textAlign: "center"}}
      >
        <Rate count={5}/>
      </Form.Item>

      <Form.Item
        name="text"
        rules={[
          {
            required: true,
            message: 'Введите текст отзыва!',
          },
        ]}
      >
        <TextArea style={{width: '100%'}}/>
      </Form.Item>

    </Form>
  </Modal>
}

export default observer(ReviewsAddEdit);