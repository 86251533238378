import React from "react";
import styles from "../ExpertsBlockItemBig/ExpertsBlockItemBig.module.scss";
import Rating from "../Rating/index";
import { Link } from "react-router-dom";
import note from "../../assets/images/note.svg";
import clock from "../../assets/images/clock.svg";
import skill from "../../assets/images/skill.svg";
import Utils from "../../Utils";
import arropen from "../../assets/images/arropen.svg";

import ExpertsStore from "../Experts/ExpertsStore";

const ExpertsBlockItemBig = ({ item }) => {
  return (
    <Link className={styles.expert_item_wrp} to={"/card/" + item?._id}>
      <div
        style={{
          backgroundImage: `url(${Utils.urlAvatar(item?.profileData?.avatar)})`,
        }}
        className={styles.expert_avatar}
      >
        <span className={styles.nubmer_top}>1</span>
        <div className={styles.timer}>
          <Rating review={item?.profileData?.review} />
        </div>
      </div>

      <div className={styles.panel_text}>
        <div>
          <p>{item?.profileData?.fio}</p>

          {!!item.profileData.category &&
            item.profileData.category.length > 0 &&
            item.profileData.category.slice(0, 1).map((item, index) => (
              <span>
                <i key={index}> {item.name}</i>{" "}
              </span>
            ))}
        </div>
        {/*<div className={styles.navik}>
          {!!item.profileData.skills &&
            item.profileData.skills.length > 0 &&
            item.profileData.skills
              .slice(0, 4)
              .map((item, index) => <p key={index}>{item.name}</p>)}
        </div>*/}

        <div className={styles.obo_mne}>
          {/*<span>{item?.profileData?.description}</span>*/}
          <div className={styles.info_bll}>
            <div className={styles.info_bll_item}>
              <img src={note} alt={""} />
              <span>{item.profileData?.reviewCount || 0}</span>
            </div>
            <div className={styles.info_bll_item}>
              <img src={clock} alt={""} />
              <span>{item.profileData.experience}</span>
            </div>
            <div className={styles.info_bll_item}>
              <img src={skill} alt={""} />
              <span>{item.profileData?.skills?.length || 0}</span>
            </div>
          </div>
          <button>
            <img src={arropen} />
          </button>
        </div>
      </div>
    </Link>
  );
};

export default ExpertsBlockItemBig;
