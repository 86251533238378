import ReviewsStore from "./ReviewsStore";
import { Button, Divider, Drawer, Form, Input, Rate, Spin } from "antd";
import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import ReviewsItem from "../ReviewsItem";
import styles from "../ExpertCard/ExpertCard.module.scss";
import rew from "../../assets/images/rew.svg";
import { PlusOutlined } from "@ant-design/icons";
import ContactBlock from "../ContactBlock";
import ExpertsStore from "../Experts/ExpertsStore";
import ReviewsAddEdit from "./ReviewsAddEdit";
import AnswerAdd from "./AnswerAdd";

const Reviews = ({ expertId, caseId = null }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    if (!expertId) return;
    values.expertId = expertId;
    values.caseId = caseId;
    ReviewsStore.commentSave(values, () => {
      form.resetFields();
      ExpertsStore.expertCard(expertId);
    });
  };

  useEffect(() => {
    ReviewsStore.commentList(expertId, caseId);
  }, [expertId, caseId]);

  return (
    <div className={styles.reww}>
      <div className={styles.otzv_bl}>
        <div className={styles.flex_tit + " mb-16"}>
          <h4 className={styles.title_main}>
            {/*<img src={rew} alt={''}/> */}
            Отзывы{" "}
            <Button
              className={styles.add_but_rew}
              onClick={() => {
                ReviewsStore.modalOpen();
              }}
            >
              <PlusOutlined />
            </Button>
            {ReviewsStore.commentItemsLoad && <Spin />}
          </h4>
        </div>

        <div>
          <Form
            name="basic"
            layout={"vertical"}
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            requiredMark={false}
          >
            <Form.Item
              label={"Поставьте оценку и напишите отзыв"}
              name="review"
              rules={[
                {
                  required: true,
                  message: "Выберите оценку!",
                },
              ]}
            >
              <Rate count={5} />
            </Form.Item>

            <Form.Item
              name="text"
              rules={[
                {
                  required: true,
                  message: "Введите текст отзыва!",
                },
              ]}
            >
              <Input.TextArea style={{ width: "100%" }} />
            </Form.Item>

            <Button htmlType="submit">Отправить</Button>
          </Form>
        </div>

        <div className={styles.navik1}>
          {ReviewsStore.commentItems.map((item, index) => (
            <span key={index}>
              <ReviewsItem item={item} caseId={caseId} />
            </span>
          ))}
        </div>
      </div>
      <ReviewsAddEdit expertId={expertId} caseId={caseId} />
      <AnswerAdd />
    </div>
  );
};

export default observer(Reviews);
