import styles from "./CaseCard.module.scss";
import React, {useEffect, useState} from "react";
import FavoritesStore from "../Favorites/FavoritesStore";
import {Spin} from "antd";
import {observer} from "mobx-react-lite";

const CaseFavoriteButton = ({caseId}) => {

  const [isActive, setActive] = useState();
  const handleToggle = (event) => {
    event.stopPropagation();
    FavoritesStore.buttonChange(caseId, (e) => {
      setActive(e);
    })
  };

  useEffect(() => {
    if (!caseId) return;
    FavoritesStore.buttonGet(caseId, (e) => {
      setActive(e);
    })
  }, [caseId])

  if (!caseId) return null;

  if (FavoritesStore.buttonToggleLoad) return <button><Spin/></button>

  return <button onClick={handleToggle} className={styles.btnFav}>
    <svg
      className={isActive ? styles.act_fav : null}
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.75 2C0.75 1.30964 1.30964 0.75 2 0.75H18C18.6904 0.75 19.25 1.30964 19.25 2V22.253L11.2053 14.6106C10.5299 13.9689 9.47013 13.9689 8.79469 14.6106L0.75 22.253V2ZM0.327812 22.6541C0.328003 22.6539 0.328192 22.6537 0.328383 22.6536L0.327812 22.6541Z"
        stroke="#716EF5"
        strokeWidth="1.5"
      />
    </svg>
  </button>
}

export default observer(CaseFavoriteButton);