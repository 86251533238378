import React from "react";
import styles from "../BestSearch/BestSearch.module.scss";
import { Row, Col } from "antd";
import s1 from "../../assets/images/s1.svg";
import s2 from "../../assets/images/s2.svg";
import s3 from "../../assets/images/s3.svg";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";
import s4 from "../../assets/images/s4.svg";

const BestSearch = () => {
  return (
    <div className={styles.best_wrp}>
      <div className="container">
        <div className={styles.best_wrp__flex}>
          <p className={styles.best_wrp__title}>
            Поиск специалистов — это легко!
            <span>Впоиске вы можете:</span>
          </p>
        </div>

        <Row>
          <Col className="mb-24" xs={24} sm={12} md={12} lg={6} xl={6}>
            <ScrollAnimation
              animateIn="fadeIn"
              animateOut="fadeOut"
              delay={100}
            >
              <div className={styles.best_item}>
                <img src={s1} />
                <b>Быстро опубликовать вакансию</b>
                <p>
                  Сотни удобных шаблонов помогут правильно составить вакансию и
                  сразу же её опубликовать!
                </p>
              </div>
            </ScrollAnimation>
          </Col>

          <Col className="mb-24" xs={24} sm={12} md={12} lg={6} xl={6}>
            <ScrollAnimation
              animateIn="fadeIn"
              animateOut="fadeOut"
              delay={300}
            >
              <div className={styles.best_item}>
                <img alt="" src={s2} />

                <b>Найти лучшего специалиста</b>
                <p>
                  Среди тысячи высококвалифицированных соискателей вы наверняка
                  найдёте подходящих, а система рейтингов поможет принять
                  решение!
                </p>
              </div>
            </ScrollAnimation>
          </Col>

          <Col className="mb-24" xs={24} sm={12} md={12} lg={6} xl={6}>
            <ScrollAnimation
              animateIn="fadeIn"
              animateOut="fadeOut"
              delay={500}
            >
              <div className={styles.best_item}>
                <img alt="" src={s3} />
                <b>Безопасно оплатить услуги</b>
                <p>
                  Оплата на сайте происходит быстро, легко и безопасно, ведь мы
                  гарантируем прозрачность сделки!
                </p>
              </div>
            </ScrollAnimation>
          </Col>

          <Col className="mb-24" xs={24} sm={12} md={12} lg={6} xl={6}>
            <ScrollAnimation
              animateIn="fadeIn"
              animateOut="fadeOut"
              delay={700}
            >
              <div className={styles.best_item}>
                <img alt="" src={s4} />
                <b>Получить поддержку</b>
                <p>
                  Если у вас возникнут вопросы, обращайтесь в службу поддержки.
                  Мы всегда на связи!
                </p>
              </div>
            </ScrollAnimation>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BestSearch;
