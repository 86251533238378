import {configure, makeAutoObservable} from "mobx";
import {apiQuery, apiQueryError} from "../../Utils";

configure({enforceActions: "never"})

class OrderStore {

  addLoad = false;

  constructor() {
    makeAutoObservable(this)
  }

  add(values, cb){
    if (!cb) cb = () => {};
    this.addLoad = true;
    apiQuery("/public/order/add", {
      method: 'POST',
      body: values
    })
      .then(() => {
        this.addLoad = false;
        cb(true)
      })
      .catch((error) => {
        apiQueryError({error})
        this.addLoad = false;
        cb(false)
      })
  }

}

const clsOrderStore = new OrderStore()
export default clsOrderStore