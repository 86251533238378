import React from "react";
import styles from "../CasesBlockItem/CasesBlockItem.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import Utils from "../../Utils";
import { observer } from "mobx-react-lite";
import TextTruncate from "react-text-truncate";
import AppStore from "../App/AppStore";
import {Col, Row} from "antd";

const CasesBlockItem = ({ item }) => {
  let navigate = useNavigate();

  return (
    <Link className={styles.cases_bl} to={`/cases/view/${item._id}`}>
      <div className={styles.gray_bl}>
        {/* <div className={styles.cases_block} style={{backgroundImage: `url(https://${Utils.domain}/api/public/file/${item.image})`}}>*/}

        <p className={styles.text}>
          <TextTruncate
            line={3}
            element="span"
            truncateText="…"
            text={item.name}
          />
        </p>
        <span className={styles.text_span}>
          <TextTruncate
            line={3}
            element="span"
            truncateText="…"
            text={item.description}
          />
        </span>
      </div>
      <Row>
        <Col flex={'auto'}/>
        <Col flex={'32px'}>
          <div
            className={styles.abs_block}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/cabinet/cases/edit/${item._id}`, { replace: true });
            }}
            style={{ visibility: AppStore.width < 900 ? "visible" : "inherit" }}
          >
            <p>
              <EditOutlined />
            </p>
          </div>
        </Col>
      </Row>
    </Link>
  );
};

export default observer(CasesBlockItem);
