import React from "react";
import styles from "./CaseItem.module.scss";
import { Link } from "react-router-dom";
import Utils from "../../Utils";

const CaseItem = ({item, index, arr}) => {
   return (
    <Link className={styles.keysit} to={`/cases/view/${item._id}`}  style={{ zIndex: `${arr.length-index}` }} >
      <div style={{width:'100%'}}>
        <div style={{backgroundImage: `url(https://${Utils.domain}/api/public/file/${item.image})` }}
             className={styles.keysit_img}/>
        <p>{item?.name}</p>
        <span>{item?.headline}</span>
      </div>
    </Link>

  );
};

export default CaseItem;
