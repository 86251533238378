import ExpertCard from "./index";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {apiQuery, apiQueryError} from "../../Utils";

const ExpertCardByUserName = () => {
  const {userName} = useParams();
  const [userId, setUserId] = useState();

  useEffect(() => {
    if (!userName) return;
    apiQuery("/public/expertByUserName/" + userName)
      .then((data) => {
        setUserId(data)
      })
      .catch((error) => {
        apiQueryError({error})
      })
  }, [userName])

  if (!userId) return null;
  return <ExpertCard userId={userId}/>
}

export default ExpertCardByUserName;