import { Outlet } from "react-router-dom";
import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import left_icon from "../assets/images/arrow_left.svg";
import PremiumModal from "../components/Premium/PremiumModal";

const HomeLayout = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  });
  if (loading) {
    return (
      <div style={{ width: "100%", marginTop: "100px", textAlign: "center" }}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <Header button1={<img src={left_icon} alt={""} />} />
      <div style={{ flex: "1 auto" }}>
        <Outlet />
      </div>
      <PremiumModal />
      <Footer />
    </>
  );
};

export default HomeLayout;
