import styles from "../Auth/Login.module.scss";
import {Button, Drawer, Empty, Form, Input, Modal, Slider, Spin} from "antd";
import SearchStore from "./SearchStore";
import {Link} from "react-router-dom";
import bodyScrollLock from "../../Utils/bodyScrollLock";
import flter from "../../assets/images/filter1.svg";
import InfiniteScroll from "react-infinite-scroller";
import ExpertsItemGorizont2 from "../ExpertsItemGorizont2";
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import starRating from "../../assets/images/starRating.svg";
import SearchModalSelect from "./SearchModalSelect";
import userStore from "../Auth/userStore";

const SearchDrawer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [completedObjects, setCompletedObjects] = useState({});
  const open = SearchStore.drawerOpen;
  const expertListItems = SearchStore.expertListItems;

  const onClose = () => {
    userStore.setProfileMenuOpen(false);
  };

  useEffect(() => {
    SearchStore.categoryList();
    SearchStore.skillsList();
    SearchStore.countryList();
    SearchStore.citiesList();
    SearchStore.list({clear: true});
  }, []);

  useEffect(() => {
    SearchStore.list({clear: true});
  }, [open]);

  return <>
    <Drawer
      title={null}
      width={520}
      closable={false}
      onClose={onClose}
      open={open}
    >
      <>
        <div className={styles.seach_bl}>
          <div className={styles.seach_relat}>
            <Input
              placeholder="Введите имя"
              value={SearchStore.filter.searchValue}
              onChange={(e) => {
                SearchStore.setSearchValue(e.target.value);
                setCompletedObjects((v) => {
                  let d = {...v};
                  if (e.target.value !== "") d["searchValue"] = true;
                  else delete d["searchValue"];
                  return d;
                });
              }}
              onPressEnter={() => {
                SearchStore.list({clear: true});
              }}
            />
            <Link
              onClick={() => {
                setIsModalOpen(true);
                bodyScrollLock.enable();
              }}
            >
              <img src={flter} alt={""}/>
            </Link>
          </div>

          <p
            onClick={() => {
              SearchStore.setDrawerOpen(false);
            }}
          >
            Отменить
          </p>
        </div>
        <div className={styles.flex_bl}>
          {!expertListItems.length && SearchStore.fetchCheck && (
            <div style={{width: "100%", textAlign: "center"}}>
              <Spin/>
            </div>
          )}
          {!expertListItems.length && !SearchStore.fetchCheck && (
            <div style={{width: "100%", textAlign: "center"}}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={"По вашим критериям ничего не найдено"}
              />
            </div>
          )}
          {!!expertListItems.length && (
            <InfiniteScroll
              style={{width: "100%", minHeight: 50}}
              pageStart={0}
              loadMore={() => SearchStore.list({})}
              hasMore={SearchStore.expertListLoadCheck}
              loader={
                <div className="loader" key={0}>
                  {/*<Spin />*/}
                </div>
              }
            >
              {expertListItems.map((item, index) => (
                <div key={index} className={styles.width_bl}>
                  {/*<ExpertsBlockItem2 item={item} />*/}
                  {/*<ExpertsBlockItemSmall item={item}/>*/}
                  <ExpertsItemGorizont2 item={item} index={index}/>
                </div>
              ))}
            </InfiniteScroll>
          )}
        </div>
      </>
    </Drawer>

    <Modal
      title={
        <div className={styles.flex_form}>
          <p>Фильтр</p>
          <Button
            type={"link"}
            onClick={() => {
              SearchStore.clearFilter();
              setCompletedObjects({});
            }}
          >
            Сбросить фильтр
          </Button>
        </div>
      }
      centered
      open={isModalOpen}
      onOk={() => {
        setIsModalOpen(false);
        bodyScrollLock.disable();
      }}
      onCancel={() => {
        setIsModalOpen(false);
        bodyScrollLock.disable();
      }}
      width={"100%"}
      footer={null}
    >
      <div className={styles.select_filter}>
        <Form name="basic" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
          <div className={"mt-8 mb-8"}>
            <p>
              По рейтингу{" "}
              <img
                src={starRating}
                alt={""}
                style={{ width: 14, verticalAlign: "sub" }}
              />
            </p>
            <Slider
              marks={{ 1: 0, 5: 5 }}
              value={SearchStore.filter.rating}
              min={1}
              max={5}
              range
              onChange={(v) => {
                SearchStore.setFilterRating(v);
              }}
            />
          </div>

          <Form.Item>
            <SearchModalSelect
              mode="multiple"
              modalTitle={"Категории"}
              modalAllTitle={"Все категории"}
              modalPopularTitle={"Популярные категории"}
              modalPopularOptions={SearchStore.optionsCategory.filter(
                (item) =>
                  [
                    "63da66486e62e680c299af0e",
                    "63da67236e62e680c299af10",
                    "63da672d6e62e680c299af11",
                  ].includes(item.value)
              )}
              placeholder="Категория"
              onChange={(value) => {
                SearchStore.setFilterCategory(value);
              }}
              value={SearchStore.filter.category}
              options={SearchStore.optionsCategory}
              onSelectAdd={(v) => SearchStore.addFilterCategoryItem(v)}
              onSelectDel={(v) => SearchStore.delFilterCategoryItem(v)}
            />
          </Form.Item>
          <Form.Item>
            <SearchModalSelect
              mode="multiple"
              modalTitle={"Навыки"}
              modalAllTitle={"Все навыки"}
              modalPopularTitle={"Популярные навыки"}
              modalPopularOptions={SearchStore.optionsSkills.filter((item) =>
                [
                  "63da64c56e62e680c299af0a",
                  "63da64b36e62e680c299af09",
                  "63dc9b1a6e62e680c299af22",
                ].includes(item.value)
              )}
              placeholder="Навыки"
              onChange={(value) => {
                SearchStore.setFilterSkills(value);
              }}
              value={SearchStore.filter.skills}
              options={SearchStore.optionsSkills}
              onSelectAdd={(v) => SearchStore.addFilterSkillsItem(v)}
              onSelectDel={(v) => SearchStore.delFilterSkillsItem(v)}
            />
          </Form.Item>
          <Form.Item>
            <SearchModalSelect
              modalTitle={"Страна"}
              modalAllTitle={"Все страны"}
              placeholder="Страна"
              mode="multiple"
              onChange={(value) => {
                SearchStore.setFilterCountry(value);
              }}
              value={SearchStore.filter.country}
              options={SearchStore.optionsCountry}
              showArrow={true}
              optionFilterProp="label"
              onSelectAdd={() => {}}
              onSelectDel={(v) => SearchStore.delFilterCountryItem(v)}
            />{" "}
          </Form.Item>
          <Form.Item>
            <SearchModalSelect
              modalTitle={"Город"}
              modalAllTitle={"Все города"}
              modalPopularTitle={"Популярные города"}
              modalPopularOptions={SearchStore.optionsCities.filter((item) =>
                [
                  "642fecc3cb9b4d831fd86a17",
                  "642fecc3cb9b4d831fd86a21",
                ].includes(item.value)
              )}
              placeholder="Город"
              mode="multiple"
              onChange={(value) => {
                SearchStore.setFilterCities(value);
              }}
              value={SearchStore.filter.city}
              options={SearchStore.optionsCities}
              showArrow={true}
              optionFilterProp="label"
              onSelectAdd={(v) => SearchStore.addFilterCitiesItem(v)}
              onSelectDel={(v) => SearchStore.delFilterCitiesItem(v)}
            />{" "}
          </Form.Item>

          <Button
            onClick={() => {
              SearchStore.list({ clear: true });
              setIsModalOpen(false);
              bodyScrollLock.disable();
            }}
            type={"primary"}
            block
          >
            Применить фильтр
          </Button>
        </Form>
      </div>
    </Modal>
  </>
}

export default observer(SearchDrawer);