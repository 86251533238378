import { Outlet, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { Spin } from "antd";
import { observer } from "mobx-react-lite";
import userStore from "../components/Auth/userStore";
import Header from "../components/Header";
import Footer from "../components/Footer";
import left_icon from "../assets/images/arrow_left.svg";

const AdminLayout = () => {
  let navigate = useNavigate();

  useEffect(() => {
    userStore.authCheck().catch();
  }, []);

  if (userStore.auth === null) {
    return <Spin />;
  }

  if (!userStore.auth) {
    navigate("/login", { replace: true });
    return;
  }

  if (!userStore?.userData?.emailVerified) {
    navigate('/verify_email', {replace: true})
    return null;
  }

  if (!userStore?.userData?.role?.admin) {
    navigate("/", { replace: true });
    return null;
  }

  return (
    <>
      <Header button1={<img src={left_icon} alt={""} />} />
      <div style={{ flex: "1 auto" }}>
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default observer(AdminLayout);