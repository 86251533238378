import React, { useCallback, useEffect, useState } from "react";
import {
  LeftOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import styles from "../PersonalUser/PersonalUser.module.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import ImgCrop from "antd-img-crop";

import {
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Spin,
} from "antd";
import { message, Upload } from "antd";
import plus from "../../assets/images/plus.svg";
import Utils, { apiQuery, apiQueryError } from "../../Utils";
import ImagePreview from "../ImagePreview";
import SearchModalSelect from "../Search/SearchModalSelect";
import SearchStore from "../Search/SearchStore";
import ruRU from "antd/locale/ru_RU";

const { TextArea } = Input;

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("Вы можете загрузить файлы только в формате JPG или PNG!");
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error("Image must smaller than 10MB!");
  }
  return isJpgOrPng && isLt2M;
};

const UploadCustom = ({ value, onChange }) => {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  const triggerChange = (changedValue) => {
    onChange?.(changedValue);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Загрузить
      </div>
    </div>
  );

  const articleHandleChange = ({ file, fileList }) => {
    setFileList(fileList);
    if (file.status === "uploading") {
      setLoading(true);
      return;
    }

    if (file.status === "done") {
      message.success(`Файл успешно загружен`);
      setLoading(false);
      console.log({ file, fileList });
      if (file.response) {
        setImageUrl(
          `https://${Utils.domain}/api/private/file/${file.response}`
        );
        triggerChange(file.response);
      }
      return;
    }

    if (file.status === "error") {
      console.error(file);
      if (file?.response?.msg) message.error(file.response.msg);
      else message.error("Не удалось загрузить файл");
    }
  };

  useEffect(() => {
    if (value && value !== "")
      setImageUrl(`https://${Utils.domain}/api/private/file/${value}`);
  }, [value]);

  return (
    <ImgCrop modalTitle="Редактирование" rotationSlider quality={1}>
      <Upload
        withCredentials={true}
        listType="picture-card"
        className="avatar-uploader keys_upload"
        showUploadList={false}
        action={`https://${Utils.domain}/api/private/upload`}
        beforeUpload={beforeUpload}
        onChange={articleHandleChange}
        fileList={fileList}
      >
        {imageUrl ? (
          <ImagePreview src={imageUrl} style={{ border: 0 }} />
        ) : (
          uploadButton
        )}
      </Upload>
    </ImgCrop>
  );
};

const CaseAddEdit = () => {
  let navigate = useNavigate();
  const { caseId } = useParams();
  const [form] = Form.useForm();
  const [load, setLoad] = useState(true);
  const [loadSave, setLoadSave] = useState(false);
  const [loadDel, setLoadDel] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);

  const onFinish = (values) => {
    console.log("Success:", values);
    values.caseId = caseId;
    setLoadSave(true);
    apiQuery("/private/cases/save", {
      method: "POST",
      body: values,
    })
      .then((data) => {
        setLoadSave(false);
        message.success("Данные успешно сохранены");
        //navigate('/cabinet/cases/edit/'+data._id, {replace: true});
        navigate("/cabinet/mycases/");
      })
      .catch((error) => {
        apiQueryError({ error });
        setLoadSave(false);
      });
  };

  const casesGet = useCallback(() => {
    setLoad(true);
    apiQuery("/private/cases", {
      method: "POST",
      body: {
        caseId,
      },
    })
      .then((data) => {
        setLoad(false);
        if (data?.categoryOptions) setCategoryOptions(data.categoryOptions);
        if (data?.caseData) form.setFieldsValue(data.caseData);
      })
      .catch((error) => {
        apiQueryError({ error });
        setLoad(false);
      });
  }, [form, caseId]);

  const caseDel = () => {
    setLoadDel(true);
    apiQuery("/private/cases/" + caseId, {
      method: "DELETE",
    })
      .then((data) => {
        setLoadDel(false);
        message.success("Продукт успешно удален");
        navigate("/cabinet/mycases", { replace: true });
      })
      .catch((error) => {
        apiQueryError({ error });
        setLoadDel(false);
      });
  };

  useEffect(() => {
    casesGet();
  }, [caseId, casesGet]);

  if (load) return <Spin />;

  return (
    <div className={styles.page_form}>
      <div className="container">
        <p className={styles.title_page}>Редактирование</p>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item name="image">
            <UploadCustom />
          </Form.Item>
          <Form.Item
            label="Наименование объявления"
            name="name"
            maxLength="5"
            className={styles.formItem}
            rules={[
              { required: true, message: "Введите наименование объявления" },
              { max: 100 },
            ]}
          >
            <Input placeholder="Продающий сайт" />
          </Form.Item>

          {/* <Form.Item
            label="Подзаголовок"
            name="headline"
            className={styles.formItem}
            rules={[{ required: true, message: "Введите подзаголовок" }]}
          >
            <Input placeholder="Увеличте свои онлайн продажи" />
          </Form.Item>*/}
          <Form.Item
            label="Описание объявления"
            name="description"
            className={styles.formItem}
            required={false}
            //rules={[{ required: false, message: "Введите описание продукта" }]}
          >
            <TextArea placeholder="Расскажи о себе" rows={4} />
          </Form.Item>

          <Form.Item
            label="Категории"
            name="category"
            className={styles.formItem}
            rules={[{ required: true, message: "Выберите категорию" }]}
          >
            <SearchModalSelect
              // mode="multiple"
              modalTitle={"Категории"}
              modalAllTitle={"Все категории"}
              placeholder="Категория"
              options={categoryOptions}
            />
          </Form.Item>

          {/*  <Form.Item label="Статьи">
            <Form.List name="articles">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div
                      key={key}
                      style={{
                        border: "1px solid #ddd",
                        borderRadius: 5,
                        padding: 5,
                        marginBottom: 10,
                      }}
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "title"]}
                        rules={[
                          {
                            required: true,
                            message: "Введите заголовок статьи",
                          },
                        ]}
                      >
                        <Input placeholder="Заголовок" maxLength={200}/>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "description"]}
                        rules={[
                          {
                            required: true,
                            message: "Введите описание статьи",
                          },
                        ]}
                      >
                        <TextArea placeholder="Описание" rows={4} maxLength={1000}/>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "_id"]}
                        hidden={true}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "image"]}
                        rules={[
                          { required: true, message: "Вставьте изображение" },
                        ]}
                      >
                        <UploadCustom />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </div>
                  ))}
                  {fields.length < 10 && <Form.Item>
                    <Button className={styles.but_add} onClick={() => add()}>
                      <span>
                        <img src={plus} alt={""} />
                      </span>
                      <p>Добавить</p>
                    </Button>
                  </Form.Item>}
                </>
              )}
            </Form.List>
          </Form.Item>*/}

          <Form.Item
            label="Цена (₸)"
            className={styles.formItem}
            name="amount"
            rules={[{ required: true, message: "Укажите цену" }]}
          >
            <InputNumber
              className={styles.inputNumber}
              style={{
                fontSize: 16,
                width: "100%",
              }}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              className="poiskButton"
              htmlType="submit"
              loading={loadSave}
            >
              Сохранить изменения
            </Button>
          </Form.Item>
          {caseId && (
            <Form.Item style={{ textAlign: "center" }}>
              <Popconfirm
                title="Удалить продукт?"
                onConfirm={caseDel}
                okText="Да"
                cancelText="Нет"
              >
                <Button type="link" danger loading={loadDel}>
                  Удалить
                </Button>
              </Popconfirm>
            </Form.Item>
          )}
        </Form>
      </div>
    </div>
  );
};

export default CaseAddEdit;
