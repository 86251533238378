import { message } from "antd";
import userStore from "./components/Auth/userStore";
import noFoto from "./assets/images/nofoto.png";

const domain_ = "vpoiske.kz";

/**
 * Мобильный или нет
 * @type {boolean}
 */
export const mobileCheck =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

/**
 * Обработка
 * @param data
 * @param company
 * @returns {{getUrl: string, getUrlData: string, getUrlDownload: string, getUrlFilename: string}|{getUrl: *, getUrlData: *, getUrlDownload: *, getUrlFilename: *}}
 */
export function fileAdapted(data, company) {
  if (data.fileUrl)
    return {
      getUrl: data.fileUrl,
      getUrlData: data.fileUrl,
      getUrlDownload: data.fileUrl,
      getUrlFilename: data.fileUrl,
    };

  if (data.serverDomain === undefined || data.id === undefined)
    return {
      getUrl: "",
      getUrlData: "",
      getUrlDownload: "",
      getUrlFilename: "",
    };

  if (data.public === false)
    return {
      getUrl:
        data.serverDomain +
        "/file/" +
        company.serverStaticToken +
        "/" +
        data.id,
      getUrlData:
        data.serverDomain +
        "/file/" +
        company.serverStaticToken +
        "/" +
        data.id +
        "/data",
      getUrlDownload:
        data.serverDomain +
        "/file/" +
        company.serverStaticToken +
        "/" +
        data.id +
        "/download",
      getUrlFilename:
        data.serverDomain +
        "/file/" +
        company.serverStaticToken +
        "/" +
        data.id +
        "/" +
        data.name,
    };

  return {
    getUrl: data.serverDomain + "/file/" + data.id,
    getUrlData: data.serverDomain + "/file/" + data.id + "/data",
    getUrlDownload: data.serverDomain + "/file/" + data.id + "/download",
    getUrlFilename: data.serverDomain + "/file/" + data.id + "/" + data.name,
  };
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const queryParam = (a) => {
  let s = [];
  let add = function (k, v) {
    v = typeof v === "function" ? v() : v;
    v = v === null ? "" : v === undefined ? "" : v;
    s[s.length] = encodeURIComponent(k) + "=" + encodeURIComponent(v);
  };
  let buildParams = function (prefix, obj) {
    let i, len;

    if (prefix) {
      if (Array.isArray(obj)) {
        for (i = 0, len = obj.length; i < len; i++) {
          buildParams(
            prefix +
              "[" +
              (typeof obj[i] === "object" && obj[i] ? i : "") +
              "]",
            obj[i]
          );
        }
      } else if (Object.prototype.toString.call(obj) === "[object Object]") {
        for (let key of Object.keys(obj)) {
          buildParams(prefix + "[" + key + "]", obj[key]);
        }
      } else {
        add(prefix, obj);
      }
    } else if (Array.isArray(obj)) {
      for (i = 0, len = obj.length; i < len; i++) {
        add(obj[i].name, obj[i].value);
      }
    } else {
      for (let key of Object.keys(obj)) {
        buildParams(key, obj[key]);
      }
    }
    return s;
  };

  return buildParams("", a).join("&");
};

/**
 * обработкой ошибок и проверкой на json
 * @param url
 * @param options
 * @returns {Promise<unknown>}
 */
export async function apiQuery(url, options = {}) {
  return new Promise(function (resolve, reject) {
    let domain = "https://" + domain_ + "/api";
    if (options.domain) domain = options.domain;

    if (options.method === "GET") {
      url = url + "?" + queryParam(options.body);
      options.body = null;
    } else {
      if (options.body) options.body = JSON.stringify(options.body);
    }

    options.credentials = "include";

    let headers = {
      "Content-Type": "application/json",
    };

    if (options.headers) options.headers = { headers, ...options.headers };
    else options.headers = headers;

    fetch(domain + url, options)
      .then(async (res) => {
        if (res.ok) return res;
        if (res.status === 400 || res.status === 401) {
          let body = await res.json();
          return Promise.reject({ res, body });
        }
        let body = await res.text();
        return Promise.reject({ res, body });
      })
      .then((res) => res.json())
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function apiQueryError({ error }) {
  console.error(error);
  if (error?.res?.status === 401) userStore.setAuth(false);
  if (error?.res?.status === 404) message.error("Ошибка 404! Адрес не найден.");
  if (error?.body?.msg) message.error(error.body.msg);
}

const getUniqueBy = (arr, prop) => {
  const set = new Set();
  return arr.filter((o) => !set.has(o[prop]) && set.add(o[prop]));
};

const urlAvatar = (avatar) => {
  if (!avatar) return noFoto;
  return `https://${domain_}/api/public/file/${avatar}`;
};

// declOfNum(1, ['минута', 'минуты', 'минут']); // вернёт — минута
// declOfNum(2, ['минута', 'минуты', 'минут']); // вернёт — минуты
// declOfNum(5, ['минута', 'минуты', 'минут']); // вернёт — минут
function declOfNum(number, words) {
  return words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
  ];
}

const Utils = {
  mobileCheck,
  fileAdapted,
  apiQuery,
  getUniqueBy,
  domain: domain_,
  urlAvatar,
  declOfNum,
};
export default Utils;
