import React from "react";
import styles from "../pages/Home.module.scss";
import ExpertsTop from "../components/Experts/ExpertsTop";
import SliderHistory from "../components/SliderHistory/";
import ExpertsAllList from "../components/Experts/ExpertsAllList";
import left_icon from "../assets/images/arrow_left.svg";
import Header from "../components/Header";
import { Button, Col, Drawer, Empty, Input, Row, Spin } from "antd";
import SearchStore from "../components/Search/SearchStore";
import { Link, useNavigate } from "react-router-dom";
import AppStore from "../components/App/AppStore";
import { observer } from "mobx-react-lite";
import BestCategories from "../components/BestCategories";
import BestSearch from "../components/BestSearch";
import AdsMain from "../components/Ads/AdsMain";
import PredFooter from "../components/PredFooter/";
import CompanySlider from "../components/Company/CompanySlider";
import bodyScrollLock from "../Utils/bodyScrollLock";
import flter from "../assets/images/filter1.svg";
import InfiniteScroll from "react-infinite-scroller";
import ExpertsItemGorizont2 from "../components/ExpertsItemGorizont2";
import SearchDrawer from "../components/Search/SearchDrawer";

function Home() {
  let navigate = useNavigate();

  return (
    <>
      <div className={styles.homeHeader}>
        <Header button1={<img src={left_icon} alt={""} />} home={true} />
        <div className="container">
          <div className={styles.homeHeaderSearchBlock}>
            <p className={styles.homeHeaderSearchBlockTitle}>
              Найдите опытных специалистов в один клик
            </p>
            <div className={styles.homeHeaderSearchInputContent}>
              <Row>
                <Col flex={"30px"}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 29 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginTop: 3 }}
                  >
                    <path
                      d="M26.2167 28.375L16.5042 18.6625C15.7333 19.2792 14.8469 19.7674 13.8448 20.1271C12.8427 20.4868 11.7764 20.6667 10.6458 20.6667C7.84514 20.6667 5.47508 19.6964 3.53567 17.756C1.59625 15.8156 0.626028 13.4455 0.625 10.6458C0.625 7.84514 1.59522 5.47508 3.53567 3.53567C5.47611 1.59625 7.84617 0.626028 10.6458 0.625C13.4465 0.625 15.8166 1.59522 17.756 3.53567C19.6954 5.47611 20.6656 7.84617 20.6667 10.6458C20.6667 11.7764 20.4868 12.8427 20.1271 13.8448C19.7674 14.8469 19.2792 15.7333 18.6625 16.5042L28.375 26.2167L26.2167 28.375ZM10.6458 17.5833C12.5729 17.5833 14.2112 16.9086 15.5607 15.5591C16.9101 14.2097 17.5844 12.5719 17.5833 10.6458C17.5833 8.71875 16.9086 7.08047 15.5591 5.731C14.2097 4.38153 12.5719 3.70731 10.6458 3.70833C8.71875 3.70833 7.08047 4.38307 5.731 5.73254C4.38153 7.08201 3.70731 8.71978 3.70833 10.6458C3.70833 12.5729 4.38307 14.2112 5.73254 15.5607C7.08201 16.9101 8.71978 17.5844 10.6458 17.5833Z"
                      fill="#6B6B6B"
                    />
                  </svg>
                </Col>
                <Col flex={"auto"}>
                  <Input
                    className={styles.homeHeaderSearchBlockInput}
                    placeholder={"Введите имя или специальность"}
                    value={SearchStore.filter.searchValue}
                    onChange={(e) => {
                      SearchStore.setSearchValue(e.target.value);
                    }}
                    onPressEnter={(e) => {
                      console.log(e);
                      SearchStore.setSearchValue(e.target.value);
                      if (AppStore.width <= 767)
                        SearchStore.setDrawerOpen(true);
                      else navigate("/search", { replace: true });
                    }}
                  />
                </Col>
                <Col className={styles.bytt} flex={"70px"}>
                  <Button
                    block
                    className={styles.searchButton}
                    onClick={() => {
                      if (AppStore.width <= 767)
                        SearchStore.setDrawerOpen(true);
                      else navigate("/search", { replace: true });
                    }}
                  >
                    Поиск
                  </Button>
                </Col>
              </Row>
            </div>
            {AppStore.width > 767 && (
              <p className={styles.homeHeaderSearchBlockPopularTitle}>
                Популярные запросы
              </p>
            )}
            {AppStore.width <= 767 && (
              <p
                className={styles.homeHeaderSearchBlockPopularTitle}
                style={{ marginTop: 30 }}
              >
                {" "}
              </p>
            )}
            {[
              { value: "6589a04d3b658096128e25ca", label: "SMM" },
              { value: "6589a04d3b658096128e25d3", label: "Таргет" },
              {
                value: "6589a04d3b658096128e25d0",
                label: "Мобилография",
              },
            ].map((item, index) => (
              <span
                key={index}
                className={styles.popularItem}
                onClick={() => {
                  SearchStore.addFilterCategoryItem(item.value, true);
                  if (AppStore.width <= 767) SearchStore.setDrawerOpen(true);
                  else navigate("/search", { replace: true });
                }}
              >
                {item.label}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.wrap_home}>
        {/*<div className={styles.history_home}>*/}
        {/*  <SliderHistory />*/}
        {/*</div>*/}
        <BestCategories />
        <ExpertsTop />
        <BestSearch />
        <ExpertsAllList />
        <PredFooter />
        {/* <CompanySlider />*/}
        <AdsMain />
        <SearchDrawer />
      </div>
    </>
  );
}

export default observer(Home);
