import React, {useEffect, useState} from "react";
import AdsStore from "./AdsStore";
import styles from "../Ads/Ads.module.scss";
import {Button, Col, Empty, Form, Input, Modal, Row, DatePicker, Spin} from "antd";
import InfiniteScroll from "react-infinite-scroller";
import AdsItem from "./AdsItem";
import {observer} from "mobx-react-lite";
import AppStore from "../App/AppStore";
import bodyScrollLock from "../../Utils/bodyScrollLock";
import img from "../../assets/images/imgphot.svg";
import starRating from "../../assets/images/starRating.svg";
import SearchModalSelect from "../Search/SearchModalSelect";
import {SearchOutlined} from "@ant-design/icons";

const { RangePicker } = DatePicker;

const Ads = () => {
  const items = AdsStore.items;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    AdsStore.categoryList();
    AdsStore.list({clear: true});
    return () => {
      AdsStore.clearFilter()
    }
  }, []);

  return (
    <div className="container">
      <div className={"mb-16"}>
        <Row>
          <Col flex={'auto'}>
            <div className={styles.flex_tit + " mb-16"}>
              <p className={styles.title_page}>Объявления</p>
            </div>
          </Col>
          {AppStore.width > 767 && <Col flex={'150px'}>
                <span className={styles.btnFilter} onClick={() => setOpen(true)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 20 20" fill="none">
                    <path d="M0.9375 3.75H19.0625C19.3111 3.75 19.5496 3.84877 19.7254 4.02459C19.9012 4.2004 20 4.43886 20 4.6875C20 4.93614 19.9012 5.1746 19.7254 5.35041C19.5496 5.52623 19.3111 5.625 19.0625 5.625H0.9375C0.68886 5.625 0.450403 5.52623 0.274587 5.35041C0.098772 5.1746 0 4.93614 0 4.6875C0 4.43886 0.098772 4.2004 0.274587 4.02459C0.450403 3.84877 0.68886 3.75 0.9375 3.75ZM3.75 9.6875C3.75 9.43886 3.84877 9.2004 4.02459 9.02459C4.2004 8.84877 4.43886 8.75 4.6875 8.75H15.3125C15.5611 8.75 15.7996 8.84877 15.9754 9.02459C16.1512 9.2004 16.25 9.43886 16.25 9.6875C16.25 9.93614 16.1512 10.1746 15.9754 10.3504C15.7996 10.5262 15.5611 10.625 15.3125 10.625H4.6875C4.43886 10.625 4.2004 10.5262 4.02459 10.3504C3.84877 10.1746 3.75 9.93614 3.75 9.6875ZM7.5 14.6875C7.5 14.4389 7.59877 14.2004 7.77459 14.0246C7.9504 13.8488 8.18886 13.75 8.4375 13.75H11.5625C11.8111 13.75 12.0496 13.8488 12.2254 14.0246C12.4012 14.2004 12.5 14.4389 12.5 14.6875C12.5 14.9361 12.4012 15.1746 12.2254 15.3504C12.0496 15.5262 11.8111 15.625 11.5625 15.625H8.4375C8.18886 15.625 7.9504 15.5262 7.77459 15.3504C7.59877 15.1746 7.5 14.9361 7.5 14.6875Z" fill="#5974FF"/>
                  </svg>
                  <span>Фильтр поиска</span>
                </span>
          </Col>}
        </Row>

        <Row>
          <Col flex={'auto'}>
            <div className={styles.searchInputBlock}>
              <Input size="large"
                     placeholder="Введите имя"
                     className={styles.searchInput}
                     prefix={<SearchOutlined/>}
                     value={AdsStore.filter.searchValue}
                     onChange={(e) => {
                       AdsStore.setSearchValue(e.target.value);
                     }}
                     onPressEnter={() => {
                       AdsStore.list({clear: true})
                     }}
              />
            </div>
          </Col>
          {AppStore.width <= 767 && <Col flex={'45px'}>
            <div onClick={() => setOpen(true)} style={{padding: '5px 0 0 10px'}}>
              <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="17.5" cy="17.5" r="17.5" fill="#4575F1"/>
                <path d="M27.222 12.1528H21.3887M11.6665 12.1528H7.77759M27.222 22.8472H23.3331M13.6109 22.8472H7.77759M15.5554 15.5556C16.0022 15.5556 16.4447 15.4675 16.8576 15.2965C17.2704 15.1255 17.6455 14.8749 17.9615 14.5589C18.2775 14.2429 18.5281 13.8678 18.6991 13.455C18.8701 13.0421 18.9581 12.5996 18.9581 12.1528C18.9581 11.7059 18.8701 11.2634 18.6991 10.8506C18.5281 10.4377 18.2775 10.0626 17.9615 9.74665C17.6455 9.43067 17.2704 9.18003 16.8576 9.00902C16.4447 8.83802 16.0022 8.75 15.5554 8.75C14.6529 8.75 13.7874 9.10851 13.1492 9.74665C12.5111 10.3848 12.1526 11.2503 12.1526 12.1528C12.1526 13.0553 12.5111 13.9208 13.1492 14.5589C13.7874 15.197 14.6529 15.5556 15.5554 15.5556V15.5556ZM19.4443 26.25C20.3467 26.25 21.2122 25.8915 21.8504 25.2533C22.4885 24.6152 22.847 23.7497 22.847 22.8472C22.847 21.9447 22.4885 21.0792 21.8504 20.4411C21.2122 19.803 20.3467 19.4444 19.4443 19.4444C18.5418 19.4444 17.6763 19.803 17.0381 20.4411C16.4 21.0792 16.0415 21.9447 16.0415 22.8472C16.0415 23.7497 16.4 24.6152 17.0381 25.2533C17.6763 25.8915 18.5418 26.25 19.4443 26.25V26.25Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </Col>}
        </Row>

      </div>

      <div>
        {!items.length && AdsStore.fetchCheck && (
          <div style={{width: "100%", textAlign: "center"}}>
            <Spin/>
          </div>
        )}
        {!items.length && !AdsStore.fetchCheck && (
          <div style={{width: "100%", textAlign: "center"}}>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={"По вашим критериям ничего не найдено"}
            />
          </div>
        )}
        {!!items.length && (
          <InfiniteScroll
            style={{width: "100%", minHeight: 50, padding: "20px 0"}}
            pageStart={0}
            loadMore={() => AdsStore.list({})}
            hasMore={AdsStore.itemsLoadCheck}
            loader={
              <div className="loader" key={0}>
                <Spin/>
              </div>
            }
          >
            {items.map((item, index) => (
              <div
                key={index}
                style={{
                  width: AppStore.width <= 767 ? "100%" : "33.3%",
                  display: "inline-block",
                }}
              >
                <div style={{padding: "0 5px 10px"}}>
                  <AdsItem item={item}/>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        )}
      </div>

      <Modal
        title={
          <div className={styles.flex_form}>
            <p>Фильтр</p>
          </div>
        }
        centered
        open={open}
        onOk={() => {
          setOpen(false);
          bodyScrollLock.disable();
        }}
        onCancel={() => {
          setOpen(false);
          bodyScrollLock.disable();
        }}
        footer={null}
      >
        <div className={styles.select_filter}>
          <Form name="basic" labelCol={{span: 24}} wrapperCol={{span: 24}}>

            <Form.Item label="Категория">
              <SearchModalSelect
                mode="multiple"
                modalTitle={"Категории"}
                modalAllTitle={"Все категории"}
                modalPopularTitle={"Популярные категории"}
                modalPopularOptions={AdsStore.optionsCategory.filter((item) => [
                  '63da66486e62e680c299af0e', '63da67236e62e680c299af10', '63da672d6e62e680c299af11'
                ].includes(item.value))}
                placeholder="Категория"
                onChange={(value) => {
                  AdsStore.setFilterCategory(value);
                }}
                value={AdsStore.filter.category}
                options={AdsStore.optionsCategory}
                onSelectAdd={(v) => AdsStore.addFilterCategoryItem(v)}
                onSelectDel={(v) => AdsStore.delFilterCategoryItem(v)}
              />
            </Form.Item>

            <Form.Item label={'Дата создания'} name={'dateRange'}>
              <RangePicker
                style={{width:'100%'}}
                size={'large'}
                onChange={(v) => {
                console.log(v)
                AdsStore.setFilterDateRange(v)
              }}/>
            </Form.Item>

            <Button
              onClick={() => {
                AdsStore.list({clear: true});
              }}
              type={"primary"}
              block
            >
              Применить фильтр
            </Button>

            <div
              style={{textAlign: "center", margin: '20px 0', cursor: "pointer"}}
              onClick={() => {
                AdsStore.clearFilter();
              }}
            >Сбросить фильтр
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default observer(Ads);
