import React from "react";
import styles from "../ArticleCard/ArticleCard.module.scss";
import Utils from "../../Utils";
import menu from "../../assets/images/menu.svg";
import {AutoSizer} from "react-virtualized";
import ImagePreview from "../ImagePreview";

const ArticleCard = ({item}) => {
   return (
    <div className={styles.keysit_bb}>
    <div className={styles.articl_flex}>
      <img src={menu} alt={''}/> <p>{item.title}<span>Собери лучшее портфолио</span></p>
    </div>
      <div className={styles.keysit}>
        <div>
          {/*<img src={`https://${Utils.domain}/api/public/file/${item.image}`}*/}
          {/*     className={styles.keysit_img}*/}
          {/*     style={{width: '100%', height: "inherit"}}*/}
          {/*/>*/}
          <div style={{marginBottom: 15}}>
            <AutoSizer disableHeight={true}>
              {({ width }) => (
                <ImagePreview src={`https://${Utils.domain}/api/public/file/${item.image}`}
                              width={width}
                              height={width}
                              style={{border: 0, borderRadius: 16}}
                />
                )}
            </AutoSizer>
          </div>
          {/*<div style={{backgroundImage: `url(https://${Utils.domain}/api/public/file/${item.image})` }}*/}
          {/*      className={styles.keysit_img}/>*/}
          <span>{item.description}</span>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
