import React, { useEffect } from "react";
import styles from "../PersonalUser/PersonalUser.module.scss";
import ReviewsItem from "../ReviewsItem/index";
import ReviewsStore from "../Reviews/ReviewsStore";
import { observer } from "mobx-react-lite";
import {Empty, Spin} from "antd";
import userStore from "../Auth/userStore";
import AnswerAdd from "../Reviews/AnswerAdd";

const ReviewsPage = () => {
  const userId = userStore.userData._id;
  useEffect(() => {
    ReviewsStore.commentList(userId);
  }, [userId]);

  return (
    <div className={styles.page_form} style={{maxWidth: 700, margin: '0 auto'}}>
      <div className="container">
        <p className={styles.title_page}>Отзывы</p>

        <div>
          {ReviewsStore.commentItemsLoad && <Spin />}
          {!ReviewsStore.commentItemsLoad && !ReviewsStore.commentItems.length && (
            <div style={{ width: "100%", textAlign: "center" }}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={"Вам еще не оставили отзывы"}
              />
            </div>
          )}
          {!ReviewsStore.commentItemsLoad && ReviewsStore.commentItems.map((item, index) => (
            <span key={index}>
              <ReviewsItem item={item} />
            </span>
          ))}
        </div>
      </div>
      <AnswerAdd />
    </div>
  );
};
export default observer(ReviewsPage);
