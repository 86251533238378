import React, { useEffect } from "react";
import styles from "../Company/CompanyCard.module.scss";
import { Spin, Tabs } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import ContactBlock from "../ContactBlock/index";
import AppStore from "../App/AppStore";
import { observer } from "mobx-react-lite";
import ExpertsStore from "../Experts/ExpertsStore";
import Utils from "../../Utils";
import Reviews from "../Reviews/Reviews";

const { TabPane } = Tabs;

const CompanyCard = ({ userId }) => {
  const params = useParams();
  if (!userId && params.userId) userId = params.userId;
  let navigate = useNavigate();

  const onChange = (key) => {
    console.log(key);
  };

  let width = AppStore.width - 32;
  if (width > 700) width = 700;

  useEffect(() => {
    if (!userId)
      return navigate("/", {
        replace: true,
      });
    ExpertsStore.expertCard(userId);
    if (width > 900) AppStore.setLogovisible(false);
    ExpertsStore.clearFilter();
    return () => {
      AppStore.setLogovisible(true);
    };
  }, [userId, navigate]);

  if (ExpertsStore.expertCardLoad && !ExpertsStore?.expertCardData?.profile)
    return (
      <div className={styles.company_bl}>
        <div className="container">
          <Spin />
        </div>
      </div>
    );
  if (!ExpertsStore?.expertCardData || !userId) return null;

  return (
    <div className={styles.company_bl}>
      <div className="container">
        <div className={styles.flex_vvv}>
          <div className={styles.right_bl}>
            <div
              className={styles.avatarBlock + " " + styles.avatarwidth}
              style={{
                backgroundImage: `url(${Utils.urlAvatar(
                  ExpertsStore?.expertCardData?.profile?.avatar
                )})`,
              }}
            />
          </div>

          <div className={styles.left_bl + " " + styles.company_skl}>
            <div className={styles.text_name}>
              <p>{ExpertsStore.expertCardData.profile.fio}</p>
            </div>
            <div className={styles.flex_categ}>
              <p>
                <b>{ExpertsStore.expertCardData?.cases?.length || 0} </b>
                {Utils.declOfNum(
                  ExpertsStore.expertCardData?.cases?.length || 0,
                  ["объявление", "объявления", "объявлений"]
                )}
              </p>
              <p>
                <b>{ExpertsStore.expertCardData.profile?.experience} </b>
                {Utils.declOfNum(
                  ExpertsStore.expertCardData.profile?.experience || 0,
                  ["год опыта", "года опыта", "лет опыта"]
                )}
              </p>
              <p>
                <b>{ExpertsStore.expertCardData?.skills?.length || 0} </b>
                {Utils.declOfNum(
                  ExpertsStore.expertCardData?.skills?.length || 0,
                  ["навык", "навыка", "навыков"]
                )}
              </p>
            </div>
            <div className={styles.text_more}>
              {ExpertsStore.expertCardData.skillsCategory &&
                ExpertsStore.expertCardData.skillsCategory.map(
                  (item, index) => {
                    if (index === 0) return <b>{item.name}</b>;
                    return <p>{item.name}</p>;
                  }
                )}
            </div>
            <p className={styles.descript}>
              {ExpertsStore.expertCardData.profile.description}
            </p>

            <div className={styles.navik} id={"skills"}>
              {!!ExpertsStore.expertCardData.skills &&
                ExpertsStore.expertCardData.skills.length > 0 && (
                  <>
                    {ExpertsStore.expertCardData.skills.map((item, index) => (
                      <p key={index}>{item.name}</p>
                    ))}
                  </>
                )}
            </div>
          </div>
        </div>
        <div className={styles.tabs_company}>
          <Tabs
            className="scroll"
            defaultActiveKey="1"
            onChange={onChange}
            centered="true"
          >
            <TabPane
              tab={
                <div className={styles.foto_b}>
                  <svg
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.4591 16.5H3.56645C3.20647 16.5021 2.85011 16.4306 2.52079 16.2904C2.19147 16.1501 1.89664 15.9441 1.6557 15.6861C1.41475 15.428 1.23313 15.1236 1.12279 14.793C1.01245 14.4623 0.975881 14.1129 1.01549 13.7677L1.87006 6.51845C1.90474 6.21603 2.05428 5.93672 2.28983 5.73444C2.52537 5.53215 2.83022 5.42123 3.14554 5.42307H14.88C15.1953 5.42123 15.5001 5.53215 15.7357 5.73444C15.9712 5.93672 16.1208 6.21603 16.1555 6.51845L16.9845 13.7677C17.0239 14.1109 16.988 14.4582 16.8791 14.7871C16.7703 15.116 16.591 15.4192 16.3528 15.6769C16.1146 15.9346 15.8229 16.141 15.4967 16.2828C15.1704 16.4246 14.8169 16.4986 14.4591 16.5Z"
                      stroke="#0F182B"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.91113 5.42306C3.91113 4.11739 4.44866 2.86519 5.40545 1.94193C6.36225 1.01868 7.65994 0.5 9.01306 0.5C10.3662 0.5 11.6639 1.01868 12.6207 1.94193C13.5775 2.86519 14.115 4.11739 14.115 5.42306M5.82436 9.11536H12.2018"
                      stroke="#0F182B"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Объявления
                </div>
              }
              key="1"
            >
              <div className={styles.flex_gal}>
                {!ExpertsStore.expertCardData.cases.length && (
                  <p>Компания еще не добавила свои объявления</p>
                )}

                {!!ExpertsStore.expertCardData.cases.length &&
                  ExpertsStore.expertCardData.cases.map((item, index) => {
                    if (item.image && item.image !== "")
                      return (
                        <Link
                          to={"/cases/view/" + item._id}
                          key={index}
                          style={{
                            backgroundImage: `url(${Utils.urlAvatar(
                              item.image
                            )})`,
                          }}
                        />
                      );

                    return (
                      <Link to={"/cases/view/" + item._id} key={index}>
                        <span>{item.name}</span>
                      </Link>
                    );
                  })}
              </div>
            </TabPane>
            <TabPane
              tab={
                <div className={styles.foto_b}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.80034 2.53133L7.99967 1.33133L9.19901 2.53133C9.39782 2.7299 9.66735 2.8414 9.94834 2.84133H13.4543C13.7758 2.84133 14.0841 2.96902 14.3114 3.19632C14.5386 3.42361 14.6663 3.73189 14.6663 4.05333V13.456C14.6663 13.7774 14.5386 14.0857 14.3114 14.313C14.0841 14.5403 13.7758 14.668 13.4543 14.668H2.54501C2.22357 14.668 1.91529 14.5403 1.68799 14.313C1.4607 14.0857 1.33301 13.7774 1.33301 13.456V4.05333C1.33301 3.73189 1.4607 3.42361 1.68799 3.19632C1.91529 2.96902 2.22357 2.84133 2.54501 2.84133H6.05167C6.33244 2.84122 6.60169 2.72973 6.80034 2.53133Z"
                      stroke="#8E8E8E"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.3986 14.668V14.2667C12.3986 13.5686 12.1213 12.899 11.6277 12.4053C11.1342 11.9116 10.4647 11.6342 9.7666 11.634H6.32993C5.63171 11.634 4.96208 11.9114 4.46836 12.4051C3.97464 12.8988 3.69727 13.5684 3.69727 14.2667V14.6687"
                      stroke="#8E8E8E"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.0484 9.754C9.35768 9.754 10.4191 8.69261 10.4191 7.38333C10.4191 6.07405 9.35768 5.01266 8.0484 5.01266C6.73912 5.01266 5.67773 6.07405 5.67773 7.38333C5.67773 8.69261 6.73912 9.754 8.0484 9.754Z"
                      stroke="#8E8E8E"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Контакты
                </div>
              }
              key="2"
            >
              <ContactBlock profile={ExpertsStore.expertCardData?.profile} />
            </TabPane>
            <TabPane
              tab={
                <div className={styles.foto_b}>
                  <svg
                    width="12"
                    height="16"
                    viewBox="0 0 12 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clip-rule="evenodd"
                      fill="none"
                      d="M3.83323 2.81233C3.84448 2.27142 4.2895 1.83333 4.83301 1.83333H7.16634C7.44157 1.83333 7.68926 1.94335 7.87279 2.12688C8.05631 2.31041 8.16634 2.5581 8.16634 2.83333C8.16634 3.38385 7.71687 3.83333 7.16634 3.83333H4.83366C4.55843 3.83333 4.31074 3.7233 4.12721 3.53977C3.94369 3.35625 3.83366 3.10855 3.83366 2.83333C3.83366 2.82629 3.83351 2.81929 3.83323 2.81233ZM2.89238 2.34868C3.10957 1.47927 3.89727 0.833328 4.83301 0.833328H7.16634C7.71778 0.833328 8.21675 1.05664 8.5799 1.41977C8.83295 1.67283 9.0181 1.99184 9.1071 2.3486C9.74073 2.39489 10.3689 2.55655 10.87 2.9676C11.5078 3.49087 11.8337 4.32699 11.8337 5.49999V12C11.8337 13.1024 11.5009 13.9252 10.8796 14.4618C10.272 14.9864 9.4689 15.1667 8.66699 15.1667H3.33366C2.53175 15.1667 1.72862 14.9864 1.12103 14.4618C0.499738 13.9252 0.166992 13.1024 0.166992 12V5.49999C0.166992 4.32699 0.492861 3.49087 1.1307 2.9676C1.63143 2.55681 2.25915 2.39509 2.89238 2.34868ZM2.90085 3.35128C2.41543 3.39455 2.03666 3.51782 1.76495 3.74072C1.43779 4.00912 1.16699 4.50633 1.16699 5.49999V12C1.16699 12.8976 1.43091 13.4081 1.77462 13.7049C2.13203 14.0135 2.66224 14.1667 3.33366 14.1667H8.66699C9.33842 14.1667 9.86862 14.0135 10.226 13.7049C10.5697 13.4081 10.8337 12.8976 10.8337 12V5.49999C10.8337 4.50633 10.5629 4.00912 10.2357 3.74072C9.96384 3.5177 9.58481 3.39441 9.09901 3.35121C8.87027 4.2038 8.09077 4.83333 7.16699 4.83333H7.16634H4.83366H4.83301C4.28157 4.83333 3.78259 4.61002 3.41945 4.24688C3.17428 4.00171 2.99285 3.69463 2.90085 3.35128ZM3.33301 8.16666C3.05687 8.16666 2.83301 8.39052 2.83301 8.66666C2.83301 8.9428 3.05687 9.16666 3.33301 9.16666H5.99967C6.27582 9.16666 6.49967 8.9428 6.49967 8.66666C6.49967 8.39052 6.27582 8.16666 5.99967 8.16666H3.33301ZM3.33301 10.8333C3.05687 10.8333 2.83301 11.0572 2.83301 11.3333C2.83301 11.6095 3.05687 11.8333 3.33301 11.8333H8.66634C8.94248 11.8333 9.16634 11.6095 9.16634 11.3333C9.16634 11.0572 8.94248 10.8333 8.66634 10.8333H3.33301Z"
                    />
                  </svg>
                  Отзывы
                </div>
              }
              key="3"
            >
              <Reviews expertId={userId} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default observer(CompanyCard);
