import React from "react";
import styles from "../ContactBlock/ContactBlock.module.scss";
import userStore from "../Auth/userStore";
import mail from "../../assets/images/mail.svg";
import star2 from "../../assets/images/contacts.svg";
import call from "../../assets/images/call.svg";
import map from "../../assets/images/map.svg";
import { Link } from "react-router-dom";
import map2 from "../../assets/images/map2.svg";
import instagram from "../../assets/images/instagram.svg";
import { InstagramOutlined, LockOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";

const ContactBlock = ({ profile }) => {
  const auth = userStore.auth;
  if (!profile) return null;

  console.log({ profile });

  return (
    <>
      <div className={styles.contact_bb}>
        <div className={styles.flex_tit + " mb-8"}>
          <h4 className={styles.title_main}>
            <img src={star2} alt={""} /> Контакты
          </h4>
        </div>
        {!auth ? (
          <div className={styles.not_user}>
            <p>
              {" "}
              <LockOutlined />
              Информация доступна только авторизованным пользователям.{" "}
            </p>
            <Link to="/create">Авторизоваться</Link>
          </div>
        ) : (
          <>
            {/*<p><img src={mail} alt={''}/> {profile.email}</p>*/}
            <p>
              <img src={map} alt={""} />{" "}
              {profile?.country?.name ? profile?.country?.name + ", " : ""}
              {profile?.city?.name}
            </p>
            <p>
              <img src={instagram} alt={""} />{" "}
              <a
                href={
                  "https://www.instagram.com/" +
                  (profile.site && profile.site !== ""
                    ? profile.site.replace("@", "")
                    : "")
                }
                target={"_blank"}
                rel="noreferrer"
              >
                {profile.site && profile.site !== ""
                  ? profile.site.replace("@", "")
                  : ""}
              </a>
            </p>
            <p>
              <img src={call} alt={""} />{" "}
              <a
                href={`tel:${profile.phone}`}
                target={"_blank"}
                rel="noreferrer"
              >
                {profile.phone}
              </a>
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default observer(ContactBlock);
