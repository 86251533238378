import { Link } from "react-router-dom";
import Utils from "../../Utils";
import styles from "../Company/CompanyBlockItem.module.scss";
import { Col, Progress, Row } from "antd";
import { AutoSizer } from "react-virtualized";
import React from "react";
import premium from "../../assets/images/premium.svg";

const CompanyBlockItem = ({ item }) => {
  return (
    <Link to={"/company/" + item?._id} className={styles.expertItem}>
      <div
        style={{
          backgroundImage: `url(${Utils.urlAvatar(item?.profileData?.avatar)})`,
        }}
        className={styles.expert_avatar}
      >
        {item?.premium && (
          <img
            src={premium}
            alt={""}
            style={{
              width: 20,
              position: "absolute",
              top: 5,
              right: 5,
            }}
          />
        )}
      </div>

      <div className={styles.panel_text}>
        <div>
          <p>{item?.profileData?.fio}</p>
          <div>
            {!!item.profileData.category &&
              item.profileData.category.length > 0 &&
              item.profileData.category.slice(0, 1).map((item, index) => (
                <span key={index} className={styles.categoryName}>
                  {item?.name}
                </span>
              ))}
          </div>
        </div>

        <div className={styles.review}>
          <Progress
            type="circle"
            size={35}
            strokeColor={"#0029FF"}
            percent={(item?.profileData?.review * 100) / 5}
            format={() => <span>{item?.profileData?.review || 0}</span>}
          />
        </div>
      </div>
    </Link>
  );
};

export default CompanyBlockItem;
