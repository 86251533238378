import styles from "./Ads.module.scss";
import { Col, Row } from "antd";
import ImagePreview from "../ImagePreview";
import Utils from "../../Utils";
import ExpertsStore from "../Experts/ExpertsStore";
import star from "../../assets/images/starRating.svg";
import React from "react";
import { useNavigate } from "react-router-dom";
import premium from "../../assets/images/premium.svg";
import { AutoSizer } from "react-virtualized";

const AdsItem = ({ item }) => {
  let navigate = useNavigate();

  return (
    <div
      className={styles.adsItem}
      onClick={() => navigate("/cases/view/" + item._id)}
    >
      <div className={styles.adsCategory}>{item?.category?.name}</div>
      <div className={styles.adsTitle}>{item.name}</div>
      <div className={styles.adsReview}>
        <p className={styles.rait}>
          <span>{item?.userIdData?.profileData?.review || 0} </span>
          <img src={star} alt={""} />
          <span className={styles.ratingContent}>
            ({item?.userIdData?.profileData?.reviewCount || 0}{" "}
            {Utils.declOfNum(item?.userIdData?.profileData?.reviewCount || 0, [
              "отзыв",
              "отзыва",
              "отзывов",
            ])}
            )
          </span>
        </p>
      </div>
      <div className={styles.adsUser}>
        <Row>
          <Col flex={"50px"}>
            <ImagePreview
              src={`${Utils.urlAvatar(item?.userIdData?.profileData?.avatar)}`}
              style={{
                borderRadius: 50,
                border: 0,
              }}
              width={40}
              height={40}
            />
          </Col>
          <Col
            flex={"auto"}
            style={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <AutoSizer disableHeight={true} style={{ display: "inline-block" }}>
              {({ width }) => (
                <>
                  <div className={styles.fio} style={{ width: width - 10 }}>
                    {item?.userIdData?.displayName}
                  </div>
                  <div>
                    {item.userIdData?.premium && (
                      <img src={premium} alt={""} style={{ width: 16 }} />
                    )}
                  </div>
                </>
              )}
            </AutoSizer>
          </Col>
          <Col
            flex={"31px"}
            style={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <svg
              width="31"
              height="30"
              viewBox="0 0 31 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.3867 7.5L14.0461 8.80594L19.2773 14.0625H7.88672V15.9375H19.2773L14.0461 21.1622L15.3867 22.5L22.8867 15L15.3867 7.5Z"
                fill="#4575F1"
              />
              <path
                d="M15.3867 28.125C12.7908 28.125 10.2533 27.3552 8.09486 25.913C5.93647 24.4709 4.25421 22.421 3.26081 20.0227C2.26741 17.6244 2.00749 14.9854 2.51392 12.4394C3.02035 9.89345 4.27038 7.55479 6.10595 5.71923C7.94151 3.88367 10.2802 2.63363 12.8262 2.1272C15.3722 1.62077 18.0112 1.88069 20.4094 2.87409C22.8077 3.86749 24.8576 5.54975 26.2998 7.70815C27.742 9.86654 28.5117 12.4041 28.5117 15C28.5078 18.4798 27.1237 21.8158 24.6631 24.2764C22.2026 26.737 18.8665 28.121 15.3867 28.125ZM15.3867 3.75001C13.1617 3.75001 10.9866 4.40981 9.13656 5.64597C7.28651 6.88214 5.84457 8.63915 4.99308 10.6948C4.14159 12.7505 3.91881 15.0125 4.35289 17.1948C4.78697 19.3771 5.85843 21.3816 7.43177 22.955C9.00511 24.5283 11.0097 25.5998 13.192 26.0338C15.3742 26.4679 17.6362 26.2451 19.6919 25.3937C21.7476 24.5422 23.5046 23.1002 24.7408 21.2502C25.9769 19.4001 26.6367 17.225 26.6367 15C26.6333 12.0174 25.4469 9.15792 23.3378 7.04889C21.2288 4.93986 18.3693 3.75348 15.3867 3.75001Z"
                fill="#4575F1"
              />
            </svg>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AdsItem;
