import {configure, makeAutoObservable} from "mobx";
import {apiQuery, apiQueryError} from "../../Utils";

configure({enforceActions: "never"})

class FavoritesStore {

  buttonToggleLoad = false;
  itemsLoad = true;
  items = [];

  constructor() {
    makeAutoObservable(this)
  }

  buttonGet(caseId, cb){
    if (!cb) cb = () => {};
    this.buttonToggleLoad = true;
    apiQuery("/private/caseFavorite/" + caseId)
      .then((data) => {
        cb(data);
        this.buttonToggleLoad = false;
      })
      .catch((error) => {
        apiQueryError({error})
        this.buttonToggleLoad = false;
      })
  }

  buttonChange(caseId, cb){
    if (!cb) cb = () => {};
    this.buttonToggleLoad = true;
    apiQuery("/private/caseFavorite/" + caseId, {
      method: 'POST'
    })
      .then((data) => {
        cb(data);
        this.buttonToggleLoad = false;
        this.list();
      })
      .catch((error) => {
        apiQueryError({error})
        this.buttonToggleLoad = false;
      })
  }

  list(){
    this.itemsLoad = true;
    this.items = [];
    apiQuery("/private/caseFavorites", {
      method: 'POST'
    })
      .then((data) => {
        this.items = data.items;
        this.itemsLoad = false;
      })
      .catch((error) => {
        apiQueryError({error})
        this.itemsLoad = false;
      })
  }
}

const clsFavoritesStore = new FavoritesStore();
export default clsFavoritesStore