import React, { useEffect, useState } from "react";
import styles from "./CaseCard.module.scss";
import { Col, message, Row, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import ArticleCard from "../ArticleCard";
import Utils from "../../Utils";
import { observer } from "mobx-react-lite";
import ImagePreview from "../ImagePreview";
import noFoto from "../../assets/images/nofoto.png";
import ContactBlock from "../ContactBlock";
import CasesStore from "./CasesStore";
import CaseFavoriteButton from "./CaseFavoriteButton";
import ViewCounterStore from "../ViewCounter/ViewCounterStore";
import Reviews from "../Reviews/Reviews";
import OrderModal from "./OrderModal";
import star from "../../assets/images/starf.svg";
import AppStore from "../App/AppStore";
import eye1 from "../../assets/images/eye1.svg";
import { AutoSizer } from "react-virtualized";

const CaseCard = () => {
  let navigate = useNavigate();
  const { caseId } = useParams();
  const [isSticky, setIsSticky] = useState();
  const [blockRightWidth, setBlockRightWidth] = useState();

  const isStickyHandler = (e) => {
    const scrollTop = window.scrollY;
    scrollTop >= 130 ? setIsSticky(true) : setIsSticky(false);
  };

  useEffect(() => {
    CasesStore.caseCard(caseId);
    ViewCounterStore.get("cases", caseId);
  }, [caseId]);

  useEffect(() => {
    window.addEventListener("scroll", isStickyHandler);
    return () => {
      window.removeEventListener("scroll", isStickyHandler);
    };
  }, []);

  if (CasesStore.caseCardLoad || !caseId) return <Spin />;
  if (!CasesStore.caseCardData) return null;

  return (
    <div className={styles.keycard}>
      <div className="container">
        {AppStore.width <= 768 ? (
          <>
            <p className={styles.title_page}>
              Детали объявления
              <div className={styles.flex_bb}>
                <CaseFavoriteButton caseId={caseId} />
              </div>
            </p>
            <div className={styles.block_mavi}>
              <Row>
                <Col flex={"auto"}>
                  <div
                    className={styles.top_keys}
                    onClick={() =>
                      navigate(
                        "/card/" + CasesStore.caseCardData?.profile?._id,
                        { replace: true }
                      )
                    }
                  >
                    {!CasesStore.caseCardData.profile.avatar && (
                      <ImagePreview
                        className={styles.avatar}
                        src={noFoto}
                        width={24}
                        height={24}
                      />
                    )}
                    {CasesStore.caseCardData.profile.avatar && (
                      <ImagePreview
                        className={styles.avatar}
                        src={`https://${Utils.domain}/api/public/file/${CasesStore.caseCardData?.profile?.avatar}`}
                        width={24}
                        height={24}
                      />
                    )}

                    <div className={styles.text_name}>
                      <p>{CasesStore.caseCardData?.profile?.fio}</p>
                    </div>
                  </div>
                </Col>
                <Col
                  flex={"30px"}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <button
                    className={styles.btnShare}
                    onClick={() => {
                      const textField = document.createElement("textarea");
                      textField.innerText = window.location.href;
                      document.body.appendChild(textField);
                      textField.select();
                      document.execCommand("copy");
                      textField.remove();
                      message.info("Ссылка скопирована в буфер обмена");
                    }}
                  >
                    <svg
                      width="18"
                      height="16"
                      viewBox="0 0 19 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.173 0.795654L11.1388 1.82994L15.6547 6.34369H5.90625C3.13547 6.34369 0.875 8.60415 0.875 11.3749C0.875 14.1457 3.13547 16.4062 5.90625 16.4062V14.9687C3.91316 14.9687 2.3125 13.368 2.3125 11.3749C2.3125 9.38184 3.91316 7.78119 5.90625 7.78119H15.6547L11.1395 12.2957L12.173 13.3292L17.923 7.57922L18.4168 7.06244L17.923 6.54565L12.173 0.795654Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </Col>
              </Row>

              <p className={styles.card_titl_rait}>
                <span>{CasesStore.caseCardData?.profile?.review} </span>
                <img src={star} alt={""} />
                <span className={styles.ratingContent}>
                  ({CasesStore.caseCardData?.profile?.reviewCount || 0}{" "}
                  {Utils.declOfNum(
                    CasesStore.caseCardData?.profile?.reviewCount || 0,
                    ["отзыв", "отзыва", "отзывов"]
                  )}
                  )
                </span>

                <p className={styles.view}>
                  <EyeOutlined /> {CasesStore.caseCardData?.caseData?.viewCount}
                </p>
              </p>
            </div>
            {CasesStore.caseCardData?.caseData?.image && (
              <div
                className={styles.keycard1}
                style={{
                  background: `url(https://${Utils.domain}/api/public/file/${CasesStore.caseCardData?.caseData?.image})`,
                  backgroundSize: `cover`,
                  backgroundPosition: `50% 50%`,
                  borderRadius: 20,
                  marginBottom: 15,
                }}
              />
            )}

            <div className={styles.title_case_title}>
              <p>
                {CasesStore.caseCardData?.caseData?.name}
                <span>{CasesStore.caseCardData?.caseData?.headline}</span>
              </p>
            </div>
            <div className={styles.fl_keys}>
              <div className={styles.left_keys}>
                <div className={styles.block_text}>
                  <p>{CasesStore.caseCardData?.caseData?.description}</p>
                  <div className={styles.price}>
                    <span>Цена:</span>
                    <p>{CasesStore.caseCardData?.caseData?.amount}₸</p>
                  </div>
                  {/*<div className={styles.navik}>*/}
                  {/*  <p>Photoshop</p>*/}
                  {/*  <p>Photoshop</p>*/}
                  {/*</div>*/}
                </div>

                <OrderModal
                  caseId={caseId}
                  expertId={CasesStore.caseCardData?.profile?._id}
                  caseData={CasesStore.caseCardData}
                />

                {CasesStore.caseCardData?.caseData?.articles &&
                  CasesStore.caseCardData?.caseData?.articles.map((item) => (
                    <ArticleCard item={item} />
                  ))}
              </div>

              <Reviews
                expertId={CasesStore.caseCardData?.profile?._id}
                caseId={caseId}
              />

              <div
                className={styles.right_keys + " mt-32"}
                style={{ marginBottom: "70px" }}
              >
                {/* <ContactBlock/>*/}
                <ContactBlock profile={CasesStore.caseCardData?.profile} />
              </div>
            </div>
          </>
        ) : (
          <>
            <p className={styles.title_page}>Детали объявления</p>
            <div className={styles.top_bl + " mb-24"}>
              <div className={styles.flex_top_bl}>
                <div
                  className={styles.flex_top_bl}
                  onClick={() =>
                    navigate("/card/" + CasesStore.caseCardData?.profile?._id, {
                      replace: true,
                    })
                  }
                  style={{ cursor: "pointer" }}
                >
                  {!CasesStore.caseCardData.profile.avatar && (
                    <div
                      className={styles.img_av}
                      style={{
                        backgroundImage: `url(${noFoto})`,
                      }}
                    />
                  )}
                  {CasesStore.caseCardData.profile.avatar && (
                    <div
                      className={styles.img_av}
                      style={{
                        backgroundImage: `url(https://${Utils.domain}/api/public/file/${CasesStore.caseCardData.profile.avatar})`,
                      }}
                    />
                  )}

                  <p>{CasesStore.caseCardData.profile.fio}</p>
                </div>
                <div>
                  <p className={styles.card_titl_rait}>
                    <b>{CasesStore.caseCardData?.profile?.review} </b>
                    <img src={star} alt={""} />
                    <span className={styles.ratingContent}>
                      ({CasesStore.caseCardData?.profile?.reviewCount || 0}{" "}
                      {Utils.declOfNum(
                        CasesStore.caseCardData?.profile?.reviewCount || 0,
                        ["отзыв", "отзыва", "отзывов"]
                      )}
                      )
                    </span>
                  </p>
                </div>
                <div>
                  <div className={styles.view}>
                    <b>
                      {CasesStore.caseCardData?.caseData?.viewCount}
                      <img src={eye1} alt={""} />
                    </b>
                    <p>просмотров</p>
                  </div>
                </div>
                <div className={styles.izbran}>
                  <CaseFavoriteButton caseId={caseId} />
                </div>
              </div>
            </div>
            <div className={styles.flex_blll}>
              <div className={styles.flex_left}>
                {CasesStore.caseCardData?.caseData?.image && (
                  <div
                    className={styles.keycard1}
                    style={{
                      background: `url(https://${Utils.domain}/api/public/file/${CasesStore.caseCardData?.caseData?.image})`,
                      backgroundSize: `cover`,
                      backgroundPosition: `50% 50%`,
                      borderRadius: 20,
                      marginBottom: 15,
                    }}
                  />
                )}
                <div className={styles.description_text}>
                  <p className={styles.description_text_t}>Описание услуги</p>
                  {CasesStore.caseCardData?.caseData?.description}
                </div>
                <div>
                  {CasesStore.caseCardData?.caseData?.articles &&
                    CasesStore.caseCardData?.caseData?.articles.map((item) => (
                      <ArticleCard item={item} />
                    ))}
                </div>
                <div className={styles.rewie_bb}>
                  <Reviews
                    expertId={CasesStore.caseCardData?.profile?._id}
                    caseId={caseId}
                  />
                </div>
              </div>
              <div className={styles.flex_right} style={{ marginTop: -75 }}>
                <AutoSizer
                  disableHeight={true}
                  style={{ display: "inline-block" }}
                >
                  {({ width }) => {
                    setBlockRightWidth(width);
                    return <></>;
                  }}
                </AutoSizer>
                <div
                  className={
                    isSticky ? styles.flex_right_fix : styles.flex_right_nofix
                  }
                  style={{
                    width: blockRightWidth,
                    height: AppStore.height - 70,
                  }}
                >
                  {/*<div className={styles.flex_bl}>*/}
                  {/*  <div>*/}
                  {/*    <img src={clock} />*/}
                  {/*    <p>*/}
                  {/*      Время работы<span>4 дня</span>*/}
                  {/*    </p>*/}
                  {/*  </div>*/}
                  {/*  <div>*/}
                  {/*    <img src={map} />*/}
                  {/*    <p>*/}
                  {/*      Локация<span>Астана</span>*/}
                  {/*    </p>*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  <div className={styles.deck_usl}>
                    <p className={styles.deck_usl_pr}>
                      {CasesStore.caseCardData?.caseData?.amount} <span>₸</span>
                    </p>
                    <p className={styles.title_bb}>
                      {CasesStore.caseCardData?.caseData?.name}
                      <span>{CasesStore.caseCardData?.caseData?.headline}</span>
                    </p>
                    {/*<p style={{fontFamily: 'Manrope-Medium'}}>Тут какой-то текст непонятно откуда он </p>*/}
                    <OrderModal
                      caseId={caseId}
                      buttonText={"Заказать"}
                      expertId={CasesStore.caseCardData?.profile?._id}
                      caseData={CasesStore.caseCardData}
                      buttonStyle={{
                        borderRadius: 10,
                        background:
                          "linear-gradient(150deg, #526efe 0%, #5b58f0 100%)",
                        fontFamily: "Manrope-Medium",
                        color: "#fff",
                        padding: "12px 16px",
                        marginTop: 16,
                        fontSize: 16,
                        display: "block",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <div className={styles.deck_usl}>
                    <ContactBlock profile={CasesStore.caseCardData?.profile} />
                    <OrderModal
                      expertId=""
                      buttonStyle={{
                        background: "rgba(15, 24, 43, 1)",
                        color: "#fff",
                        fontFamily: "Manrope-Medium",
                        cursor: "pointer",
                        borderRadius: 10,
                        display: "block",
                        textAlign: "center",
                        marginTop: 24,
                        fontSize: 14,
                        padding: "12px 16px",
                        maxWidth: "100%",
                      }}
                      buttonText={"Связаться"}
                      styleButtonSend={{ background: "rgba(15, 24, 43, 1)" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* <FormBlock />*/}
      </div>
    </div>
  );
};

export default observer(CaseCard);
