import React, { useCallback, useEffect, useState } from "react";
import {
  LoadingOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import styles from "../PersonalUser/PersonalUser.module.scss";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Checkbox,
  Row,
  Popconfirm,
} from "antd";
import { message, Upload } from "antd";
import Utils, { apiQuery, apiQueryError } from "../../Utils";
import ImagePreview from "../ImagePreview";
import InputMask from "react-input-mask";
import userStore from "../Auth/userStore";
import { LeftOutlined } from "@ant-design/icons";
import ExpertsStore from "../Experts/ExpertsStore";
import { observer } from "mobx-react-lite";
import ModalSelect from "../ModalSelect";
import SearchModalSelect from "../Search/SearchModalSelect";
import { Tooltip } from "antd";

const { TextArea } = Input;

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("Вы можете загрузить файлы только в формате JPG или PNG!");
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error("Image must smaller than 10MB!");
  }
  return isJpgOrPng && isLt2M;
};

const PersonalUser = () => {
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const [load, setLoad] = useState(false);
  const [loadSave, setLoadSave] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [skillsOptions, setSkillsOptions] = useState([]);
  const [categorySkillsOptions, setCategorySkillsOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [fileList, setFileList] = useState([]);

  const onFinish = (values) => {
    if (fileList.length > 0 && fileList[0].response)
      values.avatar = fileList[0].response;

    setLoadSave(true);
    apiQuery("/private/profile", {
      method: "POST",
      body: values,
    })
      .then((data) => {
        setLoadSave(false);
        message.success("Данные успешно сохранены");
        userStore.authCheck().catch();
        navigate("/");
      })
      .catch((error) => {
        apiQueryError({ error });
        setLoadSave(false);
      });
  };

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  const onChange = ({ file, fileList }) => {
    setFileList(fileList);
    if (file.status === "uploading") {
      setLoading(true);
      return;
    }

    if (file.status === "done") {
      message.success(`Файл успешно загружен`);
      setLoading(false);
      console.log({ file, fileList });
      if (file.response)
        setImageUrl(
          `https://${Utils.domain}/api/private/file/${file.response}`
        );
      return;
    }

    if (file.status === "error") {
      console.error(file);
      if (file?.response?.msg) message.error(file.response.msg);
      else message.error("Не удалось загрузить файл");
    }
  };

  // const skillsHandler = ({ categorySelected, skillsSelected, csos }) => {
  //   let skillsOptions_ = [];
  //   if (!csos) csos = categorySkillsOptions;
  //   for (let cso of csos) {
  //     for (let so of cso.children) {
  //       if (
  //         categorySelected.includes(cso._id) ||
  //         skillsSelected.includes(so._id)
  //       )
  //         skillsOptions_.push({
  //           value: so._id,
  //           label: so.name,
  //         });
  //     }
  //   }
  //   setSkillsOptions(skillsOptions_);
  //   console.log({ skillsOptions_ });
  // };

  const uploadButton = (
    <div style={{ borderRadius: 100 }}>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const profileDelete = () => {
    apiQuery("/private/profile", { method: "DELETE" })
      .then(() => {
        message.success("Профиль успешно удален");
        userStore.logout();
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };

  const userGet = useCallback(() => {
    setLoad(true);
    apiQuery("/private/profile")
      .then((data) => {
        console.log(data);
        // if (data?.skillsOptions) setSkillsOptions(data.skillsOptions);
        if (data?.categorySkillsOptions)
          setCategorySkillsOptions(data.categorySkillsOptions);
        if (data?.categoryOptions)
          setCategoryOptions(
            data.categoryOptions.sort((a, b) => (a.sort > b.sort ? 1 : -1))
          );
        if (data?.skillsOptions) {
          console.log(data.skillsOptions);
          setSkillsOptions(data.skillsOptions);
        }
        if (data?.profileData) {
          // if (data?.profileData?.category && data?.profileData?.skills)
          //   skillsHandler({
          //     categorySelected: data?.profileData?.category,
          //     skillsSelected: data?.profileData?.skills,
          //     csos: data?.categorySkillsOptions,
          //   });
          form.setFieldsValue(data.profileData);
        }
        if (data?.profileData?.avatar)
          setImageUrl(
            `https://${Utils.domain}/api/private/file/${data?.profileData.avatar}`
          );

        setLoad(false);
      })
      .catch((error) => {
        console.error(error);
        apiQueryError({ error });
        setLoad(false);
        navigate("/", { replace: true });
      });
  }, [form, navigate]);

  const validateUsername = (_, value) => {
    const regex = /^[a-zA-Z0-9_.]{1,30}$/;

    if (!regex.test(value)) {
      return Promise.reject(
        "Ник пользователя должно содержать только латинские буквы, цифры, символ подчеркивания, точку и не превышать 30 символов."
      );
    }

    return Promise.resolve();
  };

  useEffect(() => {
    userGet();
    ExpertsStore.citiesList();
    ExpertsStore.countriesList();
  }, [userGet]);

  if (load) return <></>;

  console.log(skillsOptions);

  return (
    <div
      className={styles.page_form}
      style={{ maxWidth: 700, margin: "0 auto" }}
    >
      <div className="container">
        <p className={styles.title_page}>Профиль</p>
        <p style={{ color: "#F14040", textAlign: "center", paddingBottom: 10 }}>
          Ваш профиль не отображается пока вы не заполните всю информацию
        </p>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          autoComplete="off"
          scrollToFirstError={true}
        >
          <div
            className={styles.formItemUpload}
            style={{ textAlign: "center" }}
          >
            <ImgCrop rotationSlider quality={1}>
              <Upload
                withCredentials={true}
                name="file"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={`https://${Utils.domain}/api/private/upload`}
                beforeUpload={beforeUpload}
                onChange={onChange}
                fileList={fileList}
              >
                {imageUrl ? (
                  <ImagePreview
                    src={imageUrl}
                    style={{ border: 0, borderRadius: 100 }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </ImgCrop>
            <Link
              to={"/card/" + userStore.userData._id}
              className={styles.rename}
            >
              Просмотр профиля
            </Link>
          </div>

          <Form.Item
            label="Имя Фамилия"
            name="fio"
            className={styles.formItem}
            rules={[{ required: true, message: "Введите ФИО" }]}
          >
            <Input placeholder="Иван Иванов" maxLength={100} />
          </Form.Item>
          <Form.Item
            label={
              <>
                Username{" "}
                <Tooltip title="Username будет использоваться для создания уникальной ссылки на ваш аккаунт">
                  <span className={styles.ibut}>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              </>
            }
            name="userName"
            className={styles.formItem}
            rules={[
              { required: true, message: "Введите Имя пользователя!" },
              {
                pattern: new RegExp(/^[a-zA-Z0-9]+([_\s-]?[a-zA-Z0-9])*$/i),
                message: "Только латинские символы и тире",
              },
            ]}
          >
            <Input placeholder="Username" />
          </Form.Item>

          <Form.Item
            label="Расскажите о себе"
            name="description"
            className={styles.formItem}
            rules={[{ required: false, message: "Расскажите о себе!" }]}
          >
            <TextArea placeholder="Расскажи о себе" rows={4} maxLength={200} />
          </Form.Item>

          <Form.Item
            label="Ваша специальность"
            name="category"
            className={styles.formItem}
            rules={[{ required: true, message: "Выберите специальность" }]}
          >
            <SearchModalSelect
              modalTitle={"Ваша специальность"}
              modalAllTitle={"Все специальности"}
              mode="multiple"
              size={"middle"}
              maxSelect={3}
              options={categoryOptions}
              onChange={(values) => {
                // skillsHandler({
                //   categorySelected: values,
                //   skillsSelected: [], //form.getFieldValue('skills'),
                // });
              }}
            />
            {/*<Select mode="multiple" size={"middle"} options={categoryOptions} showSearch={false}/>*/}
          </Form.Item>

          <Form.Item
            label="Навыки"
            name="skills"
            className={styles.formItem}
            rules={[{ required: true, message: "Выберите навыки" }]}
          >
            <SearchModalSelect
              empty={"Необходимо выбрать специальность"}
              modalTitle={"Навыки"}
              modalAllTitle={"Все навыки"}
              mode="multiple"
              size={"middle"}
              maxSelect={8}
              options={skillsOptions}
              filter={true}
            />
            {/*<Select*/}
            {/*  mode="multiple"*/}
            {/*  size={"middle"}*/}
            {/*  style={{*/}
            {/*    width: "100%",*/}
            {/*  }}*/}
            {/*  options={skillsOptions}*/}
            {/*  showSearch={false}*/}
            {/*/>*/}
          </Form.Item>

          <Form.Item
            label="Ваш e-mail"
            name="email"
            className={styles.formItem}
            // rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input readOnly />
          </Form.Item>

          <Form.Item
            label="Опыт (например: 5 лет)"
            name="experience"
            className={styles.formItem}
            rules={[{ required: true, message: "Введите кол-во лет опыта!" }]}
          >
            <InputNumber
              placeholder="5"
              className={styles.inputNumber}
              style={{
                fontSize: 16,
                width: "100%",
              }}
              min="0"
            />
          </Form.Item>

          <Form.Item
            label="Номер телефона"
            name="phone"
            className={styles.formItem}
            rules={[{ required: true, message: "Укажите номер телефона!" }]}
          >
            <InputMask mask="+7 999-999-99-99" />
          </Form.Item>

          <Form.Item
            label="Ваша страна"
            name="country"
            className={styles.formItem}
            rules={[{ required: true, message: "Введите свою страну" }]}
          >
            <Select size={"middle"} options={ExpertsStore.countriesOptions} />
          </Form.Item>

          <Form.Item
            label="Ваш город"
            name="city"
            className={styles.formItem}
            rules={[{ required: true, message: "Введите свой город" }]}
          >
            <Select size={"middle"} options={ExpertsStore.citiesOptions} />
          </Form.Item>
          {/*<Form.Item*/}
          {/*  label="Адрес"*/}
          {/*  name="city2"*/}
          {/*  className={styles.formItem}*/}
          {/*  rules={[{ required: true, message: "Введите свой адрес" }]}*/}
          {/*>*/}
          {/*  <Input placeholder="Ваш адрес" />*/}
          {/*</Form.Item>*/}
          <Form.Item
            label="Instagram (username)"
            name="site"
            className={styles.formItem}
            rules={[
              { required: true, message: "Введите username Instagram" },
              { validator: validateUsername },
            ]}
          >
            <Input addonBefore="@" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              className="poiskButton"
              htmlType="submit"
              loading={loadSave}
            >
              Сохранить изменения
            </Button>
          </Form.Item>
        </Form>

        <p style={{ textAlign: "center", marginBottom: 20 }}>
          <Popconfirm
            title="Удалить профиль"
            description="Вы уверены, что хотите удалить свой профиль? Профиль будет удален безвозвратно."
            onConfirm={profileDelete}
            okText="Да"
            cancelText="Нет"
          >
            <Button type={"link"} style={{ color: "rgba(255, 75, 75, 1)" }}>
              Удалить профиль
            </Button>
          </Popconfirm>
        </p>
      </div>
    </div>
  );
};

export default observer(PersonalUser);
