import { Button, Divider, Form, message } from "antd";
import ReactCodeInput from "react-code-input";
import { Link } from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import { Content } from "antd/es/layout/layout";
import { apiQuery, apiQueryError } from "../../Utils";
import styles from "./Login.module.scss";
import logo from "../../assets/images/logo.svg";

const propsReactCodeInput = {
  inputStyle: {
    margin: 6,
    width: 36,
    fontSize: "22px",
    height: 40,
    backgroundColor: "#ffffff",
    border: "1px solid #ddd",
    borderRadius: 6,
    textAlign: "center",
    padding: 2,
  },
};

const Resend = () => {
  const [sec, setSec] = useState(0);
  const [sendCheck, setSendCheck] = useState(true);
  const timer = useRef();

  const funTimer = (s) => {
    if (!sendCheck) return;
    apiQuery("/mailVerificationCodeSend")
      .then(() => {
        message.success("Письмо отправлено").then();
      })
      .catch((error) => {
        apiQueryError({ error });
      });

    clearInterval(timer.current);
    setSec(s);
    timer.current = setInterval(() => {
      if (s === 0) {
        setSendCheck(true);
        clearInterval(timer.current);
        return;
      }
      s--;
      setSec(s);
    }, 1000);
  };

  useEffect(() => {
    funTimer(30);
    setSendCheck(false);
  }, [])

  if (!sendCheck)
    return (
      <p style={{ textAlign: "center" }}>
        Письмо отправлено. Повторно отправить можно через: {sec} сек.
      </p>
    );

  return (
    <div style={{ textAlign: "center" }}>
      <Button
        type="link"
        style={{ textAlign: "center", cursor: "pointer" }}
        onClick={() => {
          funTimer(30);
          setSendCheck(false);
        }}
      >
        Отправить повторно код на email
      </Button>
    </div>
  );
};

const VerifyEmail = () => {
  const [code, setCode] = useState("");

  const onFinish = () => {
    if (code === "") return;

    apiQuery("/mailVerification", {
      method: "POST",
      body: {
        code,
      },
    })
      .then(() => {
        message.success("Почта успешно подтверждена").then();
        setTimeout(() => {
          window.location.href = "/cabinet";
        }, 1000);
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };
  const onChange = (v) => {
    console.log(v);
    setCode(v);
  };

  return (
    <div className={styles.wrp_log}>
      <Content
        className={styles.login_form}
        style={{
          width: "100%",
          maxWidth: 375,
          padding: "64px 10px",
          margin: "0 auto",
        }}
      >
        <div>
          <a className="logo_auth" href={"/"}>
            <img src={logo} style={{ marginBottom: "20px" }} alt="logo" />
          </a>
        </div>

        <h3 className={styles.title_bl2} style={{ textAlign: "center" }}>
          Подтверждение электронной почты
        </h3>
        <p style={{ textAlign: "center" }}>
          На ваш электронный адрес отправлен проверочный код, введите его:
        </p>

        <Form
          name="normal_login"
          className={styles.login_form + "mt-32"}
          onFinish={onFinish}
        >
          <div
            style={{
              display: "block",
              margin: "20px auto",
              width: "100%",
            }}
          >
            <ReactCodeInput
              type="number"
              fields={6}
              name="code"
              value={code}
              onChange={onChange}
              autoComplete={"off"}
              {...propsReactCodeInput}
            />
          </div>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.login_form_button}
              block
            >
              Отправить
            </Button>
          </Form.Item>

          <Divider />

          <Resend />

          <Divider />

          <div style={{ textAlign: "center" }}>
            <Link className={styles.text_link} to="/login">
              Авторизоваться
            </Link>
          </div>
        </Form>
      </Content>
    </div>
  );
};

export default VerifyEmail;
