import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Modal,
  Select,
  Input,
  Divider,
  Row,
  Col,
  Button,
  Alert,
} from "antd";
import styles from "./Search.module.scss";
import { observer } from "mobx-react-lite";
import AppStore from "../App/AppStore";
import SearchStore from "./SearchStore";
import { AutoSizer, List } from "react-virtualized";
import { v4 as uuidV4 } from "uuid";

const SearchModalSelect = ({
  value,
  onChange,
  mode,
  options,
  size,
  empty = null,
  modalAllTitle,
  modalTitle,
  modalPopularTitle,
  modalPopularOptions = [],
  placeholder = "",
  filter = false,
  onSelectAdd,
  onSelectDel,
}) => {
  const modalKey = uuidV4();
  const [isFix, setIsFix] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [optionsList, setOptionsList] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
    document.body.style.position = "fixed";
    document.body.style.inset = "0";
  };
  const handleOk = () => {
    setIsModalOpen(false);
    document.body.style.position = "";
    document.body.style.inset = "";
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    document.body.style.position = "";
    document.body.style.inset = "";
  };

  const triggerChange = (changedValue) => {
    onChange?.(changedValue);
  };

  const onCheckboxItemChange = (checkedValue) => {
    if (mode !== "multiple") {
      onChange?.(
        checkedValue.target.checked ? checkedValue.target.value : null
      );
      setIsModalOpen(false);
      document.body.style.position = "";
      document.body.style.inset = "";
      return;
    }

    console.log(value);
    let d = new Set(value || []);
    if (checkedValue.target.checked) d.add(checkedValue.target.value);
    else d.delete(checkedValue.target.value);
    d = [...d];
    console.log(d);

    onChange?.(d);
  };
  const onCheckboxChange = (checkedValues) => {
    triggerChange(checkedValues);
  };
  const onSelectChange = (checkedValues) => {
    triggerChange(checkedValues);
  };

  const onSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);

    let options_ = [...options];

    options_ = options_.filter((item) => {
      if (value !== "")
        item.hidden = item.label.toLowerCase().indexOf(value.toLowerCase()) < 0;
      else item.hidden = false;
      return !item.hidden;
    });

    options_.unshift({ value: "", label: "" });

    setOptionsList(options_);
  };

  const fixedHandler = (e) => {
    if (e.scrollTop > 100) setIsFix(true);
    else setIsFix(false);
  };

  useEffect(() => {
    if (!isModalOpen) {
      document.body.style.position = "";
      document.body.style.inset = "";
      return;
    }
    const options_ = [...options];
    options_.unshift({ value: "", label: "" });
    setOptionsList(options_);
  }, [isModalOpen, options]);

  return (
    <span>
      <Select
        className={styles.selectV2}
        allowClear={true}
        clearIcon={
          <svg
            width="18"
            height="18"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2478_5030)">
              <path
                d="M13.6166 7.76831C13.6966 7.69368 13.7612 7.60401 13.8066 7.50442C13.852 7.40483 13.8773 7.29728 13.8811 7.1879C13.885 7.07852 13.8672 6.96946 13.8289 6.86694C13.7906 6.76442 13.7324 6.67045 13.6578 6.5904C13.5832 6.51035 13.4935 6.44578 13.3939 6.40038C13.2943 6.35498 13.1868 6.32965 13.0774 6.32582C12.968 6.32199 12.8589 6.33974 12.7564 6.37806C12.6539 6.41638 12.5599 6.47451 12.4799 6.54915L10.0416 8.82248L7.76822 6.38331C7.61611 6.22752 7.40906 6.13744 7.19138 6.13237C6.9737 6.12729 6.76268 6.20762 6.60347 6.35615C6.44427 6.50469 6.34952 6.70964 6.3395 6.92714C6.32949 7.14465 6.405 7.35744 6.54989 7.51998L8.82322 9.95831L6.38405 12.2316C6.30117 12.3055 6.23389 12.3952 6.18616 12.4955C6.13843 12.5957 6.11121 12.7045 6.10611 12.8154C6.101 12.9263 6.11811 13.0371 6.15642 13.1413C6.19474 13.2455 6.25349 13.341 6.32923 13.4222C6.40497 13.5034 6.49617 13.5686 6.59747 13.614C6.69877 13.6595 6.80813 13.6842 6.91913 13.6868C7.03013 13.6894 7.14052 13.6698 7.24383 13.6291C7.34714 13.5884 7.44128 13.5275 7.52072 13.45L9.95905 11.1775L12.2324 13.6158C12.3058 13.7002 12.3954 13.769 12.496 13.818C12.5965 13.867 12.7059 13.8953 12.8176 13.9012C12.9293 13.907 13.0411 13.8903 13.1462 13.8521C13.2513 13.8138 13.3476 13.7548 13.4295 13.6785C13.5113 13.6022 13.5769 13.5103 13.6224 13.4081C13.6679 13.3059 13.6924 13.1956 13.6944 13.0838C13.6964 12.9719 13.6758 12.8608 13.634 12.7571C13.5921 12.6534 13.5298 12.5591 13.4507 12.48L11.1782 10.0416L13.6166 7.76831Z"
                fill="#1162DD"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.833252 9.99998C0.833252 4.93748 4.93742 0.833313 9.99992 0.833313C15.0624 0.833313 19.1666 4.93748 19.1666 9.99998C19.1666 15.0625 15.0624 19.1666 9.99992 19.1666C4.93742 19.1666 0.833252 15.0625 0.833252 9.99998ZM9.99992 17.5C9.015 17.5 8.03973 17.306 7.12979 16.9291C6.21985 16.5522 5.39306 15.9997 4.69662 15.3033C4.00018 14.6068 3.44773 13.78 3.07082 12.8701C2.69391 11.9602 2.49992 10.9849 2.49992 9.99998C2.49992 9.01507 2.69391 8.03979 3.07082 7.12985C3.44773 6.21991 4.00018 5.39312 4.69662 4.69668C5.39306 4.00024 6.21985 3.44779 7.12979 3.07088C8.03973 2.69397 9.015 2.49998 9.99992 2.49998C11.989 2.49998 13.8967 3.29016 15.3032 4.69668C16.7097 6.1032 17.4999 8.01086 17.4999 9.99998C17.4999 11.9891 16.7097 13.8968 15.3032 15.3033C13.8967 16.7098 11.989 17.5 9.99992 17.5Z"
                fill="#1162DD"
              />
            </g>
            <defs>
              <clipPath id="clip0_2478_5030">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        }
        suffixIcon={
          <svg
            width="9"
            height="16"
            viewBox="0 0 9 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 15L8 8L0.999999 1"
              stroke="#1162DD"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        mode={mode}
        size={size}
        options={options}
        showSearch={false}
        value={value}
        onDropdownVisibleChange={showModal}
        dropdownRender={() => null}
        dropdownStyle={{ display: "none" }}
        onChange={onSelectChange}
        placeholder={placeholder}
      />
      {isModalOpen && <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        style={{ top: 20 }}
        bodyStyle={{ height: AppStore.height - 115 }}
      >
        {!options.length && empty && <Alert message={empty} type="info" />}
        <div className={styles.bodyContent}>
          {isFix && (
            <div className={styles.fixHead} style={{ position: "absolute" }}>
              <p style={{ marginBottom: 5 }}>{modalAllTitle}</p>
              <Input
                placeholder="Поиск..."
                onChange={onSearch}
                value={searchValue}
                allowClear={true}
              />
            </div>
          )}

          <Checkbox.Group
            value={value}
            // onChange={onCheckboxChange}
            style={{ display: "block" }}
          >
            <AutoSizer disableHeight>
              {({ width }) => (
                <List
                  width={width + 15}
                  className={styles.bodyContentScroll}
                  style={{ position: "relative" }}
                  height={
                    AppStore.height - 115 - (value && value.length ? 40 : 0)
                  }
                  rowCount={optionsList.length}
                  rowHeight={({ index }) =>
                    !index ? (!!modalPopularTitle ? 160 : 90) : 30
                  }
                  onScroll={fixedHandler}
                  rowRenderer={({
                    key, // Unique key within array of rows
                    index, // Index of row within collection
                    isScrolling, // The List is currently being scrolled
                    isVisible, // This row is visible within the List (eg it is not an overscanned row)
                    style, // Style object to be applied to row (to position it)
                  }) => {
                    const item = optionsList[index];

                    if (index === 0)
                      return (
                        <div
                          key={key}
                          className={styles.selectListItem}
                          style={style}
                        >
                          <Divider style={{ margin: "10px 0" }} />
                          {!!modalPopularTitle && (
                            <div>
                              <p>{modalPopularTitle}</p>
                              <div className={styles.popularList}>
                                {modalPopularOptions.map((item, index) => (
                                  <span
                                    key={index}
                                    className={styles.itemTag}
                                    onClick={() => onSelectAdd(item.value)}
                                  >
                                    {item.label}
                                  </span>
                                ))}
                              </div>

                              <Divider style={{ margin: "10px 0" }} />
                            </div>
                          )}

                          <div className={styles.fixHead}>
                            <p style={{ marginBottom: 5 }}>{modalAllTitle}</p>
                            <Input
                              placeholder="Поиск..."
                              onChange={onSearch}
                              value={searchValue}
                              allowClear={true}
                            />
                          </div>
                        </div>
                      );

                    return (
                      <div
                        key={key}
                        className={styles.selectListItem}
                        style={style}
                      >
                        <Row>
                          <Col flex={"auto"}>
                            <label
                              htmlFor={modalKey + index}
                              className={styles.label}
                            >
                              {item.label}
                            </label>
                          </Col>
                          <Col flex={"20px"}>
                            <Checkbox
                              value={item.value}
                              id={modalKey + index}
                              onChange={onCheckboxItemChange}
                            />
                          </Col>
                        </Row>
                      </div>
                    );
                  }}
                />
              )}
            </AutoSizer>
          </Checkbox.Group>

          {mode === "multiple" && value && value.length && (
            <div>
              <Button
                onClick={() => {
                  setIsModalOpen(false);
                  document.body.style.position = "";
                  document.body.style.inset = "";
                  SearchStore.list({ clear: true });
                  SearchStore.adminListPage({page:1});
                }}
                type={"primary"}
                block
              >
                Показать
              </Button>
            </div>
          )}
        </div>
      </Modal>}
    </span>
  );
};

export default observer(SearchModalSelect);
