import React, { useEffect, useState } from "react";
import { Divider, Form, Input, Button, Layout, message } from "antd";
import styles from "./Login.module.scss";
import { Link } from "react-router-dom";
import { apiQuery, apiQueryError } from "../../Utils";
import ReactCodeInput from "react-code-input";
import { observer } from "mobx-react-lite";
import userStore from "./userStore";
import logo from "../../assets/images/logo.svg";
// import imglo from "../../assets/images/imglo.svg";
import imglo from "../../assets/images/resetPassBg.png";

const { Content } = Layout;

const propsReactCodeInput = {
  inputStyle: {
    margin: 6,
    width: 36,
    fontSize: "22px",
    height: 40,
    backgroundColor: "#ffffff",
    border: "1px solid #ddd",
    borderRadius: 6,
    textAlign: "center",
    padding: 2,
  },
};

const Restore = () => {
  const [form, setForm] = useState("email");

  useEffect(() => {
    userStore.setRestoreEmail("");
    userStore.setRestoreCode("");
  }, []);

  const onFinish = (values) => {
    apiQuery("/restore", {
      method: "POST",
      body: values,
    })
      .then((userData) => {
        console.log(values);
        userStore.setRestoreEmail(values.email);
        setForm("code");
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };

  const onFinish2 = () => {
    if (!userStore.restoreEmail)
      return apiQueryError({
        error: {
          body: {
            msg: "Ошибка запроса. Пожалуйста повторите востановление пароля заного.",
          },
        },
      });

    apiQuery("/restore_code", {
      method: "POST",
      body: {
        email: userStore.restoreEmail,
        code: userStore.restoreCode,
      },
    })
      .then((userData) => {
        setForm("password");
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };

  const onFinish3 = (values) => {
    if (userStore.restoreEmail === "" || userStore.restoreCode === "")
      return apiQueryError({
        error: {
          body: {
            msg: "Ошибка запроса. Пожалуйста повторите востановление пароля заного.",
          },
        },
      });

    if (userStore.restoreCode.length !== 6) {
      message.warn("Введите код подтверждения");
      return;
    }

    apiQuery("/restore_password", {
      method: "POST",
      body: {
        email: userStore.restoreEmail,
        code: userStore.restoreCode,
        ...values,
      },
    })
      .then((userData) => {
        setForm("success");
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };

  const onChange = (value) => {
    userStore.setRestoreCode(value);
  };

  if (form === "success")
    return (
      <div className={styles.wrp_log}>
        <Content
          className={styles.login_form}
          style={{
            width: "100%",
            maxWidth: 375,
            padding: "64px 10px",
            margin: "0 auto",
          }}
        >
          <div>
            <a className="logo_auth" href={"/"}>
              <img src={logo} style={{ marginBottom: "20px" }} alt="logo" />
            </a>
            <div className={styles.fonn}>
              <img src={imglo} alt="fon" />
            </div>
          </div>

          <h3 className={styles.title_bl2} style={{ textAlign: "center" }}>
            Восстановление пароля
          </h3>

          <h4 style={{ textAlign: "center" }}>Пароль успешно востановлен</h4>

          <div className={styles.no_acc}>
            <span>
              <Link to="/login">Авторизоваться</Link>
            </span>
          </div>
        </Content>
      </div>
    );

  if (form === "password")
    return (
      <div className={styles.wrp_log}>
        <Content
          className={styles.login_form}
          style={{
            width: "100%",
            maxWidth: 375,
            padding: "64px 10px",
            margin: "0 auto",
          }}
        >
          <div>
            <a className="logo_auth" href={"/"}>
              <img src={logo} style={{ marginBottom: "20px" }} alt="logo" />
            </a>
            <div className={styles.fonn}>
              <img src={imglo} alt="fon" />
            </div>
          </div>

          <h3 className={styles.title_bl2} style={{ textAlign: "center" }}>
            Восстановление пароля
          </h3>

          <Form
            name="normal_login"
            className={styles.login_form + "mt-32"}
            onFinish={onFinish3}
          >
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  min: 8,
                  message: "Минимальное количество символов 8!",
                },
                {
                  max: 32,
                  message: "Максимальное количество символов 32!",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirm"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Пароли не совпадают!"));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.login_form_button}
                block
              >
                Отправить
              </Button>
            </Form.Item>

            <div className={styles.no_acc}>
              <Link className={styles.text_link} to="/login">
                Авторизоваться
              </Link>
            </div>
          </Form>
        </Content>
      </div>
    );

  if (form === "code")
    return (
      <div className={styles.wrp_log}>
        <Content
          className={styles.login_form}
          style={{
            width: "100%",
            maxWidth: 375,
            padding: "64px 10px",
            margin: "0 auto",
          }}
        >
          <div>
            <a className="logo_auth" href={"/"}>
              <img src={logo} style={{ marginBottom: "20px" }} alt="logo" />
            </a>
            <div className={styles.fonn}>
              <img src={imglo} alt="fon" />
            </div>
          </div>

          <h3 className={styles.title_bl2} style={{ textAlign: "center" }}>
            Восстановление пароля
          </h3>

          <p style={{ textAlign: "center" }}>
            На ваш электронный адрес отправлен проверочный код, введите его:
          </p>

          <Form
            name="normal_login"
            className={styles.login_form + "mt-32"}
            onFinish={onFinish2}
          >
            <div
              style={{
                display: "block",
                margin: "20px auto",
                width: "100%",
                textAlign: "center",
              }}
            >
              <ReactCodeInput
                type="number"
                fields={6}
                name="code"
                value={userStore.restoreCode}
                onChange={onChange}
                autoComplete={"off"}
                {...propsReactCodeInput}
              />
            </div>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.login_form_button}
                block
              >
                Отправить
              </Button>
            </Form.Item>

            <div className={styles.no_acc}>
              <span>
                <Link className={styles.text_link} to="/login">
                  Авторизоваться
                </Link>
              </span>
            </div>
          </Form>
        </Content>
      </div>
    );

  return (
    <div className={styles.wrp_log}>
      <Content
        className={styles.login_form}
        style={{
          width: "100%",
          maxWidth: 375,
          padding: "64px 10px",
          margin: "0 auto",
        }}
      >
        <div>
          <a className="logo_auth" href={"/"}>
            <img src={logo} style={{ marginBottom: "20px" }} alt="logo" />
          </a>
          <div className={styles.fonn}>
            <img src={imglo} alt="fon" />
          </div>
        </div>

        <h3 className={styles.title_bl2} style={{ textAlign: "center" }}>
          Восстановление пароля
        </h3>

        <Form
          name="normal_login"
          className={styles.login_form + "mt-32"}
          initialValues={{ rememberMy: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Введите email!" }]}
          >
            <Input type={"email"} placeholder="Email" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.login_form_button}
              block
            >
              Отправить
            </Button>
          </Form.Item>

          <div className={styles.no_acc}>
            <Link className={styles.text_link} to="/login">
              Авторизоваться
            </Link>
          </div>
        </Form>
      </Content>
    </div>
  );
};

export default observer(Restore);
