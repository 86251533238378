import {Button, Checkbox, Modal, Radio, Space} from "antd";
import React from "react";
import PremiumStore from "./PremiumStore";
import {observer} from "mobx-react-lite";
import premium from "../../assets/images/premium.svg";
import styles from "./Premium.module.scss";

const PremiumModal = () => {
  return <Modal
    centered
    open={PremiumStore.modalOpen}
    onOk={() => {
      PremiumStore.setModalOpen(false);
    }}
    onCancel={() => {
      PremiumStore.setModalOpen(false);
    }}
    footer={null}
    style={{maxWidth: 400}}
  >
    <div style={{margin: '0 20px'}}>
      <div style={{textAlign: "center", margin: '0 20px'}}>
        <img src={premium} style={{width: 100}}/>
        <h2>Poisk премиум</h2>
        <p>
          Премиум подписчики обладают привилегиями и всегда отображаются первыми в своих категориях сферы деятельности.
        </p>
      </div>

      <div className={styles.tariff}>
        <Radio.Group style={{display: 'block'}}>
          <div className={styles.tariffItem}>
            <Radio value={1}><span style={{color: 'rgba(69, 117, 241, 1)'}}>Ежемесячно</span></Radio>
          </div>
          <div className={styles.tariffItem}>
            <Radio value={2}><span style={{color: 'rgba(69, 117, 241, 1)'}}>Ежегодно</span></Radio>
          </div>
        </Radio.Group>
      </div>
      <div style={{textAlign: "center", margin: '0 20px'}}>
        <Button type="primary" style={{minWidth: 200}}>Подключиться</Button>
      </div>
    </div>
  </Modal>
}

export default observer(PremiumModal);